import React, { PropsWithChildren } from "react";

import { Box, Modal, ModalProps } from "@mui/material";
import ReportTitle from "../../sections/title/report-title";
import { Close } from "@mui/icons-material";

type CustomModalProps = { onCloseFunc: () => void; title: string } & ModalProps & PropsWithChildren;

const CustomModal: React.FC<CustomModalProps> = (props) => {
  const { ...allProps } = props;

  return (
    <Modal onClose={props.onCloseFunc} {...allProps}>
      <Box
        sx={{
          p: "14px 28px",
          width: "540px",
          background: "#fff",
          position: "absolute",
          left: "50%",
          top: "10%",
          transform: "translate(-50%, 0%)",
          borderRadius: "8px",
        }}
      >
        <ReportTitle
          title={props.title}
          action={
            <Box
              onClick={() => props.onCloseFunc()}
              sx={{
                bgcolor: "primary.main",
                borderRadius: "50%",
                height: "28px",
                width: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Close sx={{ color: "#fff" }} />
            </Box>
          }
        />
        <>{props.children}</>
      </Box>
    </Modal>
  );
};

export default CustomModal;
