import { useState } from "react";
import moment, { Moment } from "moment";
import { enqueueSnackbar } from "notistack";

import { Box, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import { formatDate } from "../../../utils/date";
import MyButton from "../../../components/ui/myButton";
import MuiDataGrid from "../../../components/MuiDataGrid";
import ReportLayout from "../../../sections/report-layout";
import RowPageSearch from "../../../sections/row-page-search";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";
import betListMock_data from "../../../mocks/_bet-list-repoprts";

const CHOOSE_TYPES = [
  { label: "Matched", value: "matched" },
  { label: "UnMatched", value: "un-matched" },
  { label: "Deleted", value: "deleted" },
];

const columns: GridColDef[] = [
  {
    field: "type",
    headerName: "Event Type",
    width: 85,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "event_name",
    headerName: "Event Name",
    // width: 200,
    minWidth: 300,
    flex: 1,
  },
  {
    field: "user_name",
    headerName: "Username",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "runner_name",
    headerName: "Runner Name",
    minWidth: 150,
    flex: 0.5,
    // align: "center",
    // headerAlign: "center",
  },
  {
    field: "bet_type",
    headerName: "Bet Type",
    width: 80,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "requested_odds",
    headerName: "User Rate",
    width: 90,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "bet_amount",
    headerName: "Amount",
    width: 80,
    align: "center",
    headerAlign: "center",
  },
  {
    field: "created_at",
    headerName: "Placed Date",
    width: 120,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => <>{formatDate(row.created_at)}</>,
  },
  {
    field: "matched_at",
    headerName: "Matched Date",
    width: 120,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }) => <>{formatDate(row.matched_at)}</>,
  },
];

// interface Params {
//   from: string;
//   to: string;
// }

const CurrentBetsPage = () => {
  const [isLoad, setIsLoad] = useState(false);
  const [params, setParams] = useState({ pageSize: 10, page: 0 });
  const [fromDate] = useState<Moment | null>(moment().subtract(30, "days"));
  const [toDate] = useState<Moment | null>(moment());

  const [apiData] = useState<any>(betListMock_data);

  function handleLoad(event: any): void {
    setIsLoad(true);
    if (fromDate && toDate) {
      // refetch();
    } else {
      enqueueSnackbar("Please Select From & To Date", { variant: "error" });
    }
  }

  // useEffect(() => {
  //   if (data) {
  //     setIsLoad(true);
  //   }
  //   setApiData({ betList: data?.betList || [], count: data?.count || 0 });
  // }, [data]);

  const onChangePageSize = (newSize: number) => {
    setParams((prev) => ({ ...prev, pageSize: newSize }));
  };

  return (
    <ReportLayout>
      <ReportTitle title="Current Bets" />

      <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
        <Grid item xs={12} sm={2} lg={2}>
          <MuiSelect label="Choose Type" options={CHOOSE_TYPES} />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={1.5}>
          <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
            <MyButton color="primary" variant="contained" onClick={(e) => handleLoad(e)}>
              Load
            </MyButton>
          </Box>
        </Grid>
      </Grid>

      {isLoad && (
        <>
          <RowPageSearch onChangePageSize={onChangePageSize} />
          <MuiDataGrid
            rows={apiData.betList}
            columns={columns}
            getRowId={(row) => row.bet_id}
            pageOptions={params}
          />
        </>
      )}
    </ReportLayout>
  );
};

export default CurrentBetsPage;
