import { mutate } from "swr";
import { useState } from "react";
import { AxiosError, AxiosResponse } from "axios";

import instance from "../api/instance";

interface UsePutState<T> {
  data: T | null;
  loading: boolean;
  error: AxiosError | null;
  statusCode: number | null | undefined;
}

const usePutApi = <T, TPayload>(endpoint: string, mutateEndpoints?: string[]) => {
  const [state, setState] = useState<UsePutState<T>>({
    data: null,
    error: null,
    loading: false,
    statusCode: null,
  });

  function putData(payload: TPayload): Promise<AxiosResponse<T, any> | void> {
    setState({ ...state, loading: true });
    return new Promise((resolve, reject) => {
      instance
        .put<T>(endpoint, payload)
        .then((response) => {
          mutateEndpoints?.forEach((endpointToMutate) => mutate(endpointToMutate));
          setState({
            data: response.data,
            loading: false,
            error: null,
            statusCode: response.status,
          });
          resolve(response);
        })
        .catch((error) => {
          setState({
            ...state,
            loading: false,
            error: error.response,
            statusCode: error.response?.status,
          });
          reject(error.response);
        });
    });
  }
  return { ...state, putData };
};

export default usePutApi;
