import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import instance from "../../api/instance";

interface appState {
  roles: any[];
  loading: boolean;
}

const initialState: appState = {
  roles: [],
  loading: false,
};

export const getUserRoles = createAsyncThunk("app/getUserRoles", async (_, { rejectWithValue }) => {
  try {
    const response = await instance.get("role/list");
    const data = await response?.data?.data;

    return data ?? { list: [], dataCount: 0 };
  } catch (error: any) {
    return rejectWithValue(error?.data);
  }
});

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserRoles.fulfilled, (state, { payload: { list, dataCount } }) => {
        state.loading = false;
        state.roles = Array.isArray(list) ? list : [];
      })
      .addCase(getUserRoles.rejected, (state) => {
        state = initialState;
      });
  },
});

export default appSlice.reducer;
