import * as Yup from 'yup'

export interface IPasswordSchema {
    new_password: string
    confirm_password: string
    transaction_password: string
    member_operation_type: string
}

const PasswordSchema = Yup.object({
    new_password: Yup.string().required('New Password is required'),
    confirm_password: Yup.string().required('Confirm Password is required'),
    transaction_password: Yup.string().required('Transaction Password is required')
})

export default PasswordSchema