import { Box, Divider, Stack, Typography } from "@mui/material";
import MyButton from "../../../components/ui/myButton";
import React, { PropsWithChildren } from "react";
import { useBoolean } from "usehooks-ts";
import UserBookPopup from "./user-book-popup-body";

interface MenuEvents extends PropsWithChildren {
  id: string;
}

const EventMenus: React.FC<MenuEvents> = ({ children, id }) => {
  return (
    <Stack
      className={id}
      sx={{
        position: "absolute",
        top: "100%",
        left: "0",
        minWidth: "100px",
        display: "none",
        bgcolor: "white",
        zIndex: 1,
        border: "0.5px solid lightgray",
      }}
    >
      {children}
    </Stack>
  );
};

const EventActions = () => {
  const popup = useBoolean(false);
  return (
    <Box sx={{ margin: 1, display: "flex", justifyContent: "flex-end" }}>
      <Box sx={{ position: "relative", "&:hover > .bet-lock-box": { display: "block" } }}>
        <MyButton variant="contained" color="primary" size="small" sx={{ mr: 2.5 }}>
          Bet Lock
        </MyButton>

        <EventMenus id="bet-lock-box">
          <Typography variant="body2" sx={{ pl: "3px" }}>
            All Active
          </Typography>
          <Divider />
          <Typography variant="body2" sx={{ pl: "3px" }}>
            Userwise
          </Typography>
        </EventMenus>
      </Box>
      <Box sx={{ position: "relative", "&:hover > .fancy-lock-box": { display: "block" } }}>
        <MyButton variant="contained" color="primary" size="small" sx={{ mr: 2.5 }}>
          Fancy Lock
        </MyButton>
        <EventMenus id="fancy-lock-box">
          <Typography variant="body2" sx={{ pl: "3px" }}>
            All Active
          </Typography>
          <Divider />
          <Typography variant="body2" sx={{ pl: "3px" }}>
            Userwise
          </Typography>
        </EventMenus>
      </Box>
      <Box sx={{ position: "relative", "&:hover > .user-book-box": { display: "none" } }}>
        <MyButton
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2.5 }}
          onClick={() => popup.toggle()}
        >
          User Book
        </MyButton>
        <EventMenus id="user-book-box">
          <Typography variant="body2" sx={{ pl: "3px" }}>
            All Active
          </Typography>
          <Divider />
          <Typography variant="body2" sx={{ pl: "3px" }}>
            Userwise
          </Typography>
        </EventMenus>
      </Box>
      <Box sx={{ position: "relative", "&:hover > .book-markers-box": { display: "none" } }}>
        <MyButton
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2.5 }}
          onClick={() => popup.toggle()}
        >
          Bookmarkers Book
        </MyButton>
        <EventMenus id="book-markers-box">
          <Typography variant="body2" sx={{ pl: "3px" }}>
            All Active
          </Typography>
          <Divider />
          <Typography variant="body2" sx={{ pl: "3px" }}>
            Userwise
          </Typography>
        </EventMenus>
      </Box>
      {popup.value && <UserBookPopup open={popup.value} handleClose={popup.setFalse} />}
    </Box>
  );
};

export default EventActions;
