import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginView from "../../sections/login/view";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/", { replace: true });
    }
  }, [isLoggedIn, navigate]);
  return <LoginView />;
};

export default LoginPage;
