import React from "react";
import LoginForm from "../login-form";
import { Stack, Typography } from "@mui/material";
import theme from "../../../theme";

const LoginView: React.FC = () => {
  return (
    <Stack
      pt={12.5}
      alignItems={"center"}
      sx={{
        height: "100vh",
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
      }}
    >
      <img src="/logo512.png" width={"250px"} style={{ marginBottom: "20px" }} alt="logo" />

      <LoginForm />

      <Typography mt={"15px"} fontSize={"15px"} color={"#fff"}>
        © SILVEREXCH
      </Typography>
    </Stack>
  );
};

export default LoginView;
