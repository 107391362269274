import { Box, Grid } from "@mui/material";
import ReportLayout from "../../../sections/report-layout";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";
import MyButton from "../../../components/ui/myButton";
import { GridColDef } from "@mui/x-data-grid";
import RowPageSearch from "../../../sections/row-page-search";
import MuiDataGrid from "../../../components/MuiDataGrid";
import { GENERAL_REPORTS } from "../../../mock/_general_report";
import MuiDate from "../../../components/date/MuiDate";

const SELECT_TYPES = [
  { label: "General Report", value: "General Report" },
  { label: "Credit Refrance Report", value: "Credit Refrance Report" },
];

const columns: GridColDef[] = [
  {
    field: "srNoL",
    headerName: "Sr. No",
    flex: 0.5,
  },
  {
    field: "nameL",
    headerName: "Name",
    flex: 0.5,
  },
  {
    field: "amountL",
    headerName: "Amount",
    flex: 0.5,
  },
  {
    field: "srNoR",
    headerName: "Sr. No",
    flex: 0.5,
  },
  {
    field: "nameR",
    headerName: "Name",
    flex: 0.5,
  },
  {
    field: "amountR",
    headerName: "Amount",
    flex: 0.5,
  },
];

const GameReportPage = () => {
  return (
    <ReportLayout>
      <ReportTitle title="Game Report" />

      <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <MuiDate label="From" />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <MuiDate label="To" />
        </Grid>

        <Grid item xs={12} sm={12} md={2} lg={2}>
          <MuiSelect options={SELECT_TYPES} label="Type" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
            <MyButton color="primary" variant="contained">
              Game List
            </MyButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MuiSelect options={SELECT_TYPES} label="" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <MyButton color="primary" variant="contained" sx={{ mr: 1 }}>
            Show Game Reports
          </MyButton>

          <MyButton color="primary" variant="contained">
            Master Game Reports
          </MyButton>
        </Grid>
      </Grid>

      <RowPageSearch />
      <MuiDataGrid
        rows={GENERAL_REPORTS}
        columns={columns}
        hideFooter={true}
        sx={{
          "& .MuiDataGrid-row--lastVisible": {
            fontWeight: "bold",
          },
        }}
      />
    </ReportLayout>
  );
};

export default GameReportPage;
