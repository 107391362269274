const HEADER_MENU_ITEMS = [
  {
    id: 1,
    title: "List Of Clients",
    link: (userId: string) => `active-users/${userId}`,
  },
  {
    id: 2,
    title: "Market Analysis",
    link: "market-analysis",
  },
  {
    id: 3,
    title: "Live Market",
    link: "#",
    children: [
      {
        id: 1,
        title: "Binary",
        link: "#",
      },
      {
        id: 2,
        title: "Race 20-20",
        link: "#",
      },
    ],
  },
  {
    id: 4,
    title: "Reports",
    link: "#",
    children: [
      {
        id: 1,
        title: "Account Statement",
        link: "/account-statement",
      },
      {
        id: 2,
        title: "Current Bets",
        link: "/current-bets",
      },
      {
        id: 3,
        title: "General Report",
        link: "/general-report",
      },
      {
        id: 4,
        title: "Game Report",
        link: "/game-report",
      },
      {
        id: 5,
        title: "Casino Report",
        link: "/casino-report",
      },
      {
        id: 6,
        title: "Profit Loss",
        link: "/profit-loss",
      },
      {
        id: 7,
        title: "Casino Result Report",
        link: "/casinoresult",
      },
    ],
  },
];

export default HEADER_MENU_ITEMS;
