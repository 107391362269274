import React from "react";

import SportEventView from "../../sections/sport-event/view/view";

import { Grid } from "@mui/material";

import RightBar from "../../sections/main-layout/right-bar";
import EventActions from "../../sections/sport-event/event-top-header-action/event-actions";
import ReportLayout from "../../sections/report-layout";

// ------------------------------------------------------------------

const SportEventPage: React.FC = () => {
  return (
    <ReportLayout>
      <Grid container>
        <Grid item xs={12}>
          <EventActions />
        </Grid>
        <Grid item xs={8}>
          <SportEventView />
        </Grid>
        {/* {smBreakpoint && ( */}
        <Grid item xs={4}>
          <RightBar />
        </Grid>
        {/* )} */}
      </Grid>
    </ReportLayout>
  );
};

export default SportEventPage;
