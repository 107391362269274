import { Box, Typography } from "@mui/material";
import { FC } from "react";

const defaultOptions = [
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "50", value: 50 },
];

interface Props {
  onChangePageSize: (value: number) => void;
  options?: any[];
}

const PageSize: FC<Props> = ({ options = defaultOptions, onChangePageSize }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <Typography variant="body2" mr="3px">
        Show
      </Typography>
      <select
        style={{ marginRight: "3px" }}
        onChange={(e) => {
          onChangePageSize(+e.target.value);
        }}
      >
        {Array.isArray(options) &&
          options.map((item) => {
            return (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            );
          })}
      </select>
      <Typography variant="body2">entries</Typography>
    </Box>
  );
};

export default PageSize;
