import { FC } from "react";
import { GridToolbarContainer, useGridApiContext } from "@mui/x-data-grid";
import { Stack, Typography } from "@mui/material";
import MyButton from "./ui/myButton";

interface PropsI {
  page: number;
  noOfEntries: number;
  entriesPerPage: number;
}

const CustomToolbar: FC<PropsI> = ({ page, noOfEntries, entriesPerPage }) => {
  const apiRef = useGridApiContext();

  // Calculate the range of entries being shown
  const startIndex = page * entriesPerPage + 1;
  const endIndex = Math.min((page + 1) * entriesPerPage, noOfEntries);

  return (
    <GridToolbarContainer sx={{ justifyContent: "space-between", my: 1 }}>
      <Typography fontSize={"14px"}>
        Showing {startIndex} to {endIndex} of {noOfEntries} entries
      </Typography>
      <Stack direction={"row"} alignItems={"center"} columnGap={2}>
        <MyButton variant="contained" onClick={() => apiRef.current.setPage(page - 1)}>
          Previous
        </MyButton>
        {page + 1}
        <MyButton variant="contained" onClick={() => apiRef.current.setPage(page + 1)}>
          Next
        </MyButton>
      </Stack>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
