import moment from "moment";
import { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  FormLabel,
  FormControl,
  SelectChangeEvent,
  Typography,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import instance from "../../../api/instance";
import endpoints from "../../../api/endpoints";
import { formatDate, fUnixTimeStamp } from "../../../utils/date";
import useGetApi from "../../../hooks/use-get-api";
import MyButton from "../../../components/ui/myButton";
import MuiDate from "../../../components/date/MuiDate";
import MuiDataGrid from "../../../components/MuiDataGrid";
import ReportLayout from "../../../sections/report-layout";
import RowPageSearch from "../../../sections/row-page-search";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";
import { IAccountStatement } from "../../../mock/_casino-report";
import AutoCompleteSearch from "../../../components/autocomplete-search";
import parseNumber from "../../../utils/parse-number";
import FullScreenPopUp from "../../../sections/sport-event/event-top-header-action/full-screen-popup";
import { Close } from "@mui/icons-material";
import useModal from "../../../hooks/use-modal";

const ACCOUNT_TYPES = [
  { value: "all", label: "All" },
  { value: "balance_report", label: "Balance Report" },
  { value: "game_report", label: "Game Report" },
];

function getValue(amount: number, type: "credit" | "debit") {
  if (amount > 0 && type === "credit") {
    return parseNumber({ value: amount, type: "float" }).toFixed(2);
  }
  if (amount < 0 && type === "debit") {
    return parseNumber({ value: amount, type: "float" }).toFixed(2);
  }
  return "0.00";
}

interface IProps {
  row: any;
  onClickMarket: (row: any) => void;
}

const RemarkValue: FC<IProps> = ({ row = {}, onClickMarket = () => {} }) => {
  if (row?.operation_type === "bet-settle") {
    let title = row?.game_name ?? "";

    if (row?.market_name) {
      title += `/${row?.market_name}`;
    }

    if (row?.round_id) {
      title += ` RNO. ${row?.round_id}`;
    }

    return (
      <Typography
        onClick={() => onClickMarket(row)}
        sx={{
          textTransform: "uppercase",
          cursor: "pointer",
          background: "#444",
          padding: "5px 10px",
          textDecoration: "none",
          color: "#fff",
          borderRadius: "3px",
          marginRight: "3px",
          display: "inline-block",
          fontSize: "14px",
        }}
      >
        {title}
      </Typography>
    );
  }
  return row?.remark ?? "";
};

const defaultGameOptions = [{ label: "All", value: "all" }];
const balanceReportGameOptions = [
  { label: "All", value: "all" },
  { label: "Upper", value: "upper" },
  { label: "Down", value: "down" },
];

interface PageOptionsI {
  pageSize: number;
  page: number;
}

const defaultPageOptions = {
  pageSize: 5,
  page: 0,
};

const AccountStatementPage = () => {
  const [inputValue, setInputValue] = useState<string>("");
  const [clientOptions] = useState<any[]>([]);
  const isMarket = useModal();

  const [paginationModel, setPaginationModel] = useState<PageOptionsI>(defaultPageOptions);
  const [params, setParams] = useState({
    accountType: "all",
    gameName: "all",
    clientName: "",
    from: moment().subtract(7, "days"),
    to: moment(),
  });

  const { data: gameListData } = useGetApi<any[]>(endpoints.GAME.list);

  const [accountStatement, setAccountStatement] = useState<{ list: IAccountStatement[]; rowCount: number }>({
    list: [],
    rowCount: 0,
  });
  const [gameOptions, setGameOptions] = useState<any[]>(defaultGameOptions);

  const handle = {
    getData: async () => {
      try {
        const apiParams: any = {
          accountType: params.accountType,
          // page: paginationModel.page + 1,
          limit: paginationModel.pageSize,
          startDate: params.from.unix(),
          endDate: params.to.unix(),
        };

        if (["all", "upper", "down"].includes(params.gameName)) {
          apiParams.gameName = params.gameName;
        } else if (params.gameName) {
          apiParams.provider_game_id = params.gameName;
        }

        const { data: reportData } = await instance.get(endpoints.admin.accountStatement, {
          params: apiParams,
        });

        const nData = Array.isArray(reportData?.data?.accountGameStatement)
          ? reportData.data.accountGameStatement.map((i: any) => ({ ...i, id: uuidv4() }))
          : [];

        setAccountStatement({ list: nData, rowCount: reportData?.data?.count ?? 0 });
      } catch (error) {
        setAccountStatement({ list: [], rowCount: 0 });
      }
    },
    handleParams: (key: string, value: any) => {
      setParams({ ...params, [key]: value });
    },
    handleAccountType: (value: string) => {
      setParams({ ...params, accountType: value, gameName: "all" });

      if (value === "balance_report") {
        setGameOptions(balanceReportGameOptions);
      } else if (value === "game_report") {
        const options = Array.isArray(gameListData)
          ? gameListData.map((i) => ({ ...i, label: i.game_name, value: i.provider_game_id }))
          : [];

        setGameOptions([...defaultGameOptions, ...options]);
      } else {
        setGameOptions(defaultGameOptions);
      }
    },
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "created_at",
        headerName: "Date",
        renderCell: ({ row }) => <TableDate rows={row} />,
        flex: 0.5,
        valueGetter({ value }) {
          return value ? formatDate(value, "YYYY-MM-DD") : "-";
        },
      },
      {
        field: "credit",
        headerName: "Credit",
        flex: 0.5,
        align: "right",
        valueGetter({ value, row }) {
          if (row.operation_type === "account-open") return "";
          return getValue(row.credit_or_debit_amount, "credit");
        },
      },
      {
        field: "debit",
        headerName: "Debit",
        flex: 0.5,
        align: "right",
        valueGetter({ value, row }) {
          if (row.operation_type === "account-open") return "";
          return getValue(row.credit_or_debit_amount, "debit");
        },
      },
      {
        field: "final_balance",
        headerName: "Closing",
        flex: 0.5,
        align: "right",
        valueGetter({ value }) {
          return parseNumber({ value, type: "float" }).toFixed(2);
        },
      },
      {
        field: "remark",
        headerName: "Description",
        flex: 1,
        renderCell: ({ row }) => {
          if (row.operation_type === "account-open") return "Opening";
          return <RemarkValue row={row} onClickMarket={isMarket.onOpen} />;
        },
      },
      {
        field: "from_to",
        headerName: "FromTo",
        flex: 0.5,
      },
    ],
    [isMarket.onOpen]
  );

  return (
    <ReportLayout>
      <ReportTitle title="Account Statement" />

      <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
        <Grid item xs={12} sm={3} lg={2}>
          <MuiSelect
            label="Account Type"
            options={ACCOUNT_TYPES}
            name="accountType"
            value={params.accountType}
            onChange={(evt: SelectChangeEvent) => handle.handleAccountType(evt.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={3} lg={2}>
          <MuiSelect
            label="Game Name"
            name="gameName"
            value={params.gameName}
            options={gameOptions}
            onChange={(evt: SelectChangeEvent) => handle.handleParams("gameName", evt.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3} lg={2}>
          <FormControl fullWidth sx={{ "& .MuiInputBase-sizeSmall": { p: "3px 10px !important" } }}>
            <FormLabel>Search By Client Name</FormLabel>
            <AutoCompleteSearch
              name="clientName"
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={clientOptions}
              value={params.clientName}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={1.5}>
          <MuiDate
            label="From"
            name="from"
            value={params.from}
            onChange={(newDate) => handle.handleParams("from", newDate)}
          />
        </Grid>
        <Grid item xs={6} sm={2} md={2} lg={1.5}>
          <MuiDate
            label="To"
            name="to"
            value={params.to}
            onChange={(newDate) => handle.handleParams("to", newDate)}
          />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={1.5}>
          <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
            <MyButton color="primary" variant="contained" onClick={handle.getData}>
              Load
            </MyButton>
          </Box>
        </Grid>
      </Grid>

      <RowPageSearch />
      <MuiDataGrid
        rows={accountStatement.list}
        columns={columns}
        pageOptions={paginationModel}
        onChangePagination={setPaginationModel}
        noOfEntries={accountStatement.rowCount}
      />
      {isMarket.isOpen && (
        <MarketListPopup open handleClose={isMarket.onClose} itemData={isMarket.selectedRow} />
      )}
    </ReportLayout>
  );
};

export default AccountStatementPage;

const TableDate = ({ rows }: { rows: any }) => <>{moment.unix(rows?.created_at).format("YYYY-MM-DD")}</>;

interface UserBookPopupPopUp {
  open: boolean;
  handleClose: () => void;
  itemData: any;
}

type IBetRecord = {
  _id: string;
  bet_id: string;
  user_id: string;
  user_name: string;
  bet_amount: number;
  profit: number;
  liability: number;
  bet_type: string;
  bet_status: string;
  placed_at: string;
  matched_at: string;
  ip_address: string;
  outcome: string;
  upLine: string;
};

const MarketListPopup: FC<UserBookPopupPopUp> = ({ open, handleClose, itemData }) => {
  const [betType, setBetType] = useState<string>("");
  const [betData, setBetData] = useState<IBetRecord[]>([]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBetType((event.target as HTMLInputElement).value);
  };

  const getData = async () => {
    try {
      const params: any = {
        userId: itemData.user_id,
        provider_market_id: itemData.provider_market_id,
      };

      if (betType) {
        params.betType = betType;
      }

      const { data } = await instance.get(endpoints.member.marketBetList, { params });
      if (Array.isArray(data.data)) {
        setBetData(data.data);
      }
    } catch (error) {
      setBetData([]);
    }
  };

  useEffect(() => {
    if (itemData.user_id && itemData.provider_market_id) {
      getData();
    }
  }, [itemData, betType]);

  return (
    <FullScreenPopUp open={open} handleClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <ReportTitle
          title="Client Ledger"
          action={
            <Box
              onClick={() => handleClose()}
              sx={{
                bgcolor: "primary.main",
                borderRadius: "50%",
                height: "28px",
                width: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Close />
            </Box>
          }
        />
        <RadioGroup
          row
          value={betType}
          onChange={handleChange}
          sx={{
            mt: 3,
            ml: "12px",
            "& .MuiRadio-root": {
              p: 0,
              "& .MuiSvgIcon-root": {
                width: "0.9rem",
                height: "0.9rem",
              },
            },
          }}
          name="betType"
        >
          <FormControlLabel value="" control={<Radio size="small" />} label="All" />
          <FormControlLabel value="matched" control={<Radio size="small" />} label="Matched" />
          <FormControlLabel value="voided" control={<Radio size="small" />} label="Deleted" />
        </RadioGroup>
        <TableContainer
          sx={{
            "& .MuiTableCell-head": {
              fontWeight: "bold",
            },
            "& .MuiTableCell-body": {
              bgcolor: (theme) => theme.palette.primary.light,
              border: "0.5px solid #FFFFFF",
              ":first-child": {
                borderLeftColor: "lightgray",
              },
              ":last-child": {
                borderRightColor: "lightgray",
              },
            },
          }}
        >
          <Table size="small" sx={{ minWidth: 200, border: 0.5, borderColor: "lightgray" }}>
            <TableHead>
              <TableRow sx={{ fontWeight: "bold" }}>
                <TableCell>UpLevel</TableCell>
                <TableCell>UserName</TableCell>
                <TableCell>Nation</TableCell>
                <TableCell>UserRate</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>WinLoss</TableCell>
                <TableCell>PlaceDate</TableCell>
                <TableCell>MatchDate</TableCell>
                <TableCell>ip</TableCell>
                <TableCell>BrowserDetail</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(betData) &&
                betData.map((bet) => {
                  return (
                    <TableRow key={bet._id}>
                      <TableCell>{bet.upLine}</TableCell>
                      <TableCell>{bet.user_name}</TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>{bet.bet_amount}</TableCell>
                      <TableCell sx={{ color: bet?.profit >= 0 ? "green" : "red" }}>{bet?.profit}</TableCell>
                      <TableCell>{fUnixTimeStamp(bet.placed_at)}</TableCell>
                      <TableCell>{fUnixTimeStamp(bet.matched_at)}</TableCell>
                      <TableCell>{bet.ip_address}</TableCell>
                      <TableCell>{"-"}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </FullScreenPopUp>
  );
};
