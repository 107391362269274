import React from "react";

import { Stack, TableRow, TableCell } from "@mui/material";

import { BackType } from "./types";
import BetButton from "../../../components/bet-button/bet-button";

// ---------------------------------------------------------------
interface Props {
  runner: any;
  marketStatus: string;
  variant: "small" | "large";
  marketType: string;
}
// ---------------------------------------------------------------

const TwoColMarketTableRow: React.FC<Props> = ({ runner, marketStatus, variant, marketType }) => {
  const isSuspended = Boolean(runner?.provider_status === "SUSPENDED" || marketStatus === "SUSPENDED");

  return (
    <TableRow key={runner.runner_id}>
      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }}>{runner.runner_name}</TableCell>
      <TableCell
        sx={{
          p: 0,
          position: "relative",
          "&::before": {
            content: '"Suspended"',
            position: "absolute",
            display: isSuspended ? "grid" : "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            color: "#fff",
          },
          "&::after": {
            content: '"Loser"',
            position: "absolute",
            top: 0,
            color: "red",
            // display: false ? "grid" : "none",
            display: "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "18px",
            zIndex: 10,
          },
        }}
        colSpan={6}
      >
        <BetButtonsListForSixCol
          runner_name={runner?.runner_name}
          variant={variant}
          runner={runner}
          marketType={marketType}
        />
      </TableCell>
    </TableRow>
  );
};

export default TwoColMarketTableRow;

const BetButtonsListForSixCol = ({
  runner,
  variant,
  runner_name,
  marketType,
}: {
  runner: any;
  variant: string;
  runner_name: string;
  marketType: string;
}) => {
  return (
    <Stack direction={"row"}>
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"back"}
      />
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"back"}
      />
      {runner?.back
        ?.reverse()
        ?.map((back: BackType, i: number) => (
          <BetButton
            marketType={marketType}
            runner_name={runner_name}
            key={i}
            size={back.size}
            isEventPage={true}
            type={"back"}
            odds={back.price}
          />
        ))}
      {runner?.lay?.map((lay: BackType, i: number) => (
        <BetButton
          marketType={marketType}
          runner_name={runner_name}
          key={i}
          size={lay.size}
          isEventPage={true}
          type={"lay"}
          odds={lay.price}
        />
      ))}
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"lay"}
      />
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"lay"}
      />
    </Stack>
  );
};
