import React from "react";

import { TextField, TextFieldProps } from "@mui/material";

const CustomTextField: React.FC<TextFieldProps> = (props) => {
  const { sx = {}, ...otherProps } = props;

  return (
    <TextField
      {...otherProps}
      onChange={(e) => props.onChange && props.onChange(e)}
      placeholder={props.placeholder}
      name={props.name || ""}
      variant={props.variant || "outlined"}
      type={props.type || ""}
      value={props.value || ""}
      fullWidth
      size="small"
      margin="none"
      InputLabelProps={{
        shrink: true,
      }}
      sx={{
        "& input::placeholder": {
          fontSize: "14px",
        },
        "& input": {
          p: "5px 10px",
        },
        "& .Mui-disabled": { bgcolor: "#ddd" },
        ...sx,
      }}
    />
  );
};

export default CustomTextField;
