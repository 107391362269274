import { Typography } from "@mui/material";

export default function ShowTransactionPassword({ password }: { password: string }) {
  if (!password) return null;
  return (
    <div className="text-center container">
      <Typography variant="h3">Success! Your password has been updated successfully.</Typography>

      <Typography variant="h4">
        Your transaction password is <span style={{ color: "lightgreen" }}>{password}</span>
      </Typography>

      {/* <h2>
        Please remember this transaction password, from now on all transcation of the website can be done only
        with this password and keep one thing in mind, do not share this password with anyone.
      </h2>
      <h2 className="mt-3 text-dark">Thank you, Team diamondexch99.com</h2>
      <div className="font-hindi">
        <h1 className="mt-5">
          <span className="text-success">Success! आपका  पासवर्ड बदला जा चुका है।</span>
        </h1>
        <h1>
          आपका लेनदेन पासवर्ड <span className="text-info token-box">{password}</span> है।
        </h1>
        <h2>
          कृपया इस लेन-देन के पासवर्ड को याद रखें, अब से वेबसाइट के सभी हस्तांतरण केवल इस पासवर्ड से किए जा
          सकते हैं और एक बात का ध्यान रखें, इस पासवर्ड को किसी के साथ साझा न करें।
        </h2>
        <h2 className="mt-3 text-dark">धन्यवाद, टीम diamondexch99.com</h2>
      </div> */}
      <a href="/admin" className="btn btn-dark btn-lg mt-5" style={{ minWidth: "200px" }}>
        <i className="fas fa-arrow-left mr-3" />
        Back
      </a>
    </div>
  );
}
