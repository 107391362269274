import { ThemeProvider } from "@emotion/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";
import theme from "../theme";
import { Provider } from "react-redux";
import { persistor, store } from "../redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";

const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider anchorOrigin={{ horizontal: "center", vertical: "top" }} autoHideDuration={2500}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>{children}</ThemeProvider>
            </BrowserRouter>
          </LocalizationProvider>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  );
};

export default Providers;
