import { Box, Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MyButton from "../../../../components/ui/myButton";
import { useState } from "react";
import theme from "../../../../theme";

enum TABS {
  MATCHED = "MATCHED",
  UNMATCHED = "UNMATCHED",
}

const MatchData = () => {
  const [tab, setTab] = useState(TABS.MATCHED);

  const isUnmatched = tab === TABS.UNMATCHED;
  const isMatched = tab === TABS.MATCHED;
  return (
    <Paper>
      <Box
        sx={{
          bgcolor: `${theme.palette.secondary.main}d9`,
          display: "flex",
          justifyContent: "space-between",
          p: 1,
        }}
      >
        <Box>
          <MyButton
            sx={{ mr: 1.5 }}
            variant={isMatched ? "contained" : "text"}
            color={isMatched ? "primary" : "secondary"}
            onClick={() => setTab(TABS.MATCHED)}
          >
            Matched
          </MyButton>
          <MyButton
            variant={isUnmatched ? "contained" : "text"}
            color={isUnmatched ? "primary" : "secondary"}
            onClick={() => setTab(TABS.UNMATCHED)}
          >
            Unmatched
          </MyButton>
        </Box>
        <MyButton variant="contained" color="primary">
          View More
        </MyButton>
      </Box>
      <TableContainer sx={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>
        <Table size="small" sx={{ minWidth: 200 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>UserName</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>Nation</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>Rate</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>Amount</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>PlaceDate</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>MatchDate</TableCell>
              <TableCell sx={{ borderBottom: 0, fontWeight: "bold" }}>GameType</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MatchData;
