import { mutate } from "swr";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useState, useEffect } from "react";

import { Stack, TextField, Typography } from "@mui/material";
import { Logout, ReplayOutlined } from "@mui/icons-material";

import { ITemp } from "../users-table";
import usePutApi from "../../../../hooks/use-put-api";
import parseNumber from "../../../../utils/parse-number";
import MyButton from "../../../../components/ui/myButton";
import CustomModal from "../../../../components/custom-modal";
import endpoints, { IMember } from "../../../../api/endpoints";
import DepositSchema, { IDepositSchema } from "./schema/deposit.schema";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  rowData: ITemp;
  data: IMember;
}

const DepositModal = ({ openModal, toggleModal, rowData, data }: Props) => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  const [upBalance, setUpBalance] = useState(data?.admin_balance);
  const [downBalance, setDownBalance] = useState(0);
  const [remainingUpBalance, setRemainingUpBalance] = useState(0);
  // const [remainingDownBalance, setRemainingDownBalance] = useState(0);

  const { values, errors, handleChange, handleSubmit, handleBlur, resetForm, setFieldValue } = useFormik({
    initialValues: {
      remark: "",
      amount: "0",
      transaction_password: "",
      member_operation_type: "deposit",
    },
    onSubmit: (formValues) => {
      handleDepositSubmit({ ...formValues, amount: +values.amount });
    },
    validationSchema: DepositSchema,
  });

  // PUT DEPOSIT OPERATION DATA
  const { putData } = usePutApi(endpoints.member.operation(rowData?.id), [
    endpoints.member.getDownLineHandlerById(userId),
    endpoints.member.getMember(rowData?.id as string),
  ]);

  const handleDepositSubmit = async (submitValues: IDepositSchema) => {
    try {
      await putData({
        ...submitValues,
        admin_id: data?.admin_id,
        amount: parseNumber({ type: "float", value: values.amount }),
      });
      enqueueSnackbar("Deposit Successful", { variant: "success" });
      toggleModal();
      resetForm();
    } catch (submitError: any) {
      const errorMessage = submitError?.data?.status_message;
      enqueueSnackbar(errorMessage || "Someting Went Wrong", { variant: "error" });
      toggleModal();
      resetForm();
    }
  };

  useEffect(() => {
    setUpBalance(+data?.admin_balance);
  }, [data?.admin_balance]);

  useEffect(() => {
    if (rowData && rowData?.balance) {
      setDownBalance(rowData?.balance);
    }
  }, [rowData]);

  useEffect(() => {
    setRemainingUpBalance(upBalance - +values.amount);
    // setRemainingDownBalance(downBalance + +values.amount);
  }, [values.amount, upBalance, downBalance]);

  useEffect(() => {
    mutate(endpoints.member.getMember(data?.user_id));
  }, [data?.user_id]);

  return (
    <CustomModal open={openModal} onCloseFunc={toggleModal} title="Deposit">
      <form onSubmit={handleSubmit}>
        <Stack sx={{ "& .Mui-disabled": { bgcolor: "#ddd", textAlign: "right" } }} py={4} spacing={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"20%"} variant="body2">
              {data?.admin_user_name}
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <TextField disabled value={data?.admin_balance} size="small" />
              <TextField disabled value={+values.amount > 0 ? remainingUpBalance : ""} size="small" />
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"20%"} variant="body2">
              {rowData.username}
            </Typography>
            <Stack direction={"row"} spacing={2}>
              <TextField disabled value={data?.balance} size="small" />
              <TextField
                disabled
                value={+values.amount > 0 ? +data?.balance + +values?.amount : ""}
                size="small"
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"start"}>
            <Typography width={"20%"} variant="body2">
              Amount
            </Typography>
            <Stack width={"80%"} spacing={2}>
              <TextField
                value={values.amount}
                onClick={() => {
                  if (+values.amount === 0) {
                    setFieldValue("amount", "");
                  }
                }}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  if (values.amount === "") {
                    setFieldValue("amount", "0");
                  }
                }}
                name="amount"
                fullWidth
                size="small"
              />
              {errors.amount && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.amount}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"20%"} variant="body2">
              Remark
            </Typography>
            <Stack width={"80%"} spacing={2}>
              <TextField
                value={values.remark}
                onChange={handleChange}
                onBlur={handleBlur}
                name="remark"
                fullWidth
                multiline
                maxRows={2}
                size="small"
              />
              {errors.remark && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.remark}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"20%"} variant="body2">
              Transaction Password
            </Typography>
            <Stack width={"80%"} spacing={2}>
              <TextField
                type="password"
                value={values.transaction_password}
                onChange={handleChange}
                onBlur={handleBlur}
                name="transaction_password"
                fullWidth
                size="small"
              />
              {errors.transaction_password && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.transaction_password}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"end"} columnGap={1}>
          <MyButton
            sx={{ color: "#fff" }}
            startIcon={<ReplayOutlined />}
            variant="contained"
            color="secondary"
            onClick={toggleModal}
          >
            Back
          </MyButton>
          <MyButton
            type="submit"
            sx={{ color: "#fff" }}
            endIcon={<Logout />}
            variant="contained"
            color="primary"
          >
            Submit
          </MyButton>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default DepositModal;
