import React from "react";

import { Box, Table, TableRow, TableBody, TableCell, TableHead, TableContainer } from "@mui/material";

import theme from "../../../theme";
import { Market, Runner } from "./types";
import TwoColMarketTableRow from "./two-col-market-table-row";

// ------------------------------------------------------------
type Props = {
  data: any;
  variant: "small" | "large";
};
// ------------------------------------------------------------

const TwoColMarketTable: React.FC<Props> = ({ data, variant }) => {
  return (
    <Box p={0.5}>
      {data &&
        data?.map((market: Market) => (
          <TableContainer key={market?.market_id} sx={{ mt: 1 }}>
            <Table size="small">
              <TableHead sx={{ bgcolor: `${theme.palette.secondary.light}` }}>
                <TableRow>
                  <TableCell
                    width={"50%"}
                    sx={{ fontSize: "14px", fontWeight: "700", color: "#fff", paddingLeft: 0.75 }}
                  >
                    {market?.market_name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  {variant !== "small" && (
                    <>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                    </>
                  )}
                  <TableCell
                    colSpan={3}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      padding: 0,
                      textAlign: "center",
                      bgcolor: "#72BBEF",
                      height: "30px",
                    }}
                  >
                    Back
                  </TableCell>
                  <TableCell
                    colSpan={3}
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      padding: 0,
                      textAlign: "center",
                      bgcolor: "#FAA9BA",
                      height: "30px",
                    }}
                  >
                    Lay
                  </TableCell>
                  {variant !== "small" && (
                    <>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                    </>
                  )}
                </TableRow>
              </TableBody>
              <TableBody>
                {market?.runners &&
                  market?.runners.map((runner: Runner, i) => (
                    <TwoColMarketTableRow
                      key={i}
                      variant={variant}
                      runner={runner}
                      marketStatus={market.provider_status}
                      marketType={market.market_type}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </Box>
  );
};

export default TwoColMarketTable;
