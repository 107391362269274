import * as Yup from 'yup'

export interface IExposureLimitSchema {
    new_limit: number
    transaction_password: string
    member_operation_type: string
}

const ExposureLimitSchema = Yup.object({
    new_limit: Yup.number().required('New Limit is required').test('not-zero', 'New Limit should be greater than zero', value => value > 0),
    transaction_password: Yup.string().required('Transaction Password is required')
})

export default ExposureLimitSchema