import React, { SyntheticEvent, useState } from "react";
import ReportLayout from "../../report-layout";
import ReportTitle from "../../title/report-title";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import MobileAppAuth from "./mobile-app-auth";
import TelegramAuth from "./telegram-auth";

const SecurityAuthView = () => {
  const [currentTab, setCurrentTab] = useState<number>(0);

  function handleChange(_event: SyntheticEvent<Element, Event>, value: any): void {
    setCurrentTab(value);
  }

  return (
    <ReportLayout>
      <ReportTitle title="Secure Auth Verification" />

      <Stack alignItems={"center"} my={4}>
        <Typography component="h2" variant="h6" sx={{ fontSize: "22.4px" }}>
          Secure Auth Verification Status:{" "}
          <span
            style={{
              backgroundColor: "#DC3545",
              color: "#fff",
              fontWeight: 700,
              fontSize: "16px",
              padding: "4px 8px",
            }}
          >
            DISABLED
          </span>
        </Typography>

        <Typography fontSize={"14px"}>
          Please select below option to enable secure auth verification
        </Typography>

        <Tabs
          color="success"
          sx={{
            mt: 1,
            "& .Mui-selected": {
              bgcolor: "#28A745",
              color: "#fff !important",
            },
          }}
          value={currentTab}
          onChange={handleChange}
        >
          <Tab sx={tabStyle} label={"Enable Using Mobile App"} value={0} />
          <Tab sx={tabStyle} label={"Enable Using Telegram"} value={1} />
        </Tabs>

        <Box>{currentTab === 0 ? <MobileAppAuth /> : <TelegramAuth />}</Box>
      </Stack>
    </ReportLayout>
  );
};

export default SecurityAuthView;

const tabStyle = {
  textTransform: "capitalize",
  fontSize: "18px",
  border: "1px solid",
  borderColor: "#28A745",
  padding: "7px 14px",
  color: "#28A745",
};
