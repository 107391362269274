import React from "react";

import { Stack, SxProps, TableRow, TableCell, useMediaQuery } from "@mui/material";

import { BackType } from "./types";
import theme from "../../../theme";
import BetButton from "../../../components/bet-button/bet-button";

// ---------------------------------------------------------------
interface Props {
  runner: any;
  marketStatus: string;
  marketType: string;
  sxStyle?: SxProps;
}
// ---------------------------------------------------------------

const RunnersMarketTableRow: React.FC<Props> = ({ runner, marketStatus, marketType, sxStyle }) => {
  const isSuspended = Boolean(runner?.provider_status === "SUSPENDED" || marketStatus === "SUSPENDED");
  const sliceCount = useMediaQuery(theme.breakpoints.up("sm")) ? 3 : 1;
  return (
    <TableRow
      key={runner.runner_id}
      sx={{
        p: 0,
        position: "relative",
        "&::after": {
          content: '"Suspended"',
          position: "absolute",
          display: isSuspended ? "flex" : "none",
          placeContent: "center",
          right: 0,
          width: "504px",
          height: "100%",
          bgcolor: "rgba(0, 0, 0, 0.5)",
          textTransform: "uppercase",
          fontWeight: 700,
          color: "#fff",
          alignItems: "center",
          zIndex: 10,
        },
      }}
    >
      <TableCell>
        <Stack direction="column">
          <span>{runner.runner_name}</span> <span>0</span>
        </Stack>
      </TableCell>
      {Array.isArray(runner?.back) &&
        runner?.back
          .reverse()
          .slice(0, sliceCount)
          .map((back: BackType, i: number) => {
            return (
              <TableCell key={i} sx={{ ...sxStyle, p: 0 }}>
                <BetButton
                  marketType={marketType}
                  runner_name={runner.runner_name}
                  size={back.size}
                  isEventPage={true}
                  type={"back"}
                  odds={back.price}
                />
              </TableCell>
            );
          })}
      {Array.isArray(runner?.lay) &&
        runner?.lay.slice(0, sliceCount).map((lay: BackType, i: number) => {
          return (
            <TableCell key={i} sx={{ ...sxStyle, p: 0 }}>
              <BetButton
                marketType={marketType}
                runner_name={runner.runner_name}
                size={lay.size}
                isEventPage={true}
                type={"lay"}
                odds={lay.price}
              />
            </TableCell>
          );
        })}
    </TableRow>
  );
};

export default RunnersMarketTableRow;
