import ReportLayout from "../../sections/report-layout";
import ChangePasswordForm from "../../sections/change-password/change-password-form";

const ChangePassword = () => {
  return (
    <ReportLayout>
      <ChangePasswordForm />
    </ReportLayout>
  );
};

export default ChangePassword;
