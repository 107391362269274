export const eventMarketData = {
    "game_event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
    "provider_event_id": "32960046",
    "provider_status": "IN_PLAY",
    "event_name": "Sylhet Strikers v Rangpur Riders",
    "event_time": "1705995000",
    "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
    "league_id": "ec5ef1ba-2c54-4338-87fb-aae7bc2c57c4",
    "provider_league_id": "12161756",
    "league_name": "Bangladesh Premier League",
    "game_name": "cricket",
    "provider_game_id": "4",
    "min": null,
    "max": null,
    "max_bet_amount": null,
    "delay_timer": null,
    "is_live_score": true,
    "is_live_stream": true,
    "is_score_available": true,
    "is_stream_available": true,
    "match_odd": [
        {
            "market_id": "116edf81-62a2-44f0-9aa4-ae67f0625495",
            "provider_market_id": "1.223889250",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "Match Odds",
            "market_type": "match_odd",
            "market_time": "1705995000",
            "runners": [
                {
                    "runner_id": "51220568",
                    "runner_name": "Sylhet Strikers",
                    "provider_status": "ACTIVE",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 2.12,
                            "size": 56.68
                        },
                        {
                            "price": 3.55,
                            "size": 533.41
                        },
                        {
                            "price": 3.5,
                            "size": 2822.73
                        }
                    ],
                    "lay": [
                        {
                            "price": 3.75,
                            "size": 1410.56
                        },
                        {
                            "price": 3.8,
                            "size": 4111.61
                        },
                        {
                            "price": 3.85,
                            "size": 5.71
                        }
                    ]
                },
                {
                    "runner_id": "10550770",
                    "runner_name": "Rangpur Riders",
                    "provider_status": "ACTIVE",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 1.37,
                            "size": 3854.12
                        },
                        {
                            "price": 1.36,
                            "size": 11495.27
                        },
                        {
                            "price": 1.35,
                            "size": 1581.52
                        }
                    ],
                    "lay": [
                        {
                            "price": 1.38,
                            "size": 147.86
                        },
                        {
                            "price": 1.39,
                            "size": 1362.31
                        },
                        {
                            "price": 1.4,
                            "size": 7056.83
                        }
                    ]
                }
            ],
            "is_result_verified": false,
            "provider_status": "OPEN",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1705921735",
            "updated_at": "1706005832",
            "updated_by": "",
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        },
        {
            "market_id": "116edf81-62a2-44f0-9aa4-ae67f0625495",
            "provider_market_id": "1.223889250",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "To Win The Toss",
            "market_type": "match_odd",
            "market_time": "1705995000",
            "runners": [
                {
                    "runner_id": "51220568",
                    "runner_name": "Sylhet Strikers",
                    "provider_status": "ACTIVE",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 3.6,
                            "size": 56.68
                        },
                        {
                            "price": 3.55,
                            "size": 533.41
                        },
                        {
                            "price": 3.5,
                            "size": 2822.73
                        }
                    ],
                    "lay": [
                        {
                            "price": 3.75,
                            "size": 1410.56
                        },
                        {
                            "price": 3.8,
                            "size": 4111.61
                        },
                        {
                            "price": 3.85,
                            "size": 5.71
                        }
                    ]
                },
                {
                    "runner_id": "10550770",
                    "runner_name": "Rangpur Riders",
                    "provider_status": "ACTIVE",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 1.37,
                            "size": 3854.12
                        },
                        {
                            "price": 1.36,
                            "size": 11495.27
                        },
                        {
                            "price": 1.35,
                            "size": 1581.52
                        }
                    ],
                    "lay": [
                        {
                            "price": 1.38,
                            "size": 147.86
                        },
                        {
                            "price": 1.39,
                            "size": 1362.31
                        },
                        {
                            "price": 1.4,
                            "size": 7056.83
                        }
                    ]
                }
            ],
            "is_result_verified": false,
            "provider_status": "OPEN",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1705921735",
            "updated_at": "1706005832",
            "updated_by": "",
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        }
    ],
    "fancy": [
        {
            "market_id": "28f9ef62-f877-4a08-9413-38b9c2028f3a",
            "provider_market_id": "1363912",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "7th WKT Caught Out RR @ 1",
            "market_type": "fancy",
            "category": "BATSMAN",
            "no_rate": "70",
            "no_value": "45",
            "yes_rate": "55",
            "yes_value": "47",
            "is_result_verified": false,
            "provider_status": "ACTIVE",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1706004036",
            "updated_at": "1706005846",
            "updated_by": "",
            "market_time": null,
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        },
        {
            "market_id": "7855c960-71ac-46fb-92ff-eb4441987428",
            "provider_market_id": "1363873",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "15 Over RR",
            "market_type": "fancy",
            "category": "OVERS",
            "no_rate": "100",
            "no_value": "84",
            "yes_rate": "100",
            "yes_value": "85",
            "is_result_verified": false,
            "provider_status": "ACTIVE",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1706004845",
            "updated_at": "1706005840",
            "updated_by": "",
            "market_time": null,
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        },
        {
            "market_id": "9eefafed-b440-4ce6-a65a-c62370afc3e8",
            "provider_market_id": "1363888",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "16 Over RR",
            "market_type": "fancy",
            "category": "OVERS",
            "no_rate": "100",
            "no_value": "92",
            "yes_rate": "100",
            "yes_value": "93",
            "is_result_verified": false,
            "provider_status": "ACTIVE",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1706005747",
            "updated_at": "1706005846",
            "updated_by": "",
            "market_time": null,
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        }
    ],
    "bookmaker": [
        {
            "market_id": "cb2bf12c-8dfa-4a74-8867-df87747f9680",
            "provider_market_id": "215930",
            "game_id": "fbb33273-c537-4bff-9786-a2e85534deb3",
            "event_id": "a5490df5-8acb-4029-acd4-bdbcef9a698b",
            "market_name": "Bookmaker",
            "market_type": "bookmaker",
            "market_time": "1516710720",
            "runners": [
                {
                    "runner_id": "636499",
                    "runner_name": "Rangpur Riders",
                    "provider_status": "OPEN",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 35,
                            "size": 0
                        }
                    ],
                    "lay": [
                        {
                            "price": 38,
                            "size": 0
                        }
                    ]
                },
                {
                    "runner_id": "636498",
                    "runner_name": "Sylhet Strikers",
                    "provider_status": "SUSPENDED",
                    "sortPriority": "",
                    "min": 1,
                    "max": 100,
                    "back": [
                        {
                            "price": 95,
                            "size": 0
                        }
                    ],
                    "lay": [
                        {
                            "price": 105,
                            "size": 0
                        }
                    ]
                }
            ],
            "is_result_verified": false,
            "provider_status": "OPEN",
            "is_active": true,
            "created_by": "",
            "is_deleted": false,
            "created_at": "1705993353",
            "updated_at": "1706005832",
            "updated_by": "",
            "provider_game_id": "4",
            "provider_event_id": "32960046"
        }
    ]
}