import React from "react";

import { Stack, Typography } from "@mui/material";

import ExportButtons from "./export-buttons";

import CustomTextField from "../../../components/ui/custom-text-field";

const PreUserTable: React.FC = () => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <ExportButtons />

      <Stack direction={"row"} alignItems={"center"}>
        <Typography fontSize={"14px"}>Search:</Typography>
        <CustomTextField />
      </Stack>
    </Stack>
  );
};

export default PreUserTable;
