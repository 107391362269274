import { BetListData } from "../types";

const betListMock_data: BetListData = {
  count: 168,
  betList: [
    {
      bet_id: "94def5e2-0597-44c1-99e7-c22fb3f9d7f4",
      bet_id_ui: "240226170894592829039",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "aeee4b48-fc42-433f-81c9-6496c409c824",
      event_name: "Hady Habib v Gastao Elias",
      market_name: "Match Odds",
      runner_name: "Hady Habib",
      type: "back",
      odds: 1.33,
      bet_amount: 200,
      profit: 66,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708945922",
      requested_odds: 1.3,
      league_id: "d7632e1b-fa91-45a5-9934-aad2cadacfe0",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708945928",
      matched_at_odds: 1.33,
      ip_address: "59.152.54.146",
      created_at: "1708945922",
      updated_at: "1708945928",
    },
    {
      bet_id: "fc7d460b-ef57-4499-9f9a-8c598951dad7",
      bet_id_ui: "240226170894592827151",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "aeee4b48-fc42-433f-81c9-6496c409c824",
      event_name: "Hady Habib v Gastao Elias",
      market_name: "Match Odds",
      runner_name: "Hady Habib",
      type: "back",
      odds: 1.33,
      bet_amount: 200,
      profit: 66,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708945922",
      requested_odds: 1.3,
      league_id: "d7632e1b-fa91-45a5-9934-aad2cadacfe0",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708945928",
      matched_at_odds: 1.33,
      ip_address: "59.152.54.146",
      created_at: "1708945922",
      updated_at: "1708945928",
    },
    {
      bet_id: "c7cf5b36-2617-46c5-ad0a-6baac196393d",
      bet_id_ui: "240226170894542245642",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "4103b45d-5b9f-48e6-a2e5-43210eaf4ed5",
      event_name: "Karan Singh v Federico Gaio",
      market_name: "Match Odds",
      runner_name: "Karan Singh",
      type: "back",
      odds: 2.64,
      bet_amount: 200,
      profit: 328,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708945416",
      requested_odds: 2.64,
      league_id: "0c0cb15d-90ac-45e1-9ac7-4100c0304593",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708945422",
      matched_at_odds: 2.64,
      ip_address: "59.152.54.146",
      created_at: "1708945416",
      updated_at: "1708945422",
    },
    {
      bet_id: "0f9de478-a830-4270-8909-9d494e4a2aff",
      bet_id_ui: "240226170894394030620",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "fddb8989-9ffc-44df-bdd6-91258eacb145",
      event_name: "Royal Challengers Women v Gujarat Giants Women",
      market_name: "Match Odds",
      runner_name: "Royal Challengers Women",
      type: "back",
      odds: 1.57,
      bet_amount: 200,
      profit: 114,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708943934",
      requested_odds: 1.57,
      league_id: "18b1f4fe-d98b-4d4c-b174-37a0f978cb23",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708943940",
      matched_at_odds: 1.57,
      ip_address: "59.152.54.146",
      created_at: "1708943934",
      updated_at: "1708943940",
    },
    {
      bet_id: "40f33b94-c892-409b-896b-dd3a7151c1c4",
      bet_id_ui: "240226170894313345193",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.74,
      bet_amount: 200,
      profit: 148,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708943127",
      requested_odds: 1.73,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708943133",
      matched_at_odds: 1.74,
      ip_address: "59.152.54.146",
      created_at: "1708943127",
      updated_at: "1708943133",
    },
    {
      bet_id: "695846be-5018-46e4-b721-27349148c7b0",
      bet_id_ui: "240226170894308679764",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Lahore Qalandars",
      type: "back",
      odds: 2.14,
      bet_amount: 200,
      profit: 228,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708943081",
      requested_odds: 2.12,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708943086",
      matched_at_odds: 2.14,
      ip_address: "59.152.54.146",
      created_at: "1708943081",
      updated_at: "1708943086",
    },
    {
      bet_id: "b5179d33-e74b-4c95-8d9a-c97ec94c6721",
      bet_id_ui: "240226170894305435798",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.74,
      bet_amount: 200,
      profit: 148,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708943048",
      requested_odds: 1.72,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708943054",
      matched_at_odds: 1.74,
      ip_address: "59.152.54.146",
      created_at: "1708943048",
      updated_at: "1708943054",
    },
    {
      bet_id: "ca1e4ce4-8faa-492b-85bb-45df878ee85c",
      bet_id_ui: "240226170894299614891",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "lay",
      odds: 1.87,
      bet_amount: 200,
      profit: 200,
      liability: 174,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708942990",
      requested_odds: 1.9,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942996",
      matched_at_odds: 1.87,
      ip_address: "59.152.54.146",
      created_at: "1708942990",
      updated_at: "1708942996",
    },
    {
      bet_id: "99892b3d-c001-49a5-9ddd-8325db92212c",
      bet_id_ui: "240226170894297914582",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Lahore Qalandars",
      type: "lay",
      odds: 2.36,
      bet_amount: 200,
      profit: 200,
      liability: 272,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708942973",
      requested_odds: 2.38,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942979",
      matched_at_odds: 2.36,
      ip_address: "59.152.54.146",
      created_at: "1708942973",
      updated_at: "1708942979",
    },
    {
      bet_id: "4c168f15-fbe9-4f5b-85ab-e78aa3a340a5",
      bet_id_ui: "240226170894295865800",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "672aec73-3eb6-4d86-9fcb-eca1351effca",
      event_name: "Lahore Qalandars v Multan Sultans",
      market_name: "Match Odds",
      runner_name: "Lahore Qalandars",
      type: "back",
      odds: 2.14,
      bet_amount: 200,
      profit: 228,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708942952",
      requested_odds: 2.14,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942958",
      matched_at_odds: 2.14,
      ip_address: "59.152.54.146",
      created_at: "1708942952",
      updated_at: "1708942958",
    },
    {
      bet_id: "c9beeb7b-8f29-49fe-b040-8dde721a5646",
      bet_id_ui: "240226170894260459914",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708942598",
      requested_odds: 1.03,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942604",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708942598",
      updated_at: "1708942604",
    },
    {
      bet_id: "bd7078c1-cc90-4883-9767-c534e2bdc6f2",
      bet_id_ui: "240226170894254417033",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708942538",
      requested_odds: 1.03,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942544",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708942538",
      updated_at: "1708942544",
    },
    {
      bet_id: "d3172d5d-caec-48f6-a49f-e6baea11bf6c",
      bet_id_ui: "240226170894237766515",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708942372",
      requested_odds: 1.01,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942377",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708942372",
      updated_at: "1708942377",
    },
    {
      bet_id: "dd6b5092-4128-41b3-b433-65b3e0152b25",
      bet_id_ui: "240226170894225395636",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708942248",
      requested_odds: 1.01,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708942254",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708942248",
      updated_at: "1708942254",
    },
    {
      bet_id: "96139e85-f8ac-4aac-9552-a675d4c36cd7",
      bet_id_ui: "240226170894033454580",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708940328",
      requested_odds: 1.01,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708940334",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708940328",
      updated_at: "1708940334",
    },
    {
      bet_id: "180a0a66-4f82-4512-ad16-eda54c9ab894",
      bet_id_ui: "240226170893815455167",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.07,
      bet_amount: 200,
      profit: 200,
      liability: 14,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708938148",
      requested_odds: 1.07,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708938154",
      matched_at_odds: 1.07,
      ip_address: "59.152.54.146",
      created_at: "1708938148",
      updated_at: "1708938154",
    },
    {
      bet_id: "9c1779b8-01d9-4747-b1d2-c79934801376",
      bet_id_ui: "240226170893785574435",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.07,
      bet_amount: 200,
      profit: 200,
      liability: 14,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708937850",
      requested_odds: 1.07,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708937855",
      matched_at_odds: 1.07,
      ip_address: "59.152.54.146",
      created_at: "1708937850",
      updated_at: "1708937855",
    },
    {
      bet_id: "d1d6cc5d-4140-4a67-9a0e-cf0d3581b3a8",
      bet_id_ui: "240226170893735108832",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "back",
      odds: 1.08,
      bet_amount: 200,
      profit: 16,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708937345",
      requested_odds: 1.06,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708937351",
      matched_at_odds: 1.08,
      ip_address: "59.152.54.146",
      created_at: "1708937345",
      updated_at: "1708937351",
    },
    {
      bet_id: "f97eb638-6ee4-44d5-836c-e98df80ade09",
      bet_id_ui: "240226170893714242267",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 1.14,
      bet_amount: 200,
      profit: 200,
      liability: 28,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708937136",
      requested_odds: 1.14,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708937142",
      matched_at_odds: 1.14,
      ip_address: "59.152.54.146",
      created_at: "1708937136",
      updated_at: "1708937142",
    },
    {
      bet_id: "167a04f2-8219-45d0-848e-e3dd81ac85bf",
      bet_id_ui: "240226170893706365370",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7d626b25-f4b0-46e4-a5d7-6dbef75f39c9",
      event_name: "Fortune Barishal v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "back",
      odds: 1.14,
      bet_amount: 200,
      profit: 28,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708937058",
      requested_odds: 1.13,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708937063",
      matched_at_odds: 1.14,
      ip_address: "59.152.54.146",
      created_at: "1708937058",
      updated_at: "1708937063",
    },
    {
      bet_id: "caf7803a-c721-4bc3-b69e-bd42f0bff531",
      bet_id_ui: "240226170893234034637",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "lay",
      odds: 1.09,
      bet_amount: 200,
      profit: 200,
      liability: 18,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708932334",
      requested_odds: 1.11,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708932340",
      matched_at_odds: 1.09,
      ip_address: "59.152.54.146",
      created_at: "1708932334",
      updated_at: "1708932340",
    },
    {
      bet_id: "7cb8e9be-b824-415a-b1c9-8cc9203508c7",
      bet_id_ui: "240226170893226794374",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "00376f6f-8358-4d13-8bc4-c6425560933b",
      event_name: "Moez Echargui v Felix Gill",
      market_name: "Match Odds",
      runner_name: "Moez Echargui",
      type: "lay",
      odds: 1.24,
      bet_amount: 200,
      profit: 200,
      liability: 48,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708932262",
      requested_odds: 1.24,
      league_id: "0c0cb15d-90ac-45e1-9ac7-4100c0304593",
      provider_game_id: "2",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708932262",
      updated_at: "1708932268",
      matched_at: "1708932277",
      matched_at_odds: 1.01,
    },
    {
      bet_id: "4b790e9a-2c07-42c5-9623-b3e2e8968820",
      bet_id_ui: "240226170893215270487",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "00376f6f-8358-4d13-8bc4-c6425560933b",
      event_name: "Moez Echargui v Felix Gill",
      market_name: "Match Odds",
      runner_name: "Moez Echargui",
      type: "back",
      odds: 1.47,
      bet_amount: 200,
      profit: 94,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708932147",
      requested_odds: 1.34,
      league_id: "0c0cb15d-90ac-45e1-9ac7-4100c0304593",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708932152",
      matched_at_odds: 1.47,
      ip_address: "59.152.54.146",
      created_at: "1708932147",
      updated_at: "1708932152",
    },
    {
      bet_id: "3654cf5e-c7ad-4404-b870-9f45eade9999",
      bet_id_ui: "240226170893188760826",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "00376f6f-8358-4d13-8bc4-c6425560933b",
      event_name: "Moez Echargui v Felix Gill",
      market_name: "Match Odds",
      runner_name: "Moez Echargui",
      type: "back",
      odds: 1.7,
      bet_amount: 200,
      profit: 140,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708931881",
      requested_odds: 1.7,
      league_id: "0c0cb15d-90ac-45e1-9ac7-4100c0304593",
      provider_game_id: "2",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708931881",
      updated_at: "1708931887",
      matched_at: "1708931913",
      matched_at_odds: 1.72,
    },
    {
      bet_id: "6d296107-6f9b-483b-9f21-55e7fe51768d",
      bet_id_ui: "240226170893135655351",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "96fd6d64-9d47-4283-9338-610b99420b38",
      event_name: "Samuel Vincent Ruggeri v Alexey Zakharov",
      market_name: "Match Odds",
      runner_name: "Samuel Vincent Ruggeri",
      type: "back",
      odds: 1.05,
      bet_amount: 200,
      profit: 10,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708931350",
      requested_odds: 1.02,
      league_id: "0c0cb15d-90ac-45e1-9ac7-4100c0304593",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708931356",
      matched_at_odds: 1.05,
      ip_address: "59.152.54.146",
      created_at: "1708931350",
      updated_at: "1708931356",
    },
    {
      bet_id: "e461c95b-b411-464c-93c2-1e2a2620934c",
      bet_id_ui: "240226170893101142424",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "2d4f4895-64fe-4c7f-9295-842219e58fb3",
      event_name: "Peshawar Zalmi v Islamabad United",
      market_name: "Match Odds",
      runner_name: "Peshawar Zalmi",
      type: "back",
      odds: 2.02,
      bet_amount: 200,
      profit: 204,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708931005",
      requested_odds: 2.02,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708931011",
      matched_at_odds: 2.02,
      ip_address: "59.152.54.146",
      created_at: "1708931005",
      updated_at: "1708931011",
    },
    {
      bet_id: "f64a6de5-e498-4ebc-a8d5-f5d427ac55af",
      bet_id_ui: "240223170868669634434",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "6dc02e13-96be-4493-83b4-bf95c1b2f84b",
      event_name: "Comilla Victorians v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 4.4,
      bet_amount: 150,
      profit: 510,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708686690",
      requested_odds: 4.4,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708686690",
      updated_at: "1708686696",
      matched_at: "1708686805",
      matched_at_odds: 4.4,
    },
    {
      bet_id: "32f5298b-fc43-4f1e-a30d-b404e7b3dbc0",
      bet_id_ui: "240223170868523508221",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 3.35,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708685229",
      requested_odds: 3.35,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708685229",
      updated_at: "1708685235",
      is_win: false,
      outcome: "canceled",
      settled_at: "1708685280",
    },
    {
      bet_id: "1882d1d1-731d-4121-b40c-67a96931f4e3",
      bet_id_ui: "240223170868519306340",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 1.63,
      bet_amount: 150,
      profit: 94.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708685187",
      requested_odds: 1.63,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708685187",
      updated_at: "1708685193",
      matched_at: "1708685264",
      matched_at_odds: 1.63,
    },
    {
      bet_id: "c98aff63-cce3-41bf-b369-eb436f39542f",
      bet_id_ui: "240223170868412859996",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 3.6,
      bet_amount: 150,
      profit: 390,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708684122",
      requested_odds: 3.6,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708684128",
      matched_at_odds: 3.6,
      ip_address: "192.168.0.1",
      created_at: "1708684122",
      updated_at: "1708684128",
    },
    {
      bet_id: "3119e292-0fae-49fb-8545-c1574e6611d8",
      bet_id_ui: "240223170868372872946",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 3.8,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708683723",
      requested_odds: 3.8,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708683723",
      updated_at: "1708683728",
      is_win: false,
      outcome: "canceled",
      settled_at: "1708684091",
    },
    {
      bet_id: "d668268a-5527-4be1-afa8-cb072412505e",
      bet_id_ui: "240223170868371305795",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "1735c9e3-896b-4fac-93b6-34613b6f5cb6",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 3.75,
      bet_amount: 150,
      profit: 412.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708683707",
      requested_odds: 3.75,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708683713",
      matched_at_odds: 3.75,
      ip_address: "192.168.0.1",
      created_at: "1708683707",
      updated_at: "1708683713",
    },
    {
      bet_id: "5008a7b3-80a2-41e8-b50b-1abec2fad2f8",
      bet_id_ui: "240222170859819172446",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "77f5bef5-b55c-4678-897e-13eb121c88c2",
      event_name: "Multan Sultans v Peshawar Zalmi",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.74,
      bet_amount: 150,
      profit: 111,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708598186",
      requested_odds: 1.74,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708598191",
      matched_at_odds: 1.74,
      ip_address: "59.152.54.146",
      created_at: "1708598186",
      updated_at: "1708598191",
    },
    {
      bet_id: "03157091-2eb1-4d17-9dc6-bc76aea94be9",
      bet_id_ui: "240222170859798888896",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "77f5bef5-b55c-4678-897e-13eb121c88c2",
      event_name: "Multan Sultans v Peshawar Zalmi",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.74,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708597983",
      requested_odds: 1.74,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708597989",
      matched_at_odds: 1.74,
      ip_address: "59.152.54.146",
      created_at: "1708597983",
      updated_at: "1708597989",
      is_win: false,
      outcome: "voided",
      settled_at: "1708598112",
      void_reason: "Event Suspended or Canceled",
      voided_at: "1708598112",
    },
    {
      bet_id: "4dba5c9c-1f6d-4045-9486-510049e77fd4",
      bet_id_ui: "240222170859735100854",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "164fbcc1-c4a1-408a-93b5-8c401445ea6d",
      event_name: "Mumbai Indians Women v Delhi Capitals Women",
      market_name: "Match Odds",
      runner_name: "Mumbai Indians Women",
      type: "back",
      odds: 1.79,
      bet_amount: 150,
      profit: 118.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708597345",
      requested_odds: 1.79,
      league_id: "18b1f4fe-d98b-4d4c-b174-37a0f978cb23",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708597351",
      matched_at_odds: 1.79,
      ip_address: "59.152.54.146",
      created_at: "1708597345",
      updated_at: "1708597351",
    },
    {
      bet_id: "e082e978-3fc3-4034-a5a0-2f075cdf025e",
      bet_id_ui: "240222170858233755112",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "9b996bcd-ad52-4c2a-ae31-7916310d3eec",
      event_name: "Gunnedah (AUS) 22nd Feb",
      market_name: "R9 340m Gr5",
      runner_name: "1. Moneys On",
      type: "back",
      odds: 5.8,
      bet_amount: 150,
      profit: 720,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708582331",
      requested_odds: 5.3,
      league_id: "ba8d6e6f-5e3b-43dd-9d93-631358632150",
      provider_game_id: "4339",
      fromOneClick: 0,
      matched_at: "1708582337",
      matched_at_odds: 5.8,
      ip_address: "59.152.54.146",
      created_at: "1708582331",
      updated_at: "1708582337",
    },
    {
      bet_id: "4d1deca4-657c-4507-b3a9-0e7683101297",
      bet_id_ui: "240222170858226695377",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "86e88fc3-b9c9-4335-a9b9-abca7376654e",
      event_name: "Grafton (AUS) 22nd Feb",
      market_name: "R9 1420m Hcap",
      runner_name: "1. Soarhi",
      type: "back",
      odds: 6.8,
      bet_amount: 150,
      profit: 870,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708582261",
      requested_odds: 6.8,
      league_id: "88ffbbb0-91d8-48e8-9f0b-05a2d07fb8a5",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708582267",
      matched_at_odds: 6.8,
      ip_address: "59.152.54.146",
      created_at: "1708582261",
      updated_at: "1708582267",
    },
    {
      bet_id: "51c1739b-07f5-432a-bced-a9c3b15e0874",
      bet_id_ui: "240222170858145848884",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "164fbcc1-c4a1-408a-93b5-8c401445ea6d",
      event_name: "Mumbai Indians Women v Delhi Capitals Women",
      market_name: "Match Odds",
      runner_name: "Mumbai Indians Women",
      type: "back",
      odds: 1.79,
      bet_amount: 200,
      profit: 158,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708581452",
      requested_odds: 1.79,
      league_id: "18b1f4fe-d98b-4d4c-b174-37a0f978cb23",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708581458",
      matched_at_odds: 1.79,
      ip_address: "59.152.54.146",
      created_at: "1708581452",
      updated_at: "1708581458",
    },
    {
      bet_id: "42dfb0f7-e772-4a46-844b-5c190c487f8f",
      bet_id_ui: "240222170858101172552",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "164fbcc1-c4a1-408a-93b5-8c401445ea6d",
      event_name: "Mumbai Indians Women v Delhi Capitals Women",
      market_name: "Match Odds",
      runner_name: "Mumbai Indians Women",
      type: "back",
      odds: 1.79,
      bet_amount: 150,
      profit: 118.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708581006",
      requested_odds: 1.79,
      league_id: "18b1f4fe-d98b-4d4c-b174-37a0f978cb23",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708581011",
      matched_at_odds: 1.79,
      ip_address: "59.152.54.146",
      created_at: "1708581006",
      updated_at: "1708581011",
    },
    {
      bet_id: "ff8c520a-eb77-411b-82d5-18002219bb1e",
      bet_id_ui: "240222170857895585798",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "46752503-3ae8-45cf-842c-366127ce6c8b",
      event_name: "Alexander Zverev v Yoshihito Nishioka",
      market_name: "Match Odds",
      runner_name: "Alexander Zverev",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708578950",
      requested_odds: 1.01,
      league_id: "c933e532-ecc1-4609-9f34-c4fd9047b9ea",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708578956",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708578950",
      updated_at: "1708578956",
    },
    {
      bet_id: "ac7db51e-83d3-425d-9332-71b4527908e5",
      bet_id_ui: "240221170849627216244",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "20f4485e-6f3e-4abe-bb1c-a2fd6de1f08e",
      event_name: "Quetta Gladiators v Islamabad United",
      market_name: "Match Odds",
      runner_name: "Quetta Gladiators",
      type: "back",
      odds: 1.93,
      bet_amount: 200,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708496266",
      requested_odds: 1.93,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708496272",
      matched_at_odds: 1.93,
      ip_address: "59.152.54.146",
      created_at: "1708496266",
      updated_at: "1708496272",
      is_win: false,
      outcome: "voided",
      settled_at: "1708597847",
      void_reason: "Event Suspended or Canceled",
      voided_at: "1708597847",
    },
    {
      bet_id: "b3757b4e-94d8-41af-8a88-be16109c780d",
      bet_id_ui: "240221170849504952268",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "20f4485e-6f3e-4abe-bb1c-a2fd6de1f08e",
      event_name: "Quetta Gladiators v Islamabad United",
      market_name: "Match Odds",
      runner_name: "Quetta Gladiators",
      type: "back",
      odds: 1.93,
      bet_amount: 200,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708495043",
      requested_odds: 1.93,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708495049",
      matched_at_odds: 1.93,
      ip_address: "59.152.54.146",
      created_at: "1708495043",
      updated_at: "1708495049",
      is_win: false,
      outcome: "voided",
      settled_at: "1708597838",
      void_reason: "Event Suspended or Canceled",
      voided_at: "1708597838",
    },
    {
      bet_id: "fdaa29dc-d570-4333-ad1e-4f7c9e2a04aa",
      bet_id_ui: "240221170849353510913",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "20f4485e-6f3e-4abe-bb1c-a2fd6de1f08e",
      event_name: "Quetta Gladiators v Islamabad United",
      market_name: "Match Odds",
      runner_name: "Quetta Gladiators",
      type: "back",
      odds: 1.93,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708493529",
      requested_odds: 1.92,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708493535",
      matched_at_odds: 1.93,
      ip_address: "59.152.54.146",
      created_at: "1708493529",
      updated_at: "1708493535",
      is_win: false,
      outcome: "voided",
      settled_at: "1708597838",
      void_reason: "Event Suspended or Canceled",
      voided_at: "1708597838",
    },
    {
      bet_id: "1c3cade9-4afa-4ace-99c4-2e5304df3808",
      bet_id_ui: "240221170849129149035",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "2c5507a8-33e1-495f-9263-0ba3e623aeb9",
      event_name: "Correcaminos UAT v Club Atletico Morelia",
      market_name: "Match Odds",
      runner_name: "Correcaminos Uat",
      type: "back",
      odds: 1.16,
      bet_amount: 150,
      profit: 24,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708491285",
      requested_odds: 1.16,
      league_id: "5ef97be7-4724-4ac2-b7a4-1b6476862291",
      provider_game_id: "1",
      fromOneClick: 0,
      matched_at: "1708491291",
      matched_at_odds: 1.16,
      ip_address: "59.152.54.146",
      created_at: "1708491285",
      updated_at: "1708491291",
    },
    {
      bet_id: "2ad287ea-d4f8-4dc1-a8f1-762256f9317c",
      bet_id_ui: "240221170849125208017",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "2c5507a8-33e1-495f-9263-0ba3e623aeb9",
      event_name: "Correcaminos UAT v Club Atletico Morelia",
      market_name: "Match Odds",
      runner_name: "Correcaminos Uat",
      type: "back",
      odds: 1.19,
      bet_amount: 150,
      profit: 28.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708491246",
      requested_odds: 1.15,
      league_id: "5ef97be7-4724-4ac2-b7a4-1b6476862291",
      provider_game_id: "1",
      fromOneClick: 0,
      matched_at: "1708491252",
      matched_at_odds: 1.19,
      ip_address: "59.152.54.146",
      created_at: "1708491246",
      updated_at: "1708491252",
    },
    {
      bet_id: "d900af41-6894-4824-9185-6757bf35a6b4",
      bet_id_ui: "240220170843039996336",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "ccfce66e-e3c7-4dc2-a2fc-b7d590bb3d25",
      event_name: "Multan Sultans v Lahore Qalandars",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.83,
      bet_amount: 400,
      profit: 332,
      liability: 400,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708430394",
      requested_odds: 1.77,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708430400",
      matched_at_odds: 1.83,
      ip_address: "59.152.54.146",
      created_at: "1708430394",
      updated_at: "1708430400",
    },
    {
      bet_id: "27896245-964b-426c-95db-4ae67ce4c174",
      bet_id_ui: "240220170843036231335",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "ccfce66e-e3c7-4dc2-a2fc-b7d590bb3d25",
      event_name: "Multan Sultans v Lahore Qalandars",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.83,
      bet_amount: 300,
      profit: 249,
      liability: 300,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708430356",
      requested_odds: 1.77,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708430362",
      matched_at_odds: 1.83,
      ip_address: "59.152.54.146",
      created_at: "1708430356",
      updated_at: "1708430362",
    },
    {
      bet_id: "6703c810-0eff-4f39-9482-ccbfdcdae2fe",
      bet_id_ui: "240220170842887450362",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "ccfce66e-e3c7-4dc2-a2fc-b7d590bb3d25",
      event_name: "Multan Sultans v Lahore Qalandars",
      market_name: "Match Odds",
      runner_name: "Multan Sultans",
      type: "back",
      odds: 1.83,
      bet_amount: 300,
      profit: 249,
      liability: 300,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708428868",
      requested_odds: 1.77,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708428874",
      matched_at_odds: 1.83,
      ip_address: "59.152.54.146",
      created_at: "1708428868",
      updated_at: "1708428874",
    },
    {
      bet_id: "ccd0f764-dbe7-43e0-bc39-0868937d6250",
      bet_id_ui: "240220170842842967519",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "0efcd330-c3fe-4baf-90f9-5d4b2335a822",
      event_name: "Pohang Steelers v Jeonbuk Motors",
      market_name: "Match Odds",
      runner_name: "Pohang Steelers",
      type: "back",
      odds: 1.42,
      bet_amount: 200,
      profit: 84,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708428424",
      requested_odds: 1.41,
      league_id: "ad7a8e91-d8f9-4dbd-ace5-5b3504cb2ea3",
      provider_game_id: "1",
      fromOneClick: 0,
      matched_at: "1708428429",
      matched_at_odds: 1.42,
      ip_address: "59.152.54.146",
      created_at: "1708428424",
      updated_at: "1708428429",
    },
    {
      bet_id: "ebb50cf7-5bae-40a3-8388-f42824671c74",
      bet_id_ui: "240220170842834289361",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "0efcd330-c3fe-4baf-90f9-5d4b2335a822",
      event_name: "Pohang Steelers v Jeonbuk Motors",
      market_name: "Match Odds",
      runner_name: "Pohang Steelers",
      type: "back",
      odds: 1.44,
      bet_amount: 200,
      profit: 88,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708428337",
      requested_odds: 1.44,
      league_id: "ad7a8e91-d8f9-4dbd-ace5-5b3504cb2ea3",
      provider_game_id: "1",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708428337",
      updated_at: "1708428343",
      matched_at: "1708429017",
      matched_at_odds: 4.9,
    },
    {
      bet_id: "eafa2a00-a7a1-47d7-b1c6-6b5d2c7a68a6",
      bet_id_ui: "240220170842547068479",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "81bd7e1e-bfcc-4f91-b8e8-c869a8f45ef4",
      event_name: "Amelia Waligora v Gabriela Knutson",
      market_name: "Match Odds",
      runner_name: "Amelia Waligora",
      type: "back",
      odds: 1.54,
      bet_amount: 200,
      profit: 108,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708425465",
      requested_odds: 1.54,
      league_id: "b4295cb5-62e9-4dfa-bd45-0ad4da3a82a4",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1708425470",
      matched_at_odds: 1.54,
      ip_address: "59.152.54.146",
      created_at: "1708425465",
      updated_at: "1708425470",
    },
    {
      bet_id: "553e4010-9215-4bf6-9492-7346306505a8",
      bet_id_ui: "240220170842464661032",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "d4dda653-ae30-42a1-8b40-97f752c2a4ef",
      event_name: "August Holmgren v Jesper De Jong",
      market_name: "Match Odds",
      runner_name: "August Holmgren",
      type: "back",
      odds: 1.78,
      bet_amount: 200,
      profit: 156,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708424640",
      requested_odds: 1.78,
      league_id: "d7632e1b-fa91-45a5-9934-aad2cadacfe0",
      provider_game_id: "2",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708424640",
      updated_at: "1708424646",
      matched_at: "1708424662",
      matched_at_odds: 1.78,
    },
    {
      bet_id: "d9360a3b-8d02-4d1a-a421-50b80ce99755",
      bet_id_ui: "240219170834871942026",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "f4bfdd85-c1cf-4137-8c55-c1b615be7ddb",
      event_name: "Rangpur Riders v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Rangpur Riders",
      type: "back",
      odds: 3.16,
      bet_amount: 150,
      profit: 324,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708348713",
      requested_odds: 3.16,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708348713",
      updated_at: "1708348719",
      matched_at: "1708348735",
      matched_at_odds: 3.2,
    },
    {
      bet_id: "0c686546-a8f0-4362-be1f-b62a0ecb5efb",
      bet_id_ui: "240219170834866614981",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "f4bfdd85-c1cf-4137-8c55-c1b615be7ddb",
      event_name: "Rangpur Riders v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Rangpur Riders",
      type: "back",
      odds: 3.25,
      bet_amount: 150,
      profit: 337.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708348660",
      requested_odds: 3.25,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708348660",
      updated_at: "1708348666",
      matched_at: "1708348828",
      matched_at_odds: 3.25,
    },
    {
      bet_id: "74005609-efdf-4d36-b79e-9cd0f5b85e61",
      bet_id_ui: "240219170834084656419",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "d2a49766-cab9-4fe9-a4b2-41af39354841",
      event_name: "Genclerbirligi v Bandirmaspor",
      market_name: "Match Odds",
      runner_name: "Genclerbirligi",
      type: "back",
      odds: 2.86,
      bet_amount: 200,
      profit: 372,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708340840",
      requested_odds: 2.68,
      league_id: "8d0e28bf-6b5a-472b-86d9-795f10b93f0f",
      provider_game_id: "1",
      fromOneClick: 0,
      matched_at: "1708340846",
      matched_at_odds: 2.86,
      ip_address: "59.152.54.146",
      created_at: "1708340840",
      updated_at: "1708340846",
    },
    {
      bet_id: "20a90a48-534e-42e3-b719-533955a9c50c",
      bet_id_ui: "240219170834079486690",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "d2a49766-cab9-4fe9-a4b2-41af39354841",
      event_name: "Genclerbirligi v Bandirmaspor",
      market_name: "Match Odds",
      runner_name: "Genclerbirligi",
      type: "back",
      odds: 2.94,
      bet_amount: 200,
      profit: 388,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708340789",
      requested_odds: 2.94,
      league_id: "8d0e28bf-6b5a-472b-86d9-795f10b93f0f",
      provider_game_id: "1",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708340789",
      updated_at: "1708340795",
      matched_at: "1708340955",
      matched_at_odds: 2.94,
    },
    {
      bet_id: "efcbba0c-2723-40e4-a5ac-e8d61a5e439a",
      bet_id_ui: "240219170834067004315",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "f4bfdd85-c1cf-4137-8c55-c1b615be7ddb",
      event_name: "Rangpur Riders v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Rangpur Riders",
      type: "back",
      odds: 1.83,
      bet_amount: 200,
      profit: 166,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708340664",
      requested_odds: 1.83,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708340670",
      matched_at_odds: 1.83,
      ip_address: "59.152.54.146",
      created_at: "1708340664",
      updated_at: "1708340670",
    },
    {
      bet_id: "10740d94-7265-4fea-a99c-3e6319ae71cc",
      bet_id_ui: "240219170834035712513",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "f4bfdd85-c1cf-4137-8c55-c1b615be7ddb",
      event_name: "Rangpur Riders v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Fortune Barishal",
      type: "lay",
      odds: 2.18,
      bet_amount: 200,
      profit: 200,
      liability: 236,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708340351",
      requested_odds: 2.18,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708340357",
      matched_at_odds: 2.18,
      ip_address: "59.152.54.146",
      created_at: "1708340351",
      updated_at: "1708340357",
    },
    {
      bet_id: "f4cd0885-1cbf-4ef8-bf95-0d2fb00230b8",
      bet_id_ui: "240219170834029825275",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "f4bfdd85-c1cf-4137-8c55-c1b615be7ddb",
      event_name: "Rangpur Riders v Fortune Barishal",
      market_name: "Match Odds",
      runner_name: "Rangpur Riders",
      type: "back",
      odds: 1.87,
      bet_amount: 200,
      profit: 174,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708340292",
      requested_odds: 1.87,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708340298",
      matched_at_odds: 1.87,
      ip_address: "59.152.54.146",
      created_at: "1708340292",
      updated_at: "1708340298",
    },
    {
      bet_id: "8f33627e-5526-459d-854c-d02f7587f03a",
      bet_id_ui: "240219170833999422573",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Sylhet Strikers",
      type: "lay",
      odds: 1.01,
      bet_amount: 300,
      profit: 300,
      liability: 3,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708339988",
      requested_odds: 1.01,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708339994",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708339988",
      updated_at: "1708339994",
    },
    {
      bet_id: "4839c99c-0e9f-4d0b-8936-a0375d4de8dc",
      bet_id_ui: "240219170833964996378",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Sylhet Strikers",
      type: "lay",
      odds: 1.11,
      bet_amount: 200,
      profit: 200,
      liability: 22,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708339644",
      requested_odds: 1.19,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708339650",
      matched_at_odds: 1.11,
      ip_address: "59.152.54.146",
      created_at: "1708339644",
      updated_at: "1708339650",
    },
    {
      bet_id: "178d8dab-357a-47ce-9e21-4b794a3465c3",
      bet_id_ui: "240219170833958567062",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 6,
      bet_amount: 200,
      profit: 1000,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708339580",
      requested_odds: 6,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708339580",
      updated_at: "1708339585",
      matched_at: "1708339591",
      matched_at_odds: 6.2,
    },
    {
      bet_id: "17e542f6-a23e-469b-8802-4a9c1455fce6",
      bet_id_ui: "240219170833934835568",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "lay",
      odds: 8,
      bet_amount: 300,
      profit: 300,
      liability: 2100,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708339342",
      requested_odds: 8,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708339348",
      matched_at_odds: 8,
      ip_address: "59.152.54.146",
      created_at: "1708339342",
      updated_at: "1708339348",
    },
    {
      bet_id: "ad382b0e-712d-48d0-817d-1e1f3d7bcce9",
      bet_id_ui: "240219170833922232971",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Bookmaker",
      runner_name: "Sylhet Strikers",
      type: "lay",
      odds: 25,
      bet_amount: 200,
      profit: 200,
      liability: 50,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708339221",
      requested_odds: 25,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708339222",
      matched_at_odds: 25,
      ip_address: "59.152.54.146",
      created_at: "1708339221",
      updated_at: "1708339222",
    },
    {
      bet_id: "06d6df28-2872-496c-a2c0-62caf191a302",
      bet_id_ui: "240219170833859304712",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 3.6,
      bet_amount: 200,
      profit: 520,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708338587",
      requested_odds: 3.05,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708338593",
      matched_at_odds: 3.6,
      ip_address: "59.152.54.146",
      created_at: "1708338587",
      updated_at: "1708338593",
    },
    {
      bet_id: "1a82d6d1-3a97-4f74-9a0e-2c03201352b8",
      bet_id_ui: "240219170833853827122",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 2.38,
      bet_amount: 200,
      profit: 276,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708338532",
      requested_odds: 2.26,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708338538",
      matched_at_odds: 2.38,
      ip_address: "59.152.54.146",
      created_at: "1708338532",
      updated_at: "1708338538",
    },
    {
      bet_id: "2ad67168-0da3-4cfa-8b21-ae6da886a280",
      bet_id_ui: "240219170833824410192",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 1.59,
      bet_amount: 200,
      profit: 118,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708338238",
      requested_odds: 1.57,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708338244",
      matched_at_odds: 1.59,
      ip_address: "59.152.54.146",
      created_at: "1708338238",
      updated_at: "1708338244",
    },
    {
      bet_id: "10c86779-a881-46e1-89e8-72332a518f98",
      bet_id_ui: "240219170833675445387",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "cec3d3c9-86a3-4b34-b87a-2a1a51f7e746",
      event_name: "Comilla Victorians v Sylhet Strikers",
      market_name: "Match Odds",
      runner_name: "Comilla Victorians",
      type: "back",
      odds: 2.1,
      bet_amount: 200,
      profit: 220,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708336748",
      requested_odds: 2.1,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708336748",
      updated_at: "1708336754",
      matched_at: "1708338335",
      matched_at_odds: 2.1,
    },
    {
      bet_id: "810b2823-854f-4210-98ea-52b906534999",
      bet_id_ui: "240216170808162833778",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 1.98,
      bet_amount: 150,
      profit: 147,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708081622",
      requested_odds: 1.98,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708081622",
      updated_at: "1708081628",
      matched_at: "1708081652",
      matched_at_odds: 1.98,
      is_win: false,
      outcome: "loss",
      settled_at: "1708665421",
    },
    {
      bet_id: "2bbe8c5c-671e-4ce2-a98f-047d7a04ace2",
      bet_id_ui: "240216170808161117030",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 1.96,
      bet_amount: 150,
      profit: 144,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708081605",
      requested_odds: 1.96,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708081611",
      matched_at_odds: 1.96,
      ip_address: "192.168.0.1",
      created_at: "1708081605",
      updated_at: "1708081611",
      is_win: false,
      outcome: "loss",
      settled_at: "1708665421",
    },
    {
      bet_id: "05e6ab33-6e5d-4a80-a955-b43e32c5d12a",
      bet_id_ui: "240216170808130006495",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 2.36,
      bet_amount: 150,
      profit: 204,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708081294",
      requested_odds: 2.36,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708081294",
      updated_at: "1708081300",
      matched_at: "1708081307",
      matched_at_odds: 2.36,
      is_win: false,
      outcome: "loss",
      settled_at: "1708665421",
    },
    {
      bet_id: "313f4fa4-a287-4dcf-a34c-f17914348764",
      bet_id_ui: "240216170808116783282",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 2.36,
      bet_amount: 150,
      profit: 204,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708081162",
      requested_odds: 2.36,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1708081162",
      updated_at: "1708081168",
      matched_at: "1708081203",
      matched_at_odds: 2.36,
      is_win: false,
      outcome: "loss",
      settled_at: "1708665421",
    },
    {
      bet_id: "42eeecca-f0fb-473b-88d6-f5b25c1d71be",
      bet_id_ui: "240216170807733875546",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "11ab4c8c-3b7b-4c93-a468-53bec8b71262",
      event_name: "Bulli (AUS) 16th Feb",
      market_name: "R8 472m Final",
      runner_name: "1. Got The Feature",
      type: "back",
      odds: 8.6,
      bet_amount: 200,
      profit: 1520,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708077333",
      requested_odds: 8.6,
      league_id: "e7450387-71bd-4fa1-8bf9-30590086a3f1",
      provider_game_id: "4339",
      fromOneClick: 0,
      matched_at: "1708077338",
      matched_at_odds: 8.6,
      ip_address: "59.152.54.146",
      created_at: "1708077333",
      updated_at: "1708077338",
    },
    {
      bet_id: "0e2f2e83-0d14-4f18-b38c-1d2314974018",
      bet_id_ui: "240216170807722583177",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "lay",
      odds: 4.6,
      bet_amount: 300,
      profit: 300,
      liability: 1080,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708077220",
      requested_odds: 4.7,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708077226",
      matched_at_odds: 4.6,
      ip_address: "59.152.54.146",
      created_at: "1708077220",
      updated_at: "1708077226",
      is_win: false,
      outcome: "loss",
      settled_at: "1708665437",
    },
    {
      bet_id: "7fa1cd2a-4721-4866-90d5-f77e34303a08",
      bet_id_ui: "240216170807651331393",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 4.9,
      bet_amount: 200,
      profit: 780,
      liability: 200,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708076507",
      requested_odds: 4.9,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "59.152.54.146",
      created_at: "1708076507",
      updated_at: "1708076513",
      matched_at: "1708076533",
      matched_at_odds: 4.9,
      is_win: true,
      outcome: "won",
      settled_at: "1708665436",
    },
    {
      bet_id: "cf4dbfc4-ccca-4ba8-9d8c-0c6683a57fc3",
      bet_id_ui: "240216170807302989263",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "697e3ced-1e80-4674-8a0c-1bfaca0b6443",
      event_name: "Hobart (AUS) 16th Feb",
      market_name: "R5 2090m Pace M",
      runner_name: "5. Miss Papenhuyzen",
      type: "back",
      odds: 1.9,
      bet_amount: 200,
      profit: 180,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708073024",
      requested_odds: 1.9,
      league_id: "bc5078a9-08c6-44f8-ae63-de26d601ffa7",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708073030",
      matched_at_odds: 1.9,
      ip_address: "59.152.54.146",
      created_at: "1708073024",
      updated_at: "1708073030",
    },
    {
      bet_id: "250a0619-f7fe-45f7-a86a-1b5e387bafa5",
      bet_id_ui: "240216170807301035329",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "12965c73-d4d2-4115-976e-d9b39bf369be",
      event_name: "NSW Women v SA Women",
      market_name: "Match Odds",
      runner_name: "NSW Women",
      type: "back",
      odds: 1.81,
      bet_amount: 300,
      profit: 243,
      liability: 300,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708073004",
      requested_odds: 1.81,
      league_id: "8b994240-21b8-40d4-ad6c-6e679f360b01",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708073010",
      matched_at_odds: 1.81,
      ip_address: "59.152.54.146",
      created_at: "1708073004",
      updated_at: "1708073010",
      is_win: true,
      outcome: "won",
      settled_at: "1708664195",
    },
    {
      bet_id: "bec950f8-1736-4211-b2e9-762d81838981",
      bet_id_ui: "240216170807298354471",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "87a12ecf-9388-4bb1-9b69-c77ac5089c40",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Bookmaker",
      runner_name: "Khulna Tigers",
      type: "back",
      odds: 29,
      bet_amount: 200,
      profit: 58,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708072983",
      requested_odds: 29,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708072983",
      matched_at_odds: 29,
      ip_address: "59.152.54.146",
      created_at: "1708072983",
      updated_at: "1708072983",
    },
    {
      bet_id: "2f69b5de-9f42-4835-87d5-227b003af891",
      bet_id_ui: "240216170807293344541",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "87a12ecf-9388-4bb1-9b69-c77ac5089c40",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Durdanto Dhaka",
      type: "back",
      odds: 4.2,
      bet_amount: 300,
      profit: 960,
      liability: 300,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708072927",
      requested_odds: 4.2,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708072933",
      matched_at_odds: 4.2,
      ip_address: "59.152.54.146",
      created_at: "1708072927",
      updated_at: "1708072933",
    },
    {
      bet_id: "70417ab1-de90-4f96-a372-4db89d1e553e",
      bet_id_ui: "240216170807273539725",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "4026b4fe-4b32-40aa-abe1-de6b8a6625d5",
      event_name: "Fairview (RSA) 16th Feb",
      market_name: "R4 2000m Hcap",
      runner_name: "Lady Writer",
      type: "back",
      odds: 5.5,
      bet_amount: 200,
      profit: 900,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708072729",
      requested_odds: 5.5,
      league_id: "217b1d69-32b1-4646-a3e9-4a0ab10c194b",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708072735",
      matched_at_odds: 5.5,
      ip_address: "59.152.54.146",
      created_at: "1708072729",
      updated_at: "1708072735",
    },
    {
      bet_id: "e6cf7fd9-839c-4b51-9926-39dd75f9c647",
      bet_id_ui: "240216170807268134557",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "7f15c480-e1c0-4ded-9f8b-0ee6d37977f7",
      event_name: "Rangpur Riders v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Rangpur Riders",
      type: "back",
      odds: 1.39,
      bet_amount: 200,
      profit: 78,
      liability: 200,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708072675",
      requested_odds: 1.39,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708072681",
      matched_at_odds: 1.39,
      ip_address: "59.152.54.146",
      created_at: "1708072675",
      updated_at: "1708072681",
      is_win: true,
      outcome: "won",
      settled_at: "1708609151",
    },
    {
      bet_id: "40e957d0-4fe5-49bf-95ae-2625e142d409",
      bet_id_ui: "240216170807228920225",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "8ee9c80a-a498-4bfe-8500-13bd7826d64a",
      event_name: "Queensland Women v WA Women",
      market_name: "Match Odds",
      runner_name: "WA Women",
      type: "lay",
      odds: 2.98,
      bet_amount: 300,
      profit: 300,
      liability: 594,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708072283",
      requested_odds: 3,
      league_id: "8b994240-21b8-40d4-ad6c-6e679f360b01",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708072289",
      matched_at_odds: 2.98,
      ip_address: "59.152.54.146",
      created_at: "1708072283",
      updated_at: "1708072289",
    },
    {
      bet_id: "8e26c6ee-746f-466b-b2e1-42c7a1bc13de",
      bet_id_ui: "240216170807169345521",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "570619bd-ae08-4bda-9a06-1f38b477115b",
      event_name: "Melton (AUS) 16th Feb",
      market_name: "R7 1720m Trot M",
      runner_name: "11. Exalted",
      type: "back",
      odds: 5.1,
      bet_amount: 300,
      profit: 1230,
      liability: 300,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708071687",
      requested_odds: 5.1,
      league_id: "8487f581-c773-404e-a8f4-1b5e8dcec21b",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708071693",
      matched_at_odds: 5.1,
      ip_address: "59.152.54.146",
      created_at: "1708071687",
      updated_at: "1708071693",
    },
    {
      bet_id: "8aecbc6d-d84c-46ff-8186-2f3bd913f7de",
      bet_id_ui: "240216170807165568839",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "26f2577c-123f-4199-88e1-b16b444afa04",
      event_name: "Quetta Gladiators v Peshawar Zalmi",
      market_name: "Match Odds",
      runner_name: "Peshawar Zalmi",
      type: "lay",
      odds: 2.14,
      bet_amount: 200,
      profit: 200,
      liability: 228,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      placed_at: "1708071650",
      requested_odds: 2.14,
      league_id: "2c4e0d21-c82a-4896-8fe3-8f4b63fffc15",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708071655",
      matched_at_odds: 2.14,
      ip_address: "59.152.54.146",
      created_at: "1708071650",
      updated_at: "1708071655",
      is_win: true,
      outcome: "won",
      settled_at: "1708664996",
    },
    {
      bet_id: "ccd2dbb5-319b-465e-8fce-0b02f1cca7c4",
      bet_id_ui: "240216170807141739391",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "424c5907-dbad-43bc-b7b1-6b8dd4523873",
      event_name: "Auckland Aces v Central Stags",
      market_name: "Match Odds",
      runner_name: "Central Stags",
      type: "back",
      odds: 1.25,
      bet_amount: 500,
      profit: 125,
      liability: 500,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708071411",
      requested_odds: 1.04,
      league_id: "fc8a4dbe-e93d-4002-a080-06efbb763fcc",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708071417",
      matched_at_odds: 1.25,
      ip_address: "59.152.54.146",
      created_at: "1708071411",
      updated_at: "1708071417",
    },
    {
      bet_id: "6f816709-789e-4682-ace4-e38b41d0e4a1",
      bet_id_ui: "240216170807113025688",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "87a12ecf-9388-4bb1-9b69-c77ac5089c40",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Khulna Tigers",
      type: "back",
      odds: 1.6,
      bet_amount: 200,
      profit: 120,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708071124",
      requested_odds: 1.57,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708071130",
      matched_at_odds: 1.6,
      ip_address: "59.152.54.146",
      created_at: "1708071124",
      updated_at: "1708071130",
    },
    {
      bet_id: "d74f8ca7-4c9d-4866-a38a-ae283593dd26",
      bet_id_ui: "240216170807110926118",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "87a12ecf-9388-4bb1-9b69-c77ac5089c40",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Durdanto Dhaka",
      type: "back",
      odds: 2.62,
      bet_amount: 200,
      profit: 324,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708071103",
      requested_odds: 2.62,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708071109",
      matched_at_odds: 2.62,
      ip_address: "59.152.54.146",
      created_at: "1708071103",
      updated_at: "1708071109",
    },
    {
      bet_id: "c985c306-45ea-49e5-9de5-cb549162564d",
      bet_id_ui: "240216170807064411700",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "570619bd-ae08-4bda-9a06-1f38b477115b",
      event_name: "Melton (AUS) 16th Feb",
      market_name: "R7 1720m Trot M",
      runner_name: "5. Hephaestus Phoenix",
      type: "back",
      odds: 4.3,
      bet_amount: 200,
      profit: 660,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708070638",
      requested_odds: 3.5,
      league_id: "8487f581-c773-404e-a8f4-1b5e8dcec21b",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708070644",
      matched_at_odds: 4.3,
      ip_address: "59.152.54.146",
      created_at: "1708070638",
      updated_at: "1708070644",
    },
    {
      bet_id: "1ddb1039-c3d7-42cd-b9e3-c1719d7251f2",
      bet_id_ui: "240216170807061295165",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "570619bd-ae08-4bda-9a06-1f38b477115b",
      event_name: "Melton (AUS) 16th Feb",
      market_name: "R7 1720m Trot M",
      runner_name: "5. Hephaestus Phoenix",
      type: "back",
      odds: 4.3,
      bet_amount: 200,
      profit: 660,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: false,
      placed: "M",
      placed_at: "1708070607",
      requested_odds: 3.5,
      league_id: "8487f581-c773-404e-a8f4-1b5e8dcec21b",
      provider_game_id: "7",
      fromOneClick: 0,
      matched_at: "1708070613",
      matched_at_odds: 4.3,
      ip_address: "59.152.54.146",
      created_at: "1708070607",
      updated_at: "1708070613",
    },
    {
      bet_id: "4f793733-a2cc-4446-9a36-b09dadbbc964",
      bet_id_ui: "240216170806794977820",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 8,
      bet_amount: 200,
      profit: 1400,
      liability: 200,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708067944",
      requested_odds: 7.6,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708067949",
      matched_at_odds: 8,
      ip_address: "59.152.54.146",
      created_at: "1708067944",
      updated_at: "1708067949",
      is_win: true,
      outcome: "won",
      settled_at: "1708665436",
    },
    {
      bet_id: "e121f08d-1c36-414b-9ca8-ee57fe1116be",
      bet_id_ui: "240216170806791789458",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "lay",
      odds: 8.2,
      bet_amount: 200,
      profit: 200,
      liability: 1440,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708067912",
      requested_odds: 8.2,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708067918",
      matched_at_odds: 8.2,
      ip_address: "59.152.54.146",
      created_at: "1708067912",
      updated_at: "1708067918",
      is_win: false,
      outcome: "loss",
      settled_at: "1708665437",
    },
    {
      bet_id: "b33535fd-e68f-4e5b-af53-908874cc18ad",
      bet_id_ui: "240216170806788779055",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 8,
      bet_amount: 200,
      profit: 1400,
      liability: 200,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708067882",
      requested_odds: 7.4,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708067888",
      matched_at_odds: 8,
      ip_address: "59.152.54.146",
      created_at: "1708067882",
      updated_at: "1708067888",
      is_win: true,
      outcome: "won",
      settled_at: "1708665436",
    },
    {
      bet_id: "ae8ff154-4835-4e0b-aa4b-9a89d5996a87",
      bet_id_ui: "240216170806784835265",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "England",
      type: "back",
      odds: 8,
      bet_amount: 200,
      profit: 1400,
      liability: 200,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1708067842",
      requested_odds: 7.6,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708067848",
      matched_at_odds: 8,
      ip_address: "59.152.54.146",
      created_at: "1708067842",
      updated_at: "1708067848",
      is_win: true,
      outcome: "won",
      settled_at: "1708665436",
    },
    {
      bet_id: "5b4b2930-7260-4c13-8992-21b8f3cc23f6",
      bet_id_ui: "240216170806682331318",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "25403191-2428-4c66-9b70-e38feee50b6a",
      event_name: "Australia Women v South Africa Women",
      market_name: "Match Odds",
      runner_name: "Australia Women",
      type: "lay",
      odds: 1.01,
      bet_amount: 150,
      profit: 150,
      liability: 1.5,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708066817",
      requested_odds: 1.01,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708066823",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708066817",
      updated_at: "1708066823",
    },
    {
      bet_id: "9cef963a-ae0a-4352-ab1d-022309f81d8e",
      bet_id_ui: "240216170806328732523",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "25403191-2428-4c66-9b70-e38feee50b6a",
      event_name: "Australia Women v South Africa Women",
      market_name: "Match Odds",
      runner_name: "Australia Women",
      type: "lay",
      odds: 1.01,
      bet_amount: 200,
      profit: 200,
      liability: 2,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1708063272",
      requested_odds: 1.01,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1708063287",
      matched_at_odds: 1.01,
      ip_address: "59.152.54.146",
      created_at: "1708063272",
      updated_at: "1708063287",
    },
    {
      bet_id: "539ec443-dac6-4443-aa7b-4bd50704f832",
      bet_id_ui: "240215170799673478571",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Bookmaker",
      runner_name: "India",
      type: "back",
      odds: 32,
      bet_amount: 400,
      profit: 128,
      liability: 400,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1707996729",
      requested_odds: 32,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707996734",
      matched_at_odds: 32,
      ip_address: "59.152.54.146",
      created_at: "1707996729",
      updated_at: "1707996734",
      is_win: false,
      outcome: "loss",
      settled_at: "1708666625",
    },
    {
      bet_id: "485a8816-d97c-4c3d-acdc-583d9f8bfb89",
      bet_id_ui: "240215170799671432848",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "628158e4-743b-4d5f-a8ce-68541f52d25b",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 1.33,
      bet_amount: 300,
      profit: 99,
      liability: 300,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1707996699",
      requested_odds: 1.33,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707996714",
      matched_at_odds: 1.33,
      ip_address: "59.152.54.146",
      created_at: "1707996699",
      updated_at: "1707996714",
      is_win: false,
      outcome: "loss",
      settled_at: "1708665437",
    },
    {
      bet_id: "f56fa625-0742-4103-90c5-a25b53b5baaf",
      bet_id_ui: "240214170790069952340",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "bdd15315-bcf3-43d3-a62f-21aa3429bb2d",
      event_name: "Fortune Barishal v Durdanto Dhaka",
      market_name: "20 Over FB",
      runner_name: "195",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707900699",
      requested_odds: 100,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707900699",
      matched_at_odds: 100,
      ip_address: "192.168.0.1",
      created_at: "1707900699",
      updated_at: "1707900699",
    },
    {
      bet_id: "a954a996-9f86-433b-9324-9e364492f6e7",
      bet_id_ui: "240214170790061202279",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "b040f99a-ec6a-4b9b-9a6e-8766d7501a8b",
      event_name: "Benjamin Bonzi v Maks Kasnikowski",
      market_name: "Match Odds",
      runner_name: "Maks Kasnikowski",
      type: "back",
      odds: 1.32,
      bet_amount: 200,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      placed_at: "1707900606",
      requested_odds: 1.32,
      league_id: "16fdb062-8ce8-4fba-adf2-8cacbc06041e",
      provider_game_id: "2",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1707900606",
      updated_at: "1707900612",
      is_win: false,
      outcome: "canceled",
      settled_at: "1707988636",
    },
    {
      bet_id: "9af1a1c7-137a-4988-b984-de870c3cf8de",
      bet_id_ui: "240213170780641082826",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "92adb34d-351c-483f-af8c-85bcd5ebced6",
      event_name: "Alexey Zakharov v Samuel Vincent Ruggeri",
      market_name: "Match Odds",
      runner_name: "Alexey Zakharov",
      type: "back",
      odds: 1.34,
      bet_amount: 200,
      profit: 68,
      liability: 200,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707806405",
      requested_odds: 1.34,
      league_id: "16fdb062-8ce8-4fba-adf2-8cacbc06041e",
      provider_game_id: "2",
      fromOneClick: 0,
      matched_at: "1707806411",
      matched_at_odds: 1.34,
      ip_address: "192.168.0.1",
      created_at: "1707806405",
      updated_at: "1707806411",
    },
    {
      bet_id: "173a1ea0-5a73-4c22-b4b1-02b0a1d72be0",
      bet_id_ui: "240210170755370547637",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "ed950368-4acd-476e-95e4-019a5410d792",
      event_name: "Australia Women v South Africa Women",
      market_name: "Match Odds",
      runner_name: "Australia Women",
      type: "back",
      odds: 1.06,
      bet_amount: 150,
      profit: 9,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707553699",
      requested_odds: 1.04,
      league_id: "634d67e9-26cb-4637-b594-17c7a231bfcf",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707553705",
      matched_at_odds: 1.06,
      ip_address: "219.91.180.32",
      created_at: "1707553699",
      updated_at: "1707553705",
    },
    {
      bet_id: "df7b1e72-805a-44e7-889a-d64ca1d7c8f5",
      bet_id_ui: "240210170755341275626",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "ed950368-4acd-476e-95e4-019a5410d792",
      event_name: "Australia Women v South Africa Women",
      market_name: "Match Odds",
      runner_name: "Australia Women",
      type: "back",
      odds: 1.06,
      bet_amount: 150,
      profit: 9,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707553406",
      requested_odds: 1.06,
      league_id: "634d67e9-26cb-4637-b594-17c7a231bfcf",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707553412",
      matched_at_odds: 1.06,
      ip_address: "219.91.180.32",
      created_at: "1707553406",
      updated_at: "1707553412",
    },
    {
      bet_id: "3cfddf7d-1a46-4096-93ee-1bfa2b2ed954",
      bet_id_ui: "240210170755322433498",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "ed950368-4acd-476e-95e4-019a5410d792",
      event_name: "Australia Women v South Africa Women",
      market_name: "Bookmaker",
      runner_name: "Australia Women",
      type: "back",
      odds: 5.5,
      bet_amount: 150,
      profit: 8.25,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707553223",
      requested_odds: 5.5,
      league_id: "634d67e9-26cb-4637-b594-17c7a231bfcf",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707553224",
      matched_at_odds: 5.5,
      ip_address: "219.91.180.32",
      created_at: "1707553223",
      updated_at: "1707553224",
    },
    {
      bet_id: "c50a7729-6daf-4087-977d-86824f3f9e96",
      bet_id_ui: "240210170755177220247",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "4441a7e9-6279-43a7-a3ae-004a5599126a",
      event_name: "Canterbury Kings v Northern Districts",
      market_name: "Match Odds",
      runner_name: "Canterbury Kings",
      type: "back",
      odds: 1.2,
      bet_amount: 150,
      profit: 30,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707551766",
      requested_odds: 1.17,
      league_id: "fc8a4dbe-e93d-4002-a080-06efbb763fcc",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707551772",
      matched_at_odds: 1.2,
      ip_address: "219.91.180.32",
      created_at: "1707551766",
      updated_at: "1707551772",
    },
    {
      bet_id: "ada72ddd-b9bc-460b-8fb5-45f7a208b67c",
      bet_id_ui: "240209170748363021958",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "Match Odds",
      runner_name: "Sri Lanka",
      type: "back",
      odds: 1.02,
      bet_amount: 150,
      profit: 3,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707483624",
      requested_odds: 1.02,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707483630",
      matched_at_odds: 1.02,
      ip_address: "59.152.54.146",
      created_at: "1707483624",
      updated_at: "1707483630",
    },
    {
      bet_id: "54034652-d8bf-4945-99e1-2bbea0cabe06",
      bet_id_ui: "240209170747793457171",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "50 Over SL",
      runner_name: "350",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477934",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477934",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477934",
      updated_at: "1707477934",
    },
    {
      bet_id: "04b5e4d3-8a75-4ff4-9e92-4da733899077",
      bet_id_ui: "240209170747775442978",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "35 Over SL",
      runner_name: "223",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477753",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477754",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477753",
      updated_at: "1707477754",
    },
    {
      bet_id: "60b018b7-6b96-44a8-a91b-13066e6d5a91",
      bet_id_ui: "240209170747773640413",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "35 Over SL",
      runner_name: "223",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477735",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477736",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477735",
      updated_at: "1707477736",
    },
    {
      bet_id: "f7f250bf-7ae7-4d8f-b413-8f3f412ad03f",
      bet_id_ui: "240209170747770720355",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "35 Over SL",
      runner_name: "223",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477706",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477707",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477706",
      updated_at: "1707477707",
    },
    {
      bet_id: "2fd3b364-70bc-4150-b4bb-a166e332215d",
      bet_id_ui: "240209170747758062655",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "50 Over SL",
      runner_name: "350",
      type: "lay",
      odds: 100,
      bet_amount: 185,
      profit: 185,
      liability: 185,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477579",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477580",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477579",
      updated_at: "1707477580",
    },
    {
      bet_id: "f48bab14-16ab-4ea2-88d8-a64bace40eed",
      bet_id_ui: "240209170747754068021",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "50 Over SL",
      runner_name: "350",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477540",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477540",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477540",
      updated_at: "1707477540",
    },
    {
      bet_id: "43d0a9c7-cda9-405b-a1b0-5232d2ea28c6",
      bet_id_ui: "240209170747746965768",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "50 Over SL",
      runner_name: "350",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477469",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477469",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477469",
      updated_at: "1707477469",
    },
    {
      bet_id: "bef02843-042d-4f84-bfe7-0d43aa7a9067",
      bet_id_ui: "240209170747743583331",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "6dfc7219-734a-45c7-9886-6de7e7cf82ff",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "50 Over SL",
      runner_name: "350",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      placed_at: "1707477435",
      requested_odds: 100,
      league_id: "81f778f1-61a2-4b16-bf62-7e4b72cc848e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707477436",
      matched_at_odds: 100,
      ip_address: "59.152.54.146",
      created_at: "1707477435",
      updated_at: "1707477436",
    },
    {
      bet_id: "f39696e2-dedd-42b0-bf49-0bc7a50053c1",
      bet_id_ui: "240208170739036388749",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "c1e7908f-3eed-4a5c-bac9-6525d0304813",
      event_name: "Australia U19 v Pakistan U19",
      market_name: "45 Over PAK U19",
      runner_name: "163",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707390364",
      matched_at_odds: 100,
      ip_address: "2409:40c1:1c:572b:fd06:8d9a:11dd:815f",
      created_at: "1707390364",
      updated_at: "1707390364",
    },
    {
      bet_id: "93516abc-81ee-4895-a224-c21b20c2e46b",
      bet_id_ui: "240208170739032343329",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "c1e7908f-3eed-4a5c-bac9-6525d0304813",
      event_name: "Australia U19 v Pakistan U19",
      market_name: "45th Over Runs Only PAK U19",
      runner_name: "5",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707390323",
      matched_at_odds: 100,
      ip_address: "2409:40c1:1c:572b:fd06:8d9a:11dd:815f",
      created_at: "1707390323",
      updated_at: "1707390323",
    },
    {
      bet_id: "dc21595d-632d-4c18-ae69-24d49293667a",
      bet_id_ui: "240208170739032331963",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "c1e7908f-3eed-4a5c-bac9-6525d0304813",
      event_name: "Australia U19 v Pakistan U19",
      market_name: "45th Over Runs Only PAK U19",
      runner_name: "5",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707390323",
      matched_at_odds: 100,
      ip_address: "2401:4900:1c02:10b3:53ed:7c11:27f6:6843",
      created_at: "1707390323",
      updated_at: "1707390323",
    },
    {
      bet_id: "c1bdeb20-e9f1-4b3c-984d-51d1b6c25a8d",
      bet_id_ui: "240208170739030105841",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "c1e7908f-3eed-4a5c-bac9-6525d0304813",
      event_name: "Australia U19 v Pakistan U19",
      market_name: "45 Over PAK U19",
      runner_name: "162",
      type: "lay",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1707390301",
      matched_at_odds: 100,
      ip_address: "2401:4900:1c02:10b3:53ed:7c11:27f6:6843",
      created_at: "1707390301",
      updated_at: "1707390301",
    },
    {
      bet_id: "369e6baa-21aa-49a1-84e8-a6226f796d8a",
      bet_id_ui: "240205170712796130972",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "2e5b4712-2ff6-4d73-a7ef-226c9dc28c64",
      event_name: "Garcia v S Cirstea",
      market_name: "Match Odds",
      runner_name: "Caroline Garcia",
      type: "back",
      odds: 1.25,
      bet_amount: 250,
      profit: 62.5,
      liability: 250,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 1.25,
      league_id: "14e5e493-d723-48ec-8c5b-4aa61789fb75",
      provider_game_id: "2",
      fromOneClick: 0,
      ip_address: "2409:40c1:102e:5189:9144:9b89:d21:343c",
      created_at: "1707127961",
      updated_at: "1707127961",
      matched_at: "1707127966",
      matched_at_odds: 1.25,
    },
    {
      bet_id: "93d397b8-eda4-4b74-bb47-93cb2c953d00",
      bet_id_ui: "240202170687420996813",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "83a2e0d9-e5f0-442e-b0a0-e04a165ca8c4",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "Match Odds",
      runner_name: "Sri Lanka",
      type: "back",
      odds: 5,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 5,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706874210",
      updated_at: "1706874210",
      is_win: false,
      outcome: "canceled",
      settled_at: "1707124289",
    },
    {
      bet_id: "def25070-fa91-4c1d-ad73-391483c5f3ee",
      bet_id_ui: "240202170687419242396",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "83a2e0d9-e5f0-442e-b0a0-e04a165ca8c4",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "Match Odds",
      runner_name: "Sri Lanka",
      type: "back",
      odds: 1.05,
      bet_amount: 150,
      profit: 7.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.05,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 1,
      ip_address: "192.168.0.1",
      created_at: "1706874192",
      updated_at: "1706874192",
      matched_at: "1706874207",
      matched_at_odds: 1.05,
      is_win: true,
      outcome: "won",
      settled_at: "1707127149",
    },
    {
      bet_id: "ff684e76-4752-4a01-9bf7-ddc211353781",
      bet_id_ui: "240202170687391973848",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "83a2e0d9-e5f0-442e-b0a0-e04a165ca8c4",
      event_name: "Sri Lanka v Afghanistan",
      market_name: "Match Odds",
      runner_name: "Sri Lanka",
      type: "back",
      odds: 1.05,
      bet_amount: 150,
      profit: 7.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.05,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 1,
      ip_address: "192.168.0.1",
      created_at: "1706873919",
      updated_at: "1706873919",
      matched_at: "1706873933",
      matched_at_odds: 1.05,
      is_win: true,
      outcome: "won",
      settled_at: "1707127149",
    },
    {
      bet_id: "a49f8ca7-c74f-49ab-90ee-da418d2f4395",
      bet_id_ui: "240202170687018052761",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "b18b10fa-0c9e-4dd9-8b6f-7b2535cd3c39",
      event_name: "India v England",
      market_name: "6th WKT Caught Out IND @ 1",
      runner_name: "Yes",
      type: "back",
      odds: 60,
      bet_amount: 150,
      profit: 90,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 60,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706870180",
      matched_at_odds: 60,
      ip_address: "192.168.0.1",
      created_at: "1706870180",
      updated_at: "1706870180",
      is_win: false,
      outcome: "loss",
      settled_at: "1707127260",
    },
    {
      bet_id: "b1f9330e-0623-4e1e-8c79-b3fc6540f060",
      bet_id_ui: "240202170686999779365",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "b18b10fa-0c9e-4dd9-8b6f-7b2535cd3c39",
      event_name: "India v England",
      market_name: "90 OVER TOTAL ODD IND",
      runner_name: "Odd",
      type: "back",
      odds: 98,
      bet_amount: 150,
      profit: 147,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 98,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706869997",
      matched_at_odds: 98,
      ip_address: "192.168.0.1",
      created_at: "1706869997",
      updated_at: "1706869997",
      is_win: false,
      outcome: "loss",
      settled_at: "1707127256",
    },
    {
      bet_id: "3e571848-b51d-4d13-8475-4b3b4ebcc627",
      bet_id_ui: "240202170686996238983",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "b18b10fa-0c9e-4dd9-8b6f-7b2535cd3c39",
      event_name: "India v England",
      market_name: "Bookmaker",
      runner_name: "India",
      type: "back",
      odds: 29,
      bet_amount: 150,
      profit: 43.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 29,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706869962",
      matched_at_odds: 29,
      ip_address: "192.168.0.1",
      created_at: "1706869962",
      updated_at: "1706869962",
      is_win: true,
      outcome: "won",
      settled_at: "1707127242",
    },
    {
      bet_id: "2002bfa1-41af-4d8f-9630-1d1d14724bd4",
      bet_id_ui: "240202170686992949458",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "b18b10fa-0c9e-4dd9-8b6f-7b2535cd3c39",
      event_name: "India v England",
      market_name: "Y Jaiswal Boundaries",
      runner_name: "27",
      type: "back",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706869929",
      matched_at_odds: 100,
      ip_address: "192.168.0.1",
      created_at: "1706869929",
      updated_at: "1706869929",
      is_win: true,
      outcome: "won",
      settled_at: "1707127260",
    },
    {
      bet_id: "ff478710-1f50-499c-afcf-c7c7b3e126d0",
      bet_id_ui: "240202170686915090388",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "b18b10fa-0c9e-4dd9-8b6f-7b2535cd3c39",
      event_name: "India v England",
      market_name: "Match Odds",
      runner_name: "India",
      type: "back",
      odds: 1.28,
      bet_amount: 150,
      profit: 42,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.28,
      league_id: "4acde4fd-0827-43e9-97da-865779d8efe4",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706869151",
      updated_at: "1706869151",
      matched_at: "1706869164",
      matched_at_odds: 1.28,
      is_win: true,
      outcome: "won",
      settled_at: "1707127253",
    },
    {
      bet_id: "5c220707-f613-4322-aa40-b560b504d0ca",
      bet_id_ui: "240202170686902318294",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "eb9bf0fa-178e-4555-bef1-2ed3b5b01977",
      event_name: "Hapoel Mahane Yehuda v Hapoel Hod Hasharon",
      market_name: "Match Odds",
      runner_name: "The Draw",
      type: "back",
      odds: 1.26,
      bet_amount: 150,
      profit: 39,
      liability: 150,
      bet_type: "matched",
      bet_status: "unsettle",
      in_play: true,
      placed: "M",
      requested_odds: 1.25,
      league_id: "1bcb0c79-5595-4a9f-a9da-6cf7e85971de",
      provider_game_id: "1",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706869023",
      updated_at: "1706869023",
      matched_at: "1706869036",
      matched_at_odds: 1.26,
    },
    {
      bet_id: "70160482-b48b-44e6-9fd9-6fd1c81076f3",
      bet_id_ui: "240201170678444088721",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.6,
      bet_amount: 150,
      profit: 90,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.6,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706784441",
      updated_at: "1706784441",
      matched_at: "1706784456",
      matched_at_odds: 1.6,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "13f7b40a-ba6e-48c3-afb8-878bd87a7dcd",
      bet_id_ui: "240201170678437056168",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.68,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.68,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706784370",
      updated_at: "1706784370",
      is_win: false,
      outcome: "canceled",
      settled_at: "1706801414",
    },
    {
      bet_id: "0e99e7cc-b493-40fd-9dfb-b2aab47ebf01",
      bet_id_ui: "240201170678431213362",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.75,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.75,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706784312",
      updated_at: "1706784312",
      is_win: false,
      outcome: "canceled",
      settled_at: "1706801413",
    },
    {
      bet_id: "28893862-6fb1-400b-8e41-d6d929621f6f",
      bet_id_ui: "240201170678428506646",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.77,
      bet_amount: 150,
      profit: 115.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.76,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706784285",
      updated_at: "1706784285",
      matched_at: "1706784293",
      matched_at_odds: 1.77,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "7af4e6c9-8113-4eef-a9c9-50d372c1c642",
      bet_id_ui: "240201170678411904765",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.71,
      bet_amount: 150,
      profit: 106.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.7,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706784119",
      updated_at: "1706784119",
      matched_at: "1706784127",
      matched_at_odds: 1.71,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "c810b77b-ebe6-4872-9ea5-d7bdac0581fe",
      bet_id_ui: "240201170678397031172",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Namibia U19",
      type: "back",
      odds: 2.46,
      bet_amount: 150,
      profit: 219,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 2.46,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783970",
      updated_at: "1706783970",
      matched_at: "1706784120",
      matched_at_odds: 2.46,
      is_win: true,
      outcome: "won",
      settled_at: "1706862451",
    },
    {
      bet_id: "bf716ed4-c299-4d43-9d20-18472af8dd92",
      bet_id_ui: "240201170678390253370",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.73,
      bet_amount: 150,
      profit: 109.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.73,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783902",
      updated_at: "1706783902",
      matched_at: "1706784146",
      matched_at_odds: 1.76,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "60e95bf1-4151-47f9-b460-01f78cac555c",
      bet_id_ui: "240201170678362491340",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.51,
      bet_amount: 150,
      profit: 76.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.51,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783625",
      updated_at: "1706783625",
      matched_at: "1706783655",
      matched_at_odds: 1.51,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "fa56b490-3d14-43d5-9585-f67986df35ce",
      bet_id_ui: "240201170678347375149",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.56,
      bet_amount: 150,
      profit: 84,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.56,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783473",
      updated_at: "1706783473",
      matched_at: "1706783687",
      matched_at_odds: 1.65,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "284fbfc4-e04e-4ce1-9158-d161e3e36610",
      bet_id_ui: "240201170678343023640",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Scotland U19",
      type: "back",
      odds: 1.51,
      bet_amount: 150,
      profit: 76.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.5,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783430",
      updated_at: "1706783430",
      matched_at: "1706783438",
      matched_at_odds: 1.51,
      is_win: false,
      outcome: "loss",
      settled_at: "1706862451",
    },
    {
      bet_id: "d8bb443b-0bac-415e-b64c-0da530d8164d",
      bet_id_ui: "240201170678324157191",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Match Odds",
      runner_name: "Namibia U19",
      type: "back",
      odds: 2.78,
      bet_amount: 150,
      profit: 267,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 2.72,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706783241",
      updated_at: "1706783241",
      matched_at: "1706783250",
      matched_at_odds: 2.78,
      is_win: true,
      outcome: "won",
      settled_at: "1706862451",
    },
    {
      bet_id: "c30a20ed-8bb0-4e25-a216-e5a717c6fcfc",
      bet_id_ui: "240201170677854735176",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "3rd WKT Pship Boundaries SCO U19",
      runner_name: "7",
      type: "back",
      odds: 100,
      bet_amount: 150,
      profit: 150,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 100,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706778547",
      matched_at_odds: 100,
      ip_address: "192.168.0.1",
      created_at: "1706778547",
      updated_at: "1706778547",
      is_win: true,
      outcome: "won",
      settled_at: "1706862487",
    },
    {
      bet_id: "56dd1c7d-ca4b-4373-9614-1cfe96874798",
      bet_id_ui: "240201170677830061933",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Owen Gould Runs",
      runner_name: "41",
      type: "back",
      odds: 90,
      bet_amount: 150,
      profit: 135,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 90,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706778300",
      matched_at_odds: 90,
      ip_address: "192.168.0.1",
      created_at: "1706778300",
      updated_at: "1706778300",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862469",
    },
    {
      bet_id: "81f49317-179f-4328-a046-34ea40f71c1e",
      bet_id_ui: "240201170677781342675",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "Owen Gould Runs",
      runner_name: "27",
      type: "back",
      odds: 90,
      bet_amount: 150,
      profit: 135,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 90,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706777813",
      matched_at_odds: 90,
      ip_address: "192.168.0.1",
      created_at: "1706777813",
      updated_at: "1706777813",
      is_win: true,
      outcome: "won",
      settled_at: "1706862469",
    },
    {
      bet_id: "e7de2f9c-5f68-4eef-b3d1-f3809f075715",
      bet_id_ui: "240201170677738533515",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "806067c4-be3b-4f35-b58a-d0d3d2499d9b",
      event_name: "Namibia U19 v Scotland U19",
      market_name: "12th Over Runs Only SCO U19",
      runner_name: "4",
      type: "back",
      odds: 85,
      bet_amount: 150,
      profit: 127.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 85,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706777385",
      matched_at_odds: 85,
      ip_address: "192.168.0.1",
      created_at: "1706777385",
      updated_at: "1706777385",
      is_win: true,
      outcome: "won",
      settled_at: "1706862492",
    },
    {
      bet_id: "e9eb8573-ad4b-453d-8b5c-aa91ae969204",
      bet_id_ui: "240201170677186893080",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "1bb88aec-017f-4603-82c7-7b38f2450372",
      event_name: "Western Australia v New South Wales Blues",
      market_name: "20 OVER TOTAL ODD  NSWB",
      runner_name: "Odd",
      type: "back",
      odds: 98,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 98,
      league_id: "40702442-a62f-4241-9e96-85da45fdf019",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706771869",
      matched_at_odds: 98,
      ip_address: "192.168.0.1",
      created_at: "1706771869",
      updated_at: "1706771869",
      is_win: false,
      outcome: "voided",
      settled_at: "1706862293",
      void_reason: "Market Suspended or Canceled",
      voided_at: "1706862293",
    },
    {
      bet_id: "0176283a-8bf5-48e4-91a7-d8121f1b8cb6",
      bet_id_ui: "240201170677102485859",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "1bb88aec-017f-4603-82c7-7b38f2450372",
      event_name: "Western Australia v New South Wales Blues",
      market_name: "2nd  WKT Caught Out  NSWB @ 1",
      runner_name: "1",
      type: "back",
      odds: 50,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 50,
      league_id: "40702442-a62f-4241-9e96-85da45fdf019",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706771025",
      matched_at_odds: 50,
      ip_address: "192.168.0.1",
      created_at: "1706771025",
      updated_at: "1706771025",
      is_win: false,
      outcome: "voided",
      settled_at: "1706862297",
      void_reason: "Market Suspended or Canceled",
      voided_at: "1706862297",
    },
    {
      bet_id: "341ba32b-de65-4252-a667-f5e77cfc7d9c",
      bet_id_ui: "240201170677087392194",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "1bb88aec-017f-4603-82c7-7b38f2450372",
      event_name: "Western Australia v New South Wales Blues",
      market_name: "10 OVER TOTAL ODD  NSWB",
      runner_name: "1",
      type: "back",
      odds: 98,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 98,
      league_id: "40702442-a62f-4241-9e96-85da45fdf019",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706770874",
      matched_at_odds: 98,
      ip_address: "192.168.0.1",
      created_at: "1706770874",
      updated_at: "1706770874",
      is_win: false,
      outcome: "voided",
      settled_at: "1706862289",
      void_reason: "Market Suspended or Canceled",
      voided_at: "1706862289",
    },
    {
      bet_id: "8f876e7d-f7fd-48dd-b6b3-50a48c3f398c",
      bet_id_ui: "240201170677085947270",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "1bb88aec-017f-4603-82c7-7b38f2450372",
      event_name: "Western Australia v New South Wales Blues",
      market_name: "Macdonald Runs",
      runner_name: "22",
      type: "lay",
      odds: 140,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 140,
      league_id: "40702442-a62f-4241-9e96-85da45fdf019",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706770859",
      matched_at_odds: 140,
      ip_address: "192.168.0.1",
      created_at: "1706770859",
      updated_at: "1706770859",
      is_win: false,
      outcome: "voided",
      settled_at: "1706862301",
      void_reason: "Market Suspended or Canceled",
      voided_at: "1706862301",
    },
    {
      bet_id: "c0c55885-b91d-4163-a60f-73a66a7b5ce0",
      bet_id_ui: "240131170670729987931",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "4f022dda-bc40-4ae8-a1ac-f5b7b24465d9",
      event_name: "Australia U19 v England U19",
      market_name: "Match Odds",
      runner_name: "Australia U19",
      type: "back",
      odds: 5,
      bet_amount: 150,
      profit: 0,
      liability: 0,
      bet_type: "un-matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 5,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706707300",
      updated_at: "1706707300",
      is_win: false,
      outcome: "canceled",
      settled_at: "1706707320",
    },
    {
      bet_id: "648aad3d-a7c2-4c6b-8449-34531a05f539",
      bet_id_ui: "240131170670425250775",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "4f022dda-bc40-4ae8-a1ac-f5b7b24465d9",
      event_name: "Australia U19 v England U19",
      market_name: "Match Odds",
      runner_name: "Australia U19",
      type: "back",
      odds: 1.44,
      bet_amount: 150,
      profit: 66,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.42,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706704252",
      updated_at: "1706704252",
      matched_at: "1706704261",
      matched_at_odds: 1.44,
      is_win: true,
      outcome: "won",
      settled_at: "1706862293",
    },
    {
      bet_id: "b85aec7e-2ce3-4167-8cfb-0754df2bb333",
      bet_id_ui: "240131170669812068993",
      user_name: "kramilu001",
      member_code: "SA00A00SM00M01U0002",
      event_id: "4f022dda-bc40-4ae8-a1ac-f5b7b24465d9",
      event_name: "Australia U19 v England U19",
      market_name: "Match Odds",
      runner_name: "Australia U19",
      type: "lay",
      odds: 1.43,
      bet_amount: 150,
      profit: 150,
      liability: 64.5,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.46,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706698120",
      matched_at_odds: 1.43,
      ip_address: "122.170.11.110",
      created_at: "1706698120",
      updated_at: "1706698120",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862280",
    },
    {
      bet_id: "3104584d-8995-401e-92df-fe8084aef6de",
      bet_id_ui: "240131170669807149363",
      user_name: "kramilu001",
      member_code: "SA00A00SM00M01U0002",
      event_id: "4f022dda-bc40-4ae8-a1ac-f5b7b24465d9",
      event_name: "Australia U19 v England U19",
      market_name: "Match Odds",
      runner_name: "Australia U19",
      type: "lay",
      odds: 1.46,
      bet_amount: 150,
      profit: 150,
      liability: 69,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.46,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706698071",
      matched_at_odds: 1.46,
      ip_address: "122.170.11.110",
      created_at: "1706698071",
      updated_at: "1706698071",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862280",
    },
    {
      bet_id: "086f9587-500c-4706-8980-5e044be1d8c6",
      bet_id_ui: "240131170669805512835",
      user_name: "kramilu001",
      member_code: "SA00A00SM00M01U0002",
      event_id: "4f022dda-bc40-4ae8-a1ac-f5b7b24465d9",
      event_name: "Australia U19 v England U19",
      market_name: "Match Odds",
      runner_name: "Australia U19",
      type: "lay",
      odds: 1.46,
      bet_amount: 150,
      profit: 150,
      liability: 69,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.46,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706698055",
      matched_at_odds: 1.46,
      ip_address: "122.170.11.110",
      created_at: "1706698055",
      updated_at: "1706698055",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862280",
    },
    {
      bet_id: "8ad7a5b7-45e5-4a6b-ad64-96062117194d",
      bet_id_ui: "240131170668591871596",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "ebaa7455-2b3a-4f39-8719-da2e3371789f",
      event_name: "South Africa U19 v Zimbabwe U19",
      market_name: "Match Odds",
      runner_name: "South Africa U19",
      type: "back",
      odds: 1.11,
      bet_amount: 150,
      profit: 16.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.11,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 1,
      ip_address: "192.168.0.1",
      created_at: "1706685918",
      updated_at: "1706685918",
      matched_at: "1706685927",
      matched_at_odds: 1.11,
      is_win: true,
      outcome: "won",
      settled_at: "1706862418",
    },
    {
      bet_id: "c9665636-014b-439c-8e09-87a1abfb2943",
      bet_id_ui: "240131170668473116443",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "ebaa7455-2b3a-4f39-8719-da2e3371789f",
      event_name: "South Africa U19 v Zimbabwe U19",
      market_name: "Match Odds",
      runner_name: "South Africa U19",
      type: "back",
      odds: 1.1,
      bet_amount: 150,
      profit: 15,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.1,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 1,
      ip_address: "192.168.0.1",
      created_at: "1706684731",
      updated_at: "1706684731",
      matched_at: "1706684739",
      matched_at_odds: 1.1,
      is_win: true,
      outcome: "won",
      settled_at: "1706862418",
    },
    {
      bet_id: "e2621056-9856-4aa5-8022-c3b543a950a9",
      bet_id_ui: "240131170668460237152",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "ebaa7455-2b3a-4f39-8719-da2e3371789f",
      event_name: "South Africa U19 v Zimbabwe U19",
      market_name: "Match Odds",
      runner_name: "South Africa U19",
      type: "back",
      odds: 1.11,
      bet_amount: 150,
      profit: 16.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.11,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 1,
      ip_address: "192.168.0.1",
      created_at: "1706684602",
      updated_at: "1706684602",
      matched_at: "1706684610",
      matched_at_odds: 1.11,
      is_win: true,
      outcome: "won",
      settled_at: "1706862418",
    },
    {
      bet_id: "b91c86f6-f4e6-4ff1-99dc-f3b803b6f8fb",
      bet_id_ui: "240131170667709228064",
      user_name: "kramilu001",
      member_code: "SA00A00SM00M01U0002",
      event_id: "2322b75e-c917-48bb-ab14-b6ed73b9ebb7",
      event_name: "Guadalajara v Toluca",
      market_name: "Match Odds",
      runner_name: "Toluca",
      type: "back",
      odds: 4.4,
      bet_amount: 150,
      profit: 510,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 4.4,
      league_id: "247c5ee5-680c-4c3e-ac0b-901e932fa45e",
      provider_game_id: "1",
      fromOneClick: 0,
      matched_at: "1706677092",
      matched_at_odds: 4.4,
      ip_address: "122.170.11.110",
      created_at: "1706677092",
      updated_at: "1706677092",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862986",
    },
    {
      bet_id: "cd2947bf-8b23-4358-b126-4ababadaa045",
      bet_id_ui: "240129170653458941886",
      user_name: "sheezan",
      member_code: "SA00A00SM00M00U0002",
      event_id: "16b76115-6877-4c71-9190-02ce6014961f",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Khulna Tigers",
      type: "back",
      odds: 1.43,
      bet_amount: 150,
      profit: 64.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.43,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "192.168.0.1",
      created_at: "1706534589",
      updated_at: "1706534589",
      matched_at: "1706534598",
      matched_at_odds: 1.43,
      is_win: false,
      outcome: "loss",
      settled_at: "1706618380",
    },
    {
      bet_id: "2b2b1f44-5a69-4267-a014-1e15ad7b27e1",
      bet_id_ui: "240129170653262358991",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "93ad39df-e146-4137-b000-8e9739548b01",
      event_name: "Sharjah Warriors v Dubai Capitals",
      market_name: "Match Odds",
      runner_name: "Dubai Capitals",
      type: "back",
      odds: 1.76,
      bet_amount: 150,
      profit: 114,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.76,
      league_id: "54014b73-877f-4fd7-aa99-dd28cbae04e7",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706532623",
      matched_at_odds: 1.76,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706532623",
      updated_at: "1706532623",
      is_win: false,
      outcome: "loss",
      settled_at: "1706618358",
    },
    {
      bet_id: "e0010a8e-f4a4-470e-8a69-3789b29d982e",
      bet_id_ui: "240129170653259012675",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "93ad39df-e146-4137-b000-8e9739548b01",
      event_name: "Sharjah Warriors v Dubai Capitals",
      market_name: "Match Odds",
      runner_name: "Dubai Capitals",
      type: "back",
      odds: 1.76,
      bet_amount: 150,
      profit: 114,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.76,
      league_id: "54014b73-877f-4fd7-aa99-dd28cbae04e7",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706532590",
      matched_at_odds: 1.76,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706532590",
      updated_at: "1706532590",
      is_win: false,
      outcome: "loss",
      settled_at: "1706618358",
    },
    {
      bet_id: "e1b43dbb-335a-4572-b531-31c5cba0e643",
      bet_id_ui: "240129170653256102947",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "16b76115-6877-4c71-9190-02ce6014961f",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Durdanto Dhaka",
      type: "back",
      odds: 2.06,
      bet_amount: 150,
      profit: 159,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 2.06,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706532561",
      matched_at_odds: 2.06,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706532561",
      updated_at: "1706532561",
      is_win: true,
      outcome: "won",
      settled_at: "1706618387",
    },
    {
      bet_id: "b76ddfb3-5d2d-4594-a58b-f0e682a62506",
      bet_id_ui: "240129170652275207686",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "b89ee597-4094-484a-8c54-a3ff964fc0b5",
      event_name: "India U19 v New Zealand U19",
      market_name: "Match Odds",
      runner_name: "New Zealand U19",
      type: "back",
      odds: 3.55,
      bet_amount: 150,
      profit: 382.5,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 3.55,
      league_id: "73c7b6cd-0d7f-41d9-b0a1-a4d2a32af97a",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706522752",
      matched_at_odds: 3.55,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706522752",
      updated_at: "1706522752",
      is_win: false,
      outcome: "loss",
      settled_at: "1706862193",
    },
    {
      bet_id: "79bc1e53-eb58-4846-a219-cef35fb3e7cd",
      bet_id_ui: "240129170652273669378",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "93ad39df-e146-4137-b000-8e9739548b01",
      event_name: "Sharjah Warriors v Dubai Capitals",
      market_name: "Match Odds",
      runner_name: "Dubai Capitals",
      type: "back",
      odds: 1.74,
      bet_amount: 150,
      profit: 111,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.74,
      league_id: "54014b73-877f-4fd7-aa99-dd28cbae04e7",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706522736",
      matched_at_odds: 1.74,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706522736",
      updated_at: "1706522736",
      is_win: false,
      outcome: "loss",
      settled_at: "1706618358",
    },
    {
      bet_id: "2697a1da-f3ad-418c-895f-b3fb174d2c37",
      bet_id_ui: "240129170652272125326",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "16b76115-6877-4c71-9190-02ce6014961f",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Durdanto Dhaka",
      type: "back",
      odds: 2.28,
      bet_amount: 150,
      profit: 192,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 2.28,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706522721",
      matched_at_odds: 2.28,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706522721",
      updated_at: "1706522721",
      is_win: true,
      outcome: "won",
      settled_at: "1706618387",
    },
    {
      bet_id: "636e9f5c-0812-4e0c-9e8d-ec67176e441f",
      bet_id_ui: "240129170651563050811",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "fecbd9f7-4055-415c-aa8a-9477cdf618d5",
      event_name: "Sylhet Strikers v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Chattogram Challengers",
      type: "back",
      odds: 1.98,
      bet_amount: 150,
      profit: 147,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: true,
      placed: "M",
      requested_odds: 1.98,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706515630",
      updated_at: "1706515630",
      matched_at: "1706515691",
      matched_at_odds: 2,
      is_win: false,
      outcome: "loss",
      settled_at: "1706618408",
    },
    {
      bet_id: "2798b200-c345-418d-b909-e33a0c31e6fa",
      bet_id_ui: "240129170650899568674",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "fecbd9f7-4055-415c-aa8a-9477cdf618d5",
      event_name: "Sylhet Strikers v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Chattogram Challengers",
      type: "back",
      odds: 1.98,
      bet_amount: 150,
      profit: 147,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.98,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706508995",
      matched_at_odds: 1.98,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706508995",
      updated_at: "1706508995",
      is_win: false,
      outcome: "loss",
      settled_at: "1706618408",
    },
    {
      bet_id: "9e282a8c-18b1-410e-a227-38d0993535c9",
      bet_id_ui: "240129170650854666841",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "fecbd9f7-4055-415c-aa8a-9477cdf618d5",
      event_name: "Sylhet Strikers v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Sylhet Strikers",
      type: "back",
      odds: 1.02,
      bet_amount: 150,
      profit: 3,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.02,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706508546",
      matched_at_odds: 1.02,
      ip_address: "2409:40c1:39:f2e5:f3b6:521b:b0e6:1026",
      created_at: "1706508546",
      updated_at: "1706508546",
      is_win: true,
      outcome: "won",
      settled_at: "1706618416",
    },
    {
      bet_id: "e692f69f-2fbb-4dde-b413-82f07911145d",
      bet_id_ui: "240129170650854629532",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "16b76115-6877-4c71-9190-02ce6014961f",
      event_name: "Khulna Tigers v Durdanto Dhaka",
      market_name: "Match Odds",
      runner_name: "Durdanto Dhaka",
      type: "back",
      odds: 2.52,
      bet_amount: 150,
      profit: 228,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 2.52,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706508546",
      updated_at: "1706508546",
      matched_at: "1706508663",
      matched_at_odds: 2.52,
      is_win: true,
      outcome: "won",
      settled_at: "1706618387",
    },
    {
      bet_id: "7a6ee091-be99-4850-b1a0-5330e7153e32",
      bet_id_ui: "240129170650852604883",
      user_name: "kalpu",
      member_code: "SA00A00SM00M00U0000",
      event_id: "fecbd9f7-4055-415c-aa8a-9477cdf618d5",
      event_name: "Sylhet Strikers v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Sylhet Strikers",
      type: "back",
      odds: 1.02,
      bet_amount: 150,
      profit: 3,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.02,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      matched_at: "1706508526",
      matched_at_odds: 1.02,
      ip_address: "2409:40c1:39:f2e5:f3b6:521b:b0e6:1026",
      created_at: "1706508526",
      updated_at: "1706508526",
      is_win: true,
      outcome: "won",
      settled_at: "1706618416",
    },
    {
      bet_id: "6ac8a2a7-7eae-43ea-8660-0e9a1ebed092",
      bet_id_ui: "240129170650839358353",
      user_name: "dipaku1",
      member_code: "SA00A00SM00M00U0001",
      event_id: "fecbd9f7-4055-415c-aa8a-9477cdf618d5",
      event_name: "Sylhet Strikers v Chattogram Challengers",
      market_name: "Match Odds",
      runner_name: "Chattogram Challengers",
      type: "back",
      odds: 1.98,
      bet_amount: 150,
      profit: 147,
      liability: 150,
      bet_type: "matched",
      bet_status: "settle",
      in_play: false,
      placed: "M",
      requested_odds: 1.98,
      league_id: "ede49cdb-8de4-4691-9765-d5f54f75356e",
      provider_game_id: "4",
      fromOneClick: 0,
      ip_address: "2401:4900:1c02:2aa4:5739:5139:471f:3acf",
      created_at: "1706508393",
      updated_at: "1706508393",
      matched_at: "1706508410",
      matched_at_odds: 1.98,
      is_win: false,
      outcome: "loss",
      settled_at: "1706618408",
    },
  ],
};

export default betListMock_data;
