import { Stack } from "@mui/material";
import React from "react";
import MyButton from "../../../components/ui/myButton";

import { Icon } from "@iconify/react";

const ExportButtons: React.FC = () => {
  return (
    <Stack direction={"row"} columnGap={1}>
      <MyButton
        startIcon={<Icon icon="ant-design:file-pdf-outlined" />}
        sx={{ bgcolor: "#cb0606", color: "#fff", borderRadius: "0", padding: "1px 6px", height: "34px" }}
        variant="contained"
      >
        PDF
      </MyButton>
      <MyButton
        startIcon={<Icon icon="mdi:file-excel-outline" />}
        sx={{ bgcolor: "#217346", color: "#fff", borderRadius: "0", padding: "1px 6px", height: "34px" }}
        variant="contained"
      >
        Excel
      </MyButton>
    </Stack>
  );
};

export default ExportButtons;
