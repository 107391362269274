import React from "react";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";

import { Stack, TextField, Typography } from "@mui/material";
import { Logout, ReplayOutlined } from "@mui/icons-material";

import { ITemp } from "../users-table";
import endpoints from "../../../../api/endpoints";
import usePutApi from "../../../../hooks/use-put-api";
import parseNumber from "../../../../utils/parse-number";
import MyButton from "../../../../components/ui/myButton";
import CustomModal from "../../../../components/custom-modal";
import CreditLimitSchema, { ICreditLimitSchema } from "./schema/credit-limit.schema";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  rowData: ITemp;
}

const CreditLimitModal: React.FC<Props> = ({ openModal, toggleModal, rowData }) => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  const { values, errors, handleChange, handleSubmit, handleBlur, resetForm } = useFormik({
    initialValues: {
      new_credit: 0,
      transaction_password: "",
      member_operation_type: "credit",
    },
    onSubmit: (formValues) => {
      handleCreditLimitSubmit(formValues);
    },
    validationSchema: CreditLimitSchema,
  });

  // PUT WITHDRAW OPERATION DATA
  const { putData } = usePutApi(endpoints.member.operation(rowData.id), [
    endpoints.member.getDownLineHandlerById(userId),
    endpoints.member.getMember(userId),
  ]);

  const handleCreditLimitSubmit = async (submitData: ICreditLimitSchema) => {
    try {
      await putData({
        ...submitData,
        new_credit: parseNumber({ type: "float", value: values.new_credit }),
      });
      enqueueSnackbar("Exposure Limit Updated Successful", { variant: "success" });
      toggleModal();
      resetForm();
    } catch (error: any) {
      const errorMessage = error?.data?.status_message;
      enqueueSnackbar(errorMessage || "Someting Went Wrong", { variant: "error" });
      toggleModal();
      resetForm();
    }
  };

  return (
    <>
      {/* Deposit Modal */}
      <CustomModal open={openModal} onCloseFunc={toggleModal} title="Credit Limit">
        <form onSubmit={handleSubmit}>
          <Stack sx={{ "& .Mui-disabled": { bgcolor: "#ddd", textAlign: "right" } }} py={4} spacing={2}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography width={"30%"} variant="body2">
                Old Limit
              </Typography>
              <Stack width={"70%"}>
                <TextField
                  type="number"
                  sx={{ "& input": { textAlign: "right" } }}
                  fullWidth
                  disabled
                  value={rowData.credit_reference}
                  size="small"
                />
              </Stack>
            </Stack>

            <Stack direction={"row"} alignItems={"center"}>
              <Typography width={"30%"} variant="body2">
                New Limit
              </Typography>
              <Stack width={"70%"}>
                <TextField
                  type="number"
                  sx={{ "& input": { textAlign: "right" } }}
                  fullWidth
                  value={values.new_credit !== 0 ? values.new_credit : ""}
                  size="small"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="new_credit"
                />
                {errors.new_credit && (
                  <Typography mt={"0 !important"} variant="caption" color={"error"}>
                    {errors.new_credit}
                  </Typography>
                )}
              </Stack>
            </Stack>

            <Stack direction={"row"} alignItems={"center"}>
              <Typography width={"30%"} variant="body2">
                Transaction Password
              </Typography>
              <Stack width={"70%"}>
                <TextField
                  type="password"
                  fullWidth
                  value={values.transaction_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  size="small"
                  name="transaction_password"
                />
                {errors.transaction_password && (
                  <Typography mt={"0 !important"} variant="caption" color={"error"}>
                    {errors.transaction_password}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>

          <Stack direction={"row"} justifyContent={"end"} columnGap={1}>
            <MyButton
              sx={{ color: "#fff" }}
              startIcon={<ReplayOutlined />}
              variant="contained"
              color="secondary"
              onClick={toggleModal}
            >
              Back
            </MyButton>
            <MyButton
              type="submit"
              sx={{ color: "#fff" }}
              endIcon={<Logout />}
              variant="contained"
              color="primary"
            >
              Submit
            </MyButton>
          </Stack>
        </form>
      </CustomModal>
    </>
  );
};

export default CreditLimitModal;
