import moment, { Moment } from "moment";
import { enqueueSnackbar } from "notistack";
import { useState, useEffect } from "react";

import { Box, Grid, FormLabel, Typography, FormControl } from "@mui/material";
import { DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";

import { IProfitAndLossReport } from "../../../types";
import MyButton from "../../../components/ui/myButton";
import MuiDate from "../../../components/date/MuiDate";
import ReportLayout from "../../../sections/report-layout";
import ReportTitle from "../../../sections/title/report-title";
import CustomTextField from "../../../components/ui/custom-text-field";
import profitAndLossReport_mock from "../../../mocks/_profit-and-loss-report";

// const columns: GridColDef[] = [
//   {
//     field: "game_name",
//     headerName: "Game Name",
//     flex: 0.5,
//   },
//   {
//     field: "game_type",
//     headerName: "Game Type",
//     flex: 0.5,
//     renderCell: ({ row }) => (
//       <div>
//         <Button variant="contained" color="primary" size="small">
//           {row.game_type}
//         </Button>
//       </div>
//     ),
//   },
//   {
//     field: "profitAndLoss",
//     headerName: "Profit & Loss",
//     flex: 0.5,
//   },
// ];

// --------------------------------------------------
// interface Params {
//   from: string;
//   to: string;
// }
// --------------------------------------------------

const ProfitLossReportPage = () => {
  const [fromDate, setfromDate] = useState<Moment | null>(null);
  const [toDate, settoDate] = useState<Moment | null>(null);
  const [data] = useState<IProfitAndLossReport>(profitAndLossReport_mock);
  // const [mergedData, setMergedData] = useState<any[]>([]);

  const handleFromDateChange = (
    value: Moment | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    setfromDate(moment(value));
  };
  const handleToDateChange = (
    value: Moment | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => {
    settoDate(moment(value));
  };

  function handleLoad(event: any): void {
    if (fromDate && toDate) {
      // refetch();
    } else {
      enqueueSnackbar("Please Select From & To Date", { variant: "error" });
    }
  }

  useEffect(() => {
    // let mergedArray;
    // if (data && data?.bets) {
    //   mergedArray = data?.bets?.reduce((curr: any, av) => [...curr, ...av?.markets], []);
    // }
    // setMergedData(
    //   mergedArray?.map((arr: any, index: any) => ({
    //     id: index,
    //     game_name: arr?.game_name,
    //     game_type: arr?.market_name,
    //     profitAndLoss: arr?.profitAndLoss,
    //   }))
    // );
  }, [data]);

  return (
    <ReportLayout>
      <ReportTitle title="Profit Loss" />

      <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <FormControl
            fullWidth
            sx={{
              display: "flex",
              alignItems: { xs: "center", sm: "center", md: "normal" },
              flexDirection: { xs: "row", sm: "row", md: "column" },
            }}
          >
            <FormLabel sx={{ mr: { xs: 0.5, sm: 1, md: "" } }}>Search By Client Name</FormLabel>
            <CustomTextField />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <MuiDate value={moment(fromDate)} onChange={handleFromDateChange} label="From" />
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <MuiDate value={moment(toDate)} onChange={handleToDateChange} label="To" />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={1.5}>
          <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
            <MyButton onClick={(e) => handleLoad(e)} color="primary" variant="contained">
              Load
            </MyButton>
          </Box>
        </Grid>
      </Grid>

      <Typography component="h2" variant="h6" sx={{ fontSize: "18px" }}>
        Profit & Loss Event Type
      </Typography>
      <Typography
        variant="body2"
        sx={{ background: "#28a745", color: "white", width: "150px", padding: "3px", fontSize: "12px" }}
      >
        Cricket: 0.00
      </Typography>
      {/* <MuiDataGrid rows={mergedData} columns={columns} hideFooter={true} /> */}
    </ReportLayout>
  );
};

export default ProfitLossReportPage;
