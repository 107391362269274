import React, { PropsWithChildren } from "react";

import { Close } from "@mui/icons-material";
import { Box, Table, TableRow, TableCell, TableHead, TableContainer } from "@mui/material";

import FullScreenPopUp from "../full-screen-popup";
import ReportTitle from "../../../title/report-title";

interface UserBookPopupPopUp extends PropsWithChildren {
  open: boolean;
  handleClose: () => void;
}

const UserBookPopup: React.FC<UserBookPopupPopUp> = ({ open, handleClose }) => {
  return (
    <FullScreenPopUp open={open} handleClose={handleClose}>
      <Box sx={{ p: 2 }}>
        <ReportTitle
          title="User Book"
          action={
            <Box
              onClick={() => handleClose()}
              sx={{
                bgcolor: "primary.main",
                borderRadius: "50%",
                height: "28px",
                width: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Close />
            </Box>
          }
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ border: "none", p: 0, pl: 1, m: 0, bgcolor: "primary.light" }}>
                  UserName
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 8 }} />
      </Box>
    </FullScreenPopUp>
  );
};

export default UserBookPopup;
