import {
  Select,
  SxProps,
  MenuItem,
  FormLabel,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
} from "@mui/material";

const defaultValue = [{ label: "All", value: "all" }];

type Option = { label: string; value: string | number };
interface MuiSelectProps {
  name?: string;
  label?: string;
  options?: Option[];
  controlSx?: SxProps;
  value?: string;
  onChange?: (event: SelectChangeEvent) => void;
  error?: boolean;
  helperText?: string;
  onBlur?: any;
}

const MuiSelect = ({
  name,
  label = "",
  options = defaultValue,
  value,
  onChange = () => {},
  controlSx = {},
  error = false,
  helperText = "",
  onBlur,
}: MuiSelectProps) => {
  return (
    <FormControl fullWidth sx={controlSx} error={error}>
      {label && <FormLabel>{label}</FormLabel>}
      <Select
        name={name}
        sx={{
          "& .MuiSelect-select": {
            padding: "5px 10px !important",
          },
        }}
        size="small"
        fullWidth
        value={value}
        onChange={onChange}
      >
        {Array.isArray(options) &&
          options.map((op) => {
            return (
              <MenuItem
                className="custom-select-mui"
                sx={{ fontSize: "14px" }}
                key={op.value}
                value={op.value}
              >
                {op.label}
              </MenuItem>
            );
          })}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default MuiSelect;
