import React from "react";

import { Stack, TableRow, TableCell, useMediaQuery } from "@mui/material";

import { BackType } from "./types";
import theme from "../../../theme";
import BetButton from "../../../components/bet-button/bet-button";

// ---------------------------------------------------------------
interface Props {
  runner: any;
  marketStatus: string;
  marketType: string;
}
// ---------------------------------------------------------------

const SixColMarketTableRow: React.FC<Props> = ({ runner, marketStatus, marketType }) => {
  const isSuspended = Boolean(runner?.provider_status === "SUSPENDED" || marketStatus === "SUSPENDED");

  return (
    <TableRow key={runner.runner_id}>
      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }}>{runner.runner_name}</TableCell>
      <TableCell
        sx={{
          p: 0,
          position: "relative",
          "&::before": {
            content: '"Suspended"',
            position: "absolute",
            display: isSuspended ? "grid" : "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            color: "#fff",
          },
          "&::after": {
            content: '"Loser"',
            position: "absolute",
            top: 0,
            color: "red",
            // display: false ? "grid" : "none",
            display: "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "18px",
            zIndex: 10,
          },
        }}
        colSpan={6}
      >
        <BetButtonsListForSixCol marketType={marketType} runner_name={runner?.runner_name} runner={runner} />
      </TableCell>
    </TableRow>
  );
};

export default SixColMarketTableRow;

const BetButtonsListForSixCol = ({
  runner,
  runner_name,
  marketType,
}: {
  runner: any;
  runner_name: string;
  marketType: string;
}) => {
  return (
    <>
      {useMediaQuery(theme.breakpoints.up("sm")) ? (
        <Stack direction={"row"}>
          {runner?.back
            .reverse()
            .map((back: BackType, i: number) => (
              <BetButton
                marketType={marketType}
                runner_name={runner_name}
                key={i}
                size={back.size}
                isEventPage={true}
                type={"back"}
                odds={back.price}
              />
            ))}
          {runner?.lay.map((lay: BackType, i: number) => (
            <BetButton
              marketType={marketType}
              runner_name={runner_name}
              key={i}
              size={lay.size}
              isEventPage={true}
              type={"lay"}
              odds={lay.price}
            />
          ))}
        </Stack>
      ) : (
        <Stack direction={"row"}>
          <BetButton
            marketType={marketType}
            runner_name={runner_name}
            size={runner?.back[0].size}
            isEventPage={true}
            type={"back"}
            odds={runner?.back[0].price}
          />
          <BetButton
            marketType={marketType}
            runner_name={runner_name}
            size={runner?.lay[0].size}
            isEventPage={true}
            type={"lay"}
            odds={runner?.lay[0].price}
          />
        </Stack>
      )}
    </>
  );
};
