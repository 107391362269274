// KXGQ-CSXV

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import Router from "./routes/routes";
import { RootState } from "./redux/store";
import Header from "./components/header/header";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, userData } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (location.pathname === "/") {
      if (!userData?.is_password_changed) {
        navigate("/change-password");
      } else if (isLoggedIn) {
        navigate(`/market-analysis`);
      } else {
        navigate("/login");
      }
    }
  });

  return (
    <Box sx={{ width: "100%" }}>
      {isLoggedIn && <Header />}

      <Router />
    </Box>
  );
};

export default App;
