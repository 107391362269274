import { Moment } from "moment";

import { FormLabel, FormControl } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";

const MuiDate = ({ label, onChange, value }: DatePickerProps<Moment>) => (
  // const [value, setValue] = useState<moment.Moment | null>(moment("2022-04-17"));

  <FormControl fullWidth sx={{ "& .MuiInputBase-input": { padding: "5px 10px" } }}>
    {label && <FormLabel>{label}</FormLabel>}
    <DatePicker
      format="DD/MM/YYYY"
      value={value}
      onChange={onChange}
      slotProps={{
        textField: {
          size: "small",
          fullWidth: true,
        },
      }}
    />
  </FormControl>
);
export default MuiDate;
