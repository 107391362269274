import * as Yup from 'yup'

export interface IStatusSchema {
    is_bet_lock: boolean;
    is_active: boolean;
    transaction_password: string
    member_operation_type: string
}

const StatusSchema = Yup.object({
    is_bet_lock: Yup.boolean(),
    is_active: Yup.boolean(),
    transaction_password: Yup.string().required('Transaction Password is required'),
})

export default StatusSchema