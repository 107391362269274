import React, { useEffect, useState } from "react";

import { Box, Skeleton, Tab, Tabs } from "@mui/material";

import ReportTitle from "../../title/report-title";
import ReportLayout from "../../report-layout";
import MyButton from "../../../components/ui/myButton";
import UsersList from "./users-list";
// import AccountStatusHeader from "./acount-status-header";
import useGetApi from "../../../hooks/use-get-api";
import { IUser } from "../../../types/user";
import enpoints from "../../../api/endpoints";
import { Link, useLocation, useParams } from "react-router-dom";

// ----------------------------------------------------------

enum UsersTab {
  ACTIVE_USERS = "Active",
  DEACTIVE_USERS = "Inactive",
}

interface Props {
  users_type?: "all" | "active" | "inactive";
}

// ----------------------------------------------------------

const ListClientsView: React.FC<Props> = ({ users_type }) => {
  const [accountList, setAccountList] = useState<IUser[]>([]);

  const [selectedTab, setSelectedTab] = useState<string>("all");

  const { userId } = useParams();

  const { pathname } = useLocation();

  const isListClients = pathname.split("/")[1] === "list-clients";

  const { data, isLoading } = useGetApi<{ list: IUser[] }>(
    enpoints.member.getDownLineHandlerById(userId as string)
  );

  const handleChange = (_: React.SyntheticEvent<Element, Event>, value: any): void => {
    if (accountList && accountList.length) {
      setSelectedTab(value);
    }
  };

  useEffect(() => {
    setSelectedTab(isListClients ? UsersTab.ACTIVE_USERS : "all");
  }, [isListClients]);

  useEffect(() => {
    setAccountList(data?.list as IUser[]);
  }, [data]);

  return (
    <>
      {/* {users_type === "all" && <AccountStatusHeader />} */}
      <ReportLayout>
        <ReportTitle
          action={
            <Link to={`/add-account`}>
              <MyButton variant="contained">Add Account</MyButton>
            </Link>
          }
          title="Account List"
        />

        {users_type === "all" && (
          <Tabs
            onChange={handleChange}
            sx={{
              mt: "0.875rem",
              "& .Mui-selected": { bgcolor: "#fff", color: "#000 !important", border: "1px solid #00000025" },
              "& span.MuiTabs-indicator.css-q0q92n-MuiTabs-indicator": { bgcolor: "white" },
              "& .MuiTabs-flexContainer": { borderBottom: "1px solid #00000025 !important" },
            }}
            value={selectedTab}
          >
            <Tab
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{
                textTransform: "capitalize",
                color: "#007bff",
                fontSize: "16px",
              }}
              label="Active Users"
              value={UsersTab.ACTIVE_USERS}
            />
            <Tab
              disableFocusRipple
              disableRipple
              disableTouchRipple
              sx={{ textTransform: "capitalize", color: "#007bff", fontSize: "16px" }}
              label="Deactive Users"
              value={UsersTab.DEACTIVE_USERS}
            />
          </Tabs>
        )}

        <Box mb={"1rem"} />

        {isLoading ? (
          <Skeleton sx={{ maxWidth: "100%" }}>
            <UsersList selectedTab="" isListClients={false} accountList={[]} />
          </Skeleton>
        ) : (
          <UsersList selectedTab={selectedTab} isListClients={isListClients} accountList={accountList} />
        )}
      </ReportLayout>
    </>
  );
};

export default ListClientsView;
