import * as Yup from 'yup'

export interface IWithDrawSchema {
    remark: string
    amount: number
    transaction_password: string
    member_operation_type: string
}

const WithDrawSchema = Yup.object({
    remark: Yup.string().required('Remark is required'),
    amount: Yup.number().required('Amount is required').test('not-zero', 'Amount shoudl be greater than zero', value => value > 0),
    transaction_password: Yup.string().required('Transaction Password is required'),
    member_operation_type: Yup.mixed().oneOf(['deposit', 'withdraw'])
})

export default WithDrawSchema