import React from "react";

import { Stack, TableCell, TableRow } from "@mui/material";

import BetButton from "../../../components/bet-button/bet-button";

// ---------------------------------------------------------------
interface Props {
  marketStatus: string;
  market: any;
  variant: "small" | "large";
  marketType: string;
}
// ---------------------------------------------------------------

const FancyMarketTableRow: React.FC<Props> = ({ market, marketStatus, variant, marketType }) => {
  const isSuspended = Boolean(market?.provider_status === "SUSPENDED" || marketStatus === "SUSPENDED");

  return (
    <TableRow key={market.runner_id}>
      <TableCell sx={{ fontSize: "14px", fontWeight: "700" }}>{market.market_name}</TableCell>
      <TableCell
        sx={{
          p: 0,
          position: "relative",
          "&::before": {
            content: '"Suspended"',
            position: "absolute",
            display: isSuspended ? "grid" : "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            color: "#fff",
          },
          "&::after": {
            content: '"Loser"',
            position: "absolute",
            top: 0,
            color: "red",
            // display: false ? 'grid' : 'none',
            display: "none",
            placeContent: "center",
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0, 0, 0, 0.5)",
            textTransform: "uppercase",
            fontWeight: 700,
            fontSize: "18px",
            zIndex: 10,
          },
        }}
        colSpan={6}
      >
        <BetButtonsListForSixCol
          marketType={marketType}
          runner_name={market?.market_name}
          variant={variant}
          market={market}
        />
      </TableCell>
    </TableRow>
  );
};

export default FancyMarketTableRow;

const BetButtonsListForSixCol = ({
  market,
  variant,
  runner_name,
  marketType,
}: {
  market: any;
  variant: string;
  runner_name: string;
  marketType: string;
}) => {
  return (
    <Stack direction={"row"}>
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"back"}
      />
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"back"}
      />
      {/* {market['back'].reverse().map((back: BackType) => ( */}
      {/* // ))} */}
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        size={market.no_rate}
        isEventPage={true}
        type={"lay"}
        odds={+market.no_value}
        fancy_rate={+market.no_rate}
      />
      {/* {market['lay'].map((lay: BackType) => ( */}
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        size={market.yes_rate}
        isEventPage={true}
        type={"back"}
        odds={+market.yes_value}
        fancy_rate={+market.yes_rate}
      />
      {/* ))} */}
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"lay"}
      />
      <BetButton
        marketType={marketType}
        runner_name={runner_name}
        removeCell={variant === "small" ? true : false}
        emptyCellButton
        isEventPage={true}
        type={"lay"}
      />
    </Stack>
  );
};
