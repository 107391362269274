import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

interface PropsI {
  searchResult: any[];
  onClosePopUp: () => void;
}

const SearchBox: FC<PropsI> = ({ searchResult = [], onClosePopUp = () => {} }) => {
  return (
    <Box
      py={1}
      zIndex={1}
      position={"absolute"}
      top={"100%"}
      left={0}
      sx={{
        width: "100%",
        background: "#fff",
        borderRadius: 1,
        maxHeight: "480px",
        overflowX: "hidden",
      }}
    >
      {Array.isArray(searchResult) &&
        searchResult.length > 0 &&
        searchResult.map((result: any) => (
          <Link
            style={{ color: "#000", textDecoration: "none" }}
            to={`#`}
            key={result?.event_id}
            onClick={onClosePopUp}
          >
            <Box p={"3px 1em 3px 0.4em"}>
              <Typography
                fontSize="14px"
                sx={{
                  ":hover": {
                    bgcolor: "blue",
                    color: "#fff",
                  },
                }}
              >
                {result}
              </Typography>
            </Box>
          </Link>
        ))}
    </Box>
  );
};

export default SearchBox;
