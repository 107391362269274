import { useState } from "react";
import { useFormik } from "formik";

import { Box, Typography, FormControl, useMediaQuery } from "@mui/material";

import theme from "../../theme";
import endpoints from "../../api/endpoints";
import usePutApi from "../../hooks/use-put-api";
import ReportTitle from "../title/report-title";
import MyButton from "../../components/ui/myButton";
import { ITransactionPwdPayload } from "../../types";
import ShowTransactionPassword from "./show-transaction-password";
import CustomTextField from "../../components/ui/custom-text-field";
import { changePasswordSchema } from "../../utils/validation-schema";

const ChangePasswordForm = () => {
  const breakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const userData = JSON.parse(localStorage.getItem("user_data") as string);

  const [created, setCreated] = useState<string>("");

  const { error, putData } = usePutApi<any, ITransactionPwdPayload>(
    endpoints.ACCOUNT.get_transaction_pwd(userData?.user_id)
  );

  const { values, touched, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",

      transaction_password: "",
    },
    onSubmit: (formValues) => {
      handle.createTransactionPwd(formValues);
    },
    validationSchema: changePasswordSchema,
  });

  const handle = {
    createTransactionPwd: (formData: ITransactionPwdPayload) => {
      putData(formData)
        .then((res) => {
          const transaction_password = res?.data?.data?.transaction_password;

          if (transaction_password) {
            setCreated(transaction_password);
          }
        })
        .catch(() => {
          if (error) {
            // console.log("error", { error, res: error?.response });
          }
        });
    },
  };

  return (
    <Box m={0.5} my={1}>
      {!created ? (
        <>
          <ReportTitle title="Change Password" />
          <form onSubmit={handleSubmit}>
            <Box
              mb={1}
              sx={{
                width: breakpoint ? "33%" : "100%",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "0.5rem",
              }}
            >
              <FormControl>
                <Typography sx={{ mb: 0.5 }} variant="subtitle2">
                  New Password
                </Typography>
                <CustomTextField
                  size="small"
                  variant={breakpoint ? "outlined" : "standard"}
                  type="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                />
              </FormControl>
              <FormControl>
                <Typography sx={{ mb: 0.5 }} variant="subtitle2">
                  Confirm New Password
                </Typography>
                <CustomTextField
                  size="small"
                  variant={breakpoint ? "outlined" : "standard"}
                  type="password"
                  name="confirm_password"
                  value={values.confirm_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.confirm_password && Boolean(errors.confirm_password)}
                  helperText={touched.confirm_password && errors.confirm_password}
                />
              </FormControl>
              <FormControl>
                <Typography sx={{ mb: 0.5 }} variant="subtitle2">
                  Transaction Password
                </Typography>
                <CustomTextField
                  type="password"
                  size="small"
                  variant={breakpoint ? "outlined" : "standard"}
                  name="transaction_password"
                  value={values.transaction_password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.transaction_password && Boolean(errors.transaction_password)}
                  helperText={touched.transaction_password && errors.transaction_password}
                />
              </FormControl>
            </Box>
            <Box p="0.5rem">
              <MyButton variant="contained" sx={{ mb: 1 }} type="submit">
                Load
              </MyButton>
            </Box>
          </form>
        </>
      ) : (
        <ShowTransactionPassword password={created} />
      )}
    </Box>
  );
};

export default ChangePasswordForm;
