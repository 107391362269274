import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { ArrowDropDown } from "@mui/icons-material";
import { Box, Menu, Button, MenuItem } from "@mui/material";

import { userLogout } from "../../redux/reducers/user";
import { RootState, AppDispatch } from "../../redux/store";
import PROFILE_MENU_ITEMS from "../../config/profile-menu-items";

const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const username = useSelector((state: RootState) => state.user.userData?.user_name);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  function handleClose(menuTitle?: string): void {
    if (menuTitle === "Logout") {
      dispatch(userLogout());
    }

    setAnchorEl(null);
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableElevation
        disableFocusRipple
        disableRipple
        disableTouchRipple
        variant="text"
        sx={{ color: "#000", textTransform: "initial", "& .MuiButton-endIcon": { margin: 0 } }}
        endIcon={<ArrowDropDown />}
      >
        {username}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        sx={{
          width: "100%",
          "& .MuiList-root": { padding: 0, bgcolor: "primary.main" },
          "& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": { boxShadow: "0 0 0 0" },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {PROFILE_MENU_ITEMS.map((menu) => (
          <MenuItem
            key={menu.id}
            component={Link}
            to={menu.link}
            disableRipple
            disableGutters
            disableTouchRipple
            onClick={() => handleClose(menu.title)}
            sx={{
              fontSize: "14px",
              fontWeight: 700,
              p: "10px",
              ":hover": {
                textDecoration: "underline",
              },
            }}
          >
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
