import "@fontsource/roboto-condensed";

import { createTheme, PaletteOptions } from "@mui/material/styles";

// Define a custom palette type
interface CustomPalette {
  accent: {
    main: string;
  };
  grey: {
    "200": string;
    "300": string;
    "400": string;
  };
  common: {
    black: string;
    white: string;
  };
}

// Define a custom theme type that includes both PaletteOptions and CustomPalette
type CustomTheme = PaletteOptions & CustomPalette;

const theme = createTheme({
  palette: {
    primary: {
      main: "#04A1A6",
    },
    secondary: {
      main: "#0F696E",
    },
    accent: {
      main: "#579699",
    },
    grey: {
      "100": "#f5f5f5",
      "200": "#9e9e9e",
      "300": "#CCCCCC",
      "400": "#444444",
    },
    common: {
      black: "#000",
      white: "#fff",
    },
  } as CustomTheme,
  typography: {
    fontFamily: "Roboto Condensed",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
