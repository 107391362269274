const PROFILE_MENU_ITEMS = [
  {
    id: 1,
    title: "Secure Auth Verification",
    link: "/security-auth",
  },
  {
    id: 2,
    title: "Change Password",
    link: "/change-password",
  },
  {
    id: 3,
    title: "Logout",
    link: "",
  },
];

export default PROFILE_MENU_ITEMS
