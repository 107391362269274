import * as Yup from "yup";

export const addAccountSchema = Yup.object().shape({
  user_name: Yup.string().required("Username is required"),
  display_name: Yup.string().required("Display name is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),
  role_id: Yup.string().required("Role ID is required"),
  role_name: Yup.string().required("Role name is required"),

  credit_reference: Yup.string()
    .matches(/^\d+$/, "Must be a valid number")
    .required("Credit Reference is required"),
  city: Yup.string().optional(),
  phone: Yup.string().optional(),

  member_max_exposure_amt: Yup.string().when("role_name", ([role_name], schema) => {
    if (role_name && role_name.toLowerCase() === "user") {
      return schema.required("Member max exposure amount is required").min(0, "Must be a positive number");
    }

    return schema.optional();
  }),

  commission_settings: Yup.object().shape({
    up_line: Yup.string()
      .matches(/^\d+$/, "Must be a valid number")
      .required("UpLine commission is required"),
    down_line: Yup.string()
      .matches(/^\d+$/, "Must be a valid number")
      .required("DownLine commission is required"),
    our: Yup.string().matches(/^\d+$/, "Must be a valid number").required("Our commission is required"),
  }),

  partnership: Yup.object().shape({
    up_line: Yup.string()
      .matches(/^\d+$/, "Must be a valid number")
      .required("UpLine partnership is required"),
    down_line: Yup.string()
      .matches(/^\d+$/, "Must be a valid number")
      .required("DownLine partnership is required"),
    our: Yup.string().matches(/^\d+$/, "Must be a valid number").required("Our partnership is required"),
  }),
  transaction_password: Yup.string().required("Transaction password is required"),

  member_min_bet: Yup.string().when("role_name", ([role_name], schema) => {
    if (role_name && role_name.toLowerCase() === "user") {
      return schema.matches(/^\d+$/, "Must be a valid number").required("Member minimum bet is required");
    }

    return schema.optional();
  }),
  member_max_bet_amt: Yup.string().when("role_name", ([role_name], schema) => {
    if (role_name && role_name.toLowerCase() === "user") {
      return schema.matches(/^\d+$/, "Must be a valid number").required("Member max bet amount is required");
    }

    return schema.optional();
  }),
  bet_delay: Yup.string().when("role_name", ([role_name], schema) => {
    if (role_name && role_name.toLowerCase() === "user") {
      return schema.matches(/^\d+$/, "Must be a valid number").required("Bet delay is required");
    }

    return schema.optional();
  }),
});

export const changePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),

  transaction_password: Yup.string().required("Transaction password is required"),
});
