const endpoints = {
  PROFITLOSSREPORTOFADMIN: `profit-loss-report/admin`,
  BETLIST: `bet/bet-list`,
  ACCOUNT: {
    ADD: `member/add-account`,
    get_transaction_pwd: (user_id: string) => `member/set-transaction-password/${user_id}`,
  },
  game: {
    root: `api/game`,
  },
  user: {},
  member: {
    getMember: (user_id: string) => `member/get-member/${user_id}`,
    getDownLineHandlerById: (user_id: string) => `member/getDownLineHandler/${user_id}`,
    operation: (user_id: string) => `member/operation/${user_id}`,
    getAccountList: (user_id: string, limit: string | number = 5, is_active: boolean = true) =>
      `member/getDownLineList/${user_id}?limit=${limit}&is_active=${is_active}`,
    marketBetList: `/admin/marketBetList`,
  },
  admin: {
    accountStatement: `admin/account-statement`,
  },
  GAME: {
    list: `game`,
  },
};

export default endpoints;

// Types
export interface IMember {
  member_code: string;
  user_name: string;
  role_id: string;
  status: string;
  member_min_bet: number;
  member_max_bet_amt: number;
  member_max_exposure_amt: number;
  created_by: string;
  is_bet_lock: boolean;
  created_at: string;
  commission_settings: CommissionSettings;
  partnership: Partnership;
  credit_reference: number;
  user_id: string;
  balance: number;
  role_name: string;
  admin_id: string;
  admin_user_name: string;
  admin_balance: number;
}

export interface CommissionSettings {
  up_line: number;
  down_line: number;
  our: number;
}

export interface Partnership {
  up_line: number;
  down_line: number;
  our: number;
}
