const parseNumber = ({ type, value }: { type: "float" | "int"; value: any }) => {
  try {
    if (type === "int") {
      return parseInt(value, 10) || 0;
    }
    if (type === "float") {
      return parseFloat(value) || 0;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

export default parseNumber;
