import React from "react";

import { Stack, SxProps, TableCell, TableRow } from "@mui/material";

import BetButton from "../../../components/bet-button/bet-button";
import theme from "../../../theme";

// ---------------------------------------------------------------
interface Props {
  fancy: any;
  sxStyle: SxProps;
  isLink?: boolean;
}
// ---------------------------------------------------------------

const EventsFancyTableRow: React.FC<Props> = ({ fancy, sxStyle, isLink = false }) => {
  return (
    <TableRow>
      <TableCell>
        <Stack direction="column">
          <span>{fancy.market_name}</span> <span>0</span>
        </Stack>
      </TableCell>

      <TableCell sx={{ ...sxStyle, p: 0 }}>
        <BetButton size={fancy.no_rate} isEventPage={true} type={"lay"} odds={fancy.no_value} />
      </TableCell>

      <TableCell sx={{ ...sxStyle, p: 0 }}>
        <BetButton size={fancy.yes_rate} isEventPage={true} type={"back"} odds={fancy.yes_value} />
      </TableCell>

      <TableCell
        sx={{
          ...sxStyle,
          width: "110px",
          padding: 0,
          textAlign: "right",
          color: theme.palette.secondary.light,
        }}
      >
        <Stack direction="column" sx={{ padding: "4px" }}>
          <span>Min:100</span> <span>Max:10000</span>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default EventsFancyTableRow;
