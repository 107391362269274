import { useState, useEffect, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import { GridColDef } from "@mui/x-data-grid";
import { Box, Tab, Tabs, Checkbox, Typography } from "@mui/material";

import ReportLayout from "../../../report-layout";
import endpoints from "../../../../api/endpoints";
import useGetApi from "../../../../hooks/use-get-api";
import AccountStatusHeader from "../acount-status-header";
import MuiDataGrid from "../../../../components/MuiDataGrid";
import UserRowActionButtons from "../user-row-action-buttons";
import PreUserTable from "../pre-user-table";
import ReportTitle from "../../../title/report-title";
import MyButton from "../../../../components/ui/myButton";

// interface IUser {
//   _id: string;
//   admin_id: string;
//   user_name: string;
//   role_id: string;
//   balance: number;
//   is_bet_lock: boolean;
//   is_active: boolean;
//   credit_reference: number;
//   downTotalBalance: number;
//   account_type: string;
// }

enum UsersTab {
  ACTIVE_USERS = "Active",
  DEACTIVE_USERS = "Inactive",
}

const columns: GridColDef[] = [
  {
    field: "username",
    headerName: "User Name",
    width: 270,
    renderCell: (params) => {
      return <UserNameCol rowData={params.row} />;
    },
  },
  {
    field: "credit_reference",
    headerName: "Credit Reference",
    align: "right",
    minWidth: 120,
  },
  {
    field: "balance",
    headerName: "Balance",
    align: "right",
    width: 150,
  },
  {
    field: "client_pnl",
    headerName: "Client (P/L)",
    align: "right",
    width: 190,
  },
  {
    field: "user_exposure",
    headerName: "Exposure",
    align: "right",
    minWidth: 120,
  },
  {
    field: "total_balance",
    headerName: "Available Balance",
    align: "right",
    width: 150,
    valueGetter({ row, value }) {
      if (row.account_type !== "user") {
        return row.balance;
      }
      return value;
    },
  },
  {
    field: "is_active",
    headerName: "U St",
    renderCell: ({ row, value }) => {
      if (row?.account_type) {
        return <Checkbox disableFocusRipple disableRipple disableTouchRipple checked={value} />;
      }
      return null;
    },
    width: 50,
  },
  {
    field: "is_bet_lock",
    headerName: "B St",
    renderCell: ({ row, value }) => {
      if (row?.account_type) {
        return <Checkbox disableFocusRipple disableRipple disableTouchRipple checked={value} />;
      }
      return null;
    },
    width: 50,
  },
  {
    field: "member_max_exposure_amt",
    headerName: "Exposure Limit",
    align: "right",
    width: 110,
  },
  {
    field: "default_percentage",
    headerName: "Defualt %",
    align: "right",
    minWidth: 100,
  },
  {
    field: "account_type",
    headerName: "Account Type",
    width: 120,
  },
  {
    field: "total_casino",
    headerName: "Casino Total",
    align: "right",
    width: 100,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 300,
    renderCell: ({ row }) => {
      if (row?.account_type) {
        return <UserRowActionButtons rowData={row} />;
      }
    },
  },
];

const ActiveDeActiveUsers = () => {
  const { userId } = useParams();
  const [selectedTab, setSelectedTab] = useState<string>(UsersTab.ACTIVE_USERS);

  const [rowData, setRowData] = useState<any>({});

  const { data } = useGetApi<any[]>(
    endpoints.member.getAccountList(userId as string, 5, selectedTab === UsersTab.ACTIVE_USERS)
  );

  useEffect(() => {
    setRowData(data ?? {});
  }, [data]);

  const handle = {
    changeTab: (_: React.SyntheticEvent<Element, Event>, value: any): void => {
      setSelectedTab(value);
    },
  };

  const downLineList = useMemo(() => {
    if (Array.isArray(rowData.downLine)) {
      let list = rowData.downLine.map((i: any) => {
        return {
          ...i,

          id: i.user_id || i.admin_id || "",
          user_status: i?.is_active ?? false,
          bet_status: !i?.is_bet_lock,
          username: i.user_name,
        };
      });

      list = [
        {
          _id: "first_data",
          credit_reference: rowData?.calculation?.total_credit_reference ?? 0,
          balance: rowData?.calculation?.total_balance ?? 0,
          total_balance: rowData?.calculation?.total_available_balance ?? 0,
          user_exposure: rowData?.calculation?.total_exposure ?? 0,
          client_pnl: rowData?.calculation?.total_client_pnl ?? 0,
        },

        ...list,
      ];

      return list;
    }
    return [
      {
        _id: "first_data",
        credit_reference: rowData?.calculation?.total_credit_reference ?? 0,
        balance: rowData?.calculation?.total_balance ?? 0,
        total_balance: rowData?.calculation?.total_available_balance ?? 0,
        user_exposure: rowData?.calculation?.total_exposure ?? 0,
        client_pnl: rowData?.calculation?.total_client_pnl ?? 0,
      },
    ];
  }, [rowData]);

  return (
    <>
      <AccountStatusHeader accountData={rowData?.login_user_data ?? {}} />
      <ReportLayout>
        <ReportTitle
          action={
            <Link to={`/add-account`}>
              <MyButton variant="contained">Add Account</MyButton>
            </Link>
          }
          title="Account List"
        />
        <Tabs
          onChange={handle.changeTab}
          sx={{
            mt: "0.875rem",
            "& .Mui-selected": { bgcolor: "#fff", color: "#000 !important", border: "1px solid #00000025" },
            "& span.MuiTabs-indicator.css-q0q92n-MuiTabs-indicator": { bgcolor: "white" },
            "& .MuiTabs-flexContainer": { borderBottom: "1px solid #00000025 !important" },
          }}
          value={selectedTab}
        >
          <Tab
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{
              textTransform: "capitalize",
              color: "#007bff",
              fontSize: "16px",
            }}
            label="Active Users"
            value={UsersTab.ACTIVE_USERS}
          />
          <Tab
            disableFocusRipple
            disableRipple
            disableTouchRipple
            sx={{ textTransform: "capitalize", color: "#007bff", fontSize: "16px" }}
            label="Deactive Users"
            value={UsersTab.DEACTIVE_USERS}
          />
        </Tabs>
        <Box mb={1} mt={2}>
          <PreUserTable />
        </Box>
        <Box
          sx={{
            "& .MuiDataGrid-row:nth-child(1)": {
              fontWeight: "700",
            },
          }}
        >
          <MuiDataGrid rows={downLineList} columns={columns} getRowId={(row) => row?._id} />
        </Box>
      </ReportLayout>
    </>
  );
};

const UserNameCol: React.FC<{ rowData: any }> = ({ rowData }) => {
  // const { pathname } = useLocation();
  // const isListClients = pathname.split("/")[1] === "list-clients";

  if (!rowData?.account_type) return null;

  let toLink = "javascript:void(0)";

  if (rowData?.account_type?.toLowerCase() !== "user" && rowData.user_id) {
    toLink = `/list-clients/${rowData.user_id}`;
  }

  return (
    <Link
      target={rowData.account_type.toLowerCase() === "user" ? "_self" : "_blank"}
      //
      to={toLink}
    >
      <Typography
        sx={{
          cursor: "pointer",
          background: "#444",
          padding: "5px 10px",
          textDecoration: "none",
          color: "#fff",
          borderRadius: "3px",
          marginRight: "3px",
          textTransform: "uppercase",
          display: "inline-block",
          fontSize: "14px",
        }}
      >
        {rowData.user_name}
      </Typography>
    </Link>
  );
};

export default ActiveDeActiveUsers;
