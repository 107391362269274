import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

const ReportLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box sx={{ backgroundColor: "grey.100", p: "10px", m: "10px 15px 0 15px" }}>
      {children}
    </Box>
  );
};

export default ReportLayout;
