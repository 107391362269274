import React from "react";

import {
  Box,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  useMediaQuery,
  TableContainer,
} from "@mui/material";

import { Runner } from "./types";
import theme from "../../../theme";
import SixColMarketTableRow from "./six-col-market-table-row";

// ------------------------------------------------------------
type Props = {
  data: any;
};
// ------------------------------------------------------------

const SixColMarketTable: React.FC<Props> = ({ data }) => {
  const breakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box p={0.5}>
      {/* {data && data.map((market: Market) => ( */}
      <TableContainer key={data?.market_id} sx={{ mt: 1 }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: `${theme.palette.secondary.light}` }}>
            <TableRow>
              <TableCell
                width={"50%"}
                sx={{ fontSize: "14px", fontWeight: "700", color: "#fff", paddingLeft: 0.75 }}
              >
                {data?.market_name}
              </TableCell>
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              {breakpoint && (
                <>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                </>
              )}
              <TableCell
                colSpan={!breakpoint ? 3 : undefined}
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#72BBEF",
                  height: "30px",
                }}
              >
                Back
              </TableCell>
              <TableCell
                colSpan={!breakpoint ? 3 : undefined}
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#FAA9BA",
                  height: "30px",
                }}
              >
                Lay
              </TableCell>
              {breakpoint && (
                <>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                </>
              )}
            </TableRow>
          </TableBody>
          <TableBody>
            {data?.runners &&
              data?.runners.map((runner: Runner, i: number) => (
                <SixColMarketTableRow
                  key={i}
                  runner={runner}
                  marketStatus={data.provider_status}
                  marketType={data.market_type}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* ))} */}
    </Box>
  );
};

export default SixColMarketTable;
