import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { ArrowDropDown } from "@mui/icons-material";

import { Link } from "react-router-dom";

// ----------------------------------------------------
type Props = {
  data: any;
};
// ----------------------------------------------------

const TypographyLink = ({ title, to = "" }: { title: string; to: string }) => {
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Typography
        p={"10px"}
        sx={{
          color: "#000",
          fontSize: "14px",
          fontWeight: 700,

          "&:hover": {
            color: "white",
            backgroundColor: "#0F696E",
          },
        }}
      >
        {title}
      </Typography>
    </Link>
  );
};

const MenuItems: React.FC<Props> = ({ data }) => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  return (
    <Stack
      direction={"row"}
      columnGap={3}
      sx={{ display: { xs: "none", sm: "none", md: "none", lg: "flex" } }}
    >
      {data.map((d: any) => (
        <Box key={d.id} sx={{ position: "relative", "&:hover > .dropdown-el": { display: "block" } }}>
          <Link style={{ textDecoration: "none" }} to={typeof d.link === "string" ? d.link : d.link(userId)}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 700,
                  "&:hover": {
                    color: "white",
                    textDecoration: "underline",
                  },
                }}
              >
                {d.title}
              </Typography>
              {d.children && <ArrowDropDown sx={{ color: "#000" }} />}
            </Stack>
          </Link>
          {d.children && (
            <Stack
              className="dropdown-el"
              sx={{
                position: "absolute",
                top: "100%",
                left: "0",
                minWidth: "200px",
                display: "none",
                bgcolor: "primary.main",
                zIndex: 1,
              }}
            >
              {d.children.map((child: any) => {
                return <TypographyLink to={child.link} key={child.id} title={child.title} />;
              })}
            </Stack>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default MenuItems;
