import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { List, Collapse, ListItem, ListItemText } from "@mui/material";

const hasChildren = (item: any) => {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
};

const SingleLevel = ({ item }: any) => {
  return (
    <ListItem button sx={{ paddingLeft: "56px", paddingTop: 0, paddingBottom: 0 }}>
      <ListItemText
        className="mtree mtree-open"
        sx={{ marginTop: 0, marginBottom: 0 }}
        primary={item.title}
      />
    </ListItem>
  );
};

const MultiLevel = ({ item }: any) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        sx={{ paddingLeft: item?.paddingLeft || 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <ListItemText
          sx={{ marginTop: 0, marginBottom: 0 }}
          className="mtree mtree-open"
          primary={item.title}
        />
        {open ? <RemoveIcon /> : <AddIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child: any, key: any) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const MenuItem = ({ item }: any) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};
