import { mutate } from "swr";
import { useState } from "react";

import instance from "../api/instance";

interface UsePostApiState<T> {
  data: T | null;
  loading: boolean;
  error: any;
}

export default function usePostApi<T, P>(endpoint: string, mutateEndpoints?: Array<string>) {
  const [state, setState] = useState<UsePostApiState<T>>({
    data: null,
    loading: false,
    error: null,
  });

  const postData = (payload: P) =>
    new Promise<T>((resolve, reject) => {
      setState({ ...state, loading: true });

      instance
        .post<T>(endpoint, payload)
        .then((response) => {
          const resData = {
            data: response.data,
            loading: false,
            error: null,
          };
          setState(resData);
          mutateEndpoints?.forEach((endpointToMutate) => mutate(endpointToMutate));
          resolve(response.data);
        })
        .catch((error) => {
          const errRes = {
            data: null,
            loading: false,
            error: error?.response?.data ?? {},
          };
          setState(errRes);
          reject(error?.response?.data ?? {});
        })
        .finally(() => {
          setState({
            ...state,
            loading: false,
          });
        });
    });

  return { ...state, postData };
}
