import { useEffect } from "react";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  Stack,
  FormLabel,
  GridProps,
  Typography,
  FormControl,
  FormControlProps,
} from "@mui/material";

import endpoints from "../../api/endpoints";
import useGetApi from "../../hooks/use-get-api";
import usePostApi2 from "../../hooks/use-post-api";
import parseNumber from "../../utils/parse-number";
import MyButton from "../../components/ui/myButton";
import MuiSelect from "../../components/MuiSelect/MuiSelect";
import { getUserRoles } from "../../redux/reducers/appSlice";
import { addAccountSchema } from "../../utils/validation-schema";
import CustomTextField from "../../components/ui/custom-text-field";
import { IAddAccountPayload, IAddAccountResponse } from "../../types";

const CommonFormControl = ({ fullWidth = true, sx = {}, ...rest }: FormControlProps) => {
  return <FormControl fullWidth={fullWidth} sx={{ mb: "1rem", ...sx }} {...rest} />;
};

const CommonGridItem = ({ lg = 6, md = 6, sm = 12, xs = 12, ...rest }: GridProps) => {
  return <Grid item lg={lg} md={md} sm={sm} xs={xs} {...rest} />;
};
export default function AddAccount() {
  const dispatch = useDispatch<any>();
  const roles = useSelector<any>(({ app }) => app.roles);
  const navigate = useNavigate();
  const { postData } = usePostApi2<IAddAccountResponse, IAddAccountPayload>(endpoints.ACCOUNT.ADD);

  const userData = JSON.parse(localStorage.getItem("user_data") as string);

  const { data: memberData } = useGetApi<any>(endpoints.member.getMember(userData.user_id));

  const { values, touched, errors, setFieldValue, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      user_name: "",
      display_name: "",
      password: "",
      confirm_password: "",
      city: "",
      phone: "",
      credit_reference: 0,
      member_max_exposure_amt: 0,
      role_id: "",
      role_name: "",
      commission_settings: {
        up_line: 0,
        down_line: 0,
        our: 0,
      },
      partnership: {
        up_line: 0,
        down_line: 0,
        our: 0,
      },
      transaction_password: "",
      member_min_bet: 0,
      member_max_bet_amt: 0,
      bet_delay: 0,
    },
    onSubmit: (formValues) => {
      handle.addAccount(formValues);
    },
    validationSchema: addAccountSchema,
  });

  useEffect(() => {
    dispatch(getUserRoles());
  }, [dispatch]);

  const handle = {
    addAccount: (formData: IAddAccountPayload) => {
      const newPayload: IAddAccountPayload = {
        user_name: formData.user_name,
        display_name: formData.display_name,
        password: formData.password,
        confirm_password: formData.confirm_password,
        role_id: formData.role_id,
        role_name: formData.role_name,
        credit_reference: parseNumber({ type: "float", value: formData.credit_reference }),
        commission_settings: {
          up_line: parseNumber({ type: "float", value: formData.commission_settings.up_line }),
          down_line: parseNumber({ type: "float", value: formData.commission_settings.down_line }),
          our: parseNumber({ type: "float", value: formData.commission_settings.our }),
        },
        partnership: {
          up_line: parseNumber({ type: "float", value: formData.partnership.up_line }),
          down_line: parseNumber({ type: "float", value: formData.partnership.down_line }),
          our: parseNumber({ type: "float", value: formData.partnership.our }),
        },
        transaction_password: formData.transaction_password,
      };

      if (process.env.NODE_ENV === "development") {
        newPayload.base_url = "https://betmaverick.in";
      }

      if (formData?.role_name && formData?.role_name?.toLowerCase() === "user") {
        newPayload.member_min_bet = parseNumber({ type: "float", value: formData.member_min_bet });
        newPayload.member_max_bet_amt = parseNumber({ type: "float", value: formData.member_max_bet_amt });

        newPayload.bet_delay = formData.bet_delay ?? 0;
        newPayload.member_max_exposure_amt = parseNumber({
          type: "float",
          value: formData.member_max_exposure_amt,
        });
      }

      postData(newPayload)
        .then(() => {
          navigate(`/active-users/${userData.user_id}`);
        })
        .catch((error) => {
          enqueueSnackbar(error.status_message ?? "Something Went Wrong", { variant: "error" });
        });
    },
  };

  const getUserOptions = () => {
    try {
      if (Array.isArray(roles)) {
        const nRoles: any[] = roles
          .filter((rI) => {
            if (userData?.role_name) {
              if (userData.role_name.toLowerCase() === "super-admin") {
                return ["admin", "super-master", "agent", "user"].includes(rI.name?.toLowerCase()?.trim());
                //
              } else if (userData?.role_name.toLowerCase() === "admin") {
                return ["super-master", "agent", "user"].includes(rI.name?.toLowerCase()?.trim());
                //
              } else if (userData?.role_name.toLowerCase() === "super-master") {
                return ["agent", "user"].includes(rI.name?.toLowerCase()?.trim());
                //
              } else if (userData?.role_name.toLowerCase() === "agent") {
                return ["user"].includes(rI.name?.toLowerCase()?.trim());
                //
              }
              return false;
            }
          })
          .map((mrI) => ({ ...mrI, value: mrI.role_id, label: mrI.name }));

        nRoles.unshift({ label: "Select Account Type", value: "" });

        return nRoles;
      }

      return [];
    } catch (error) {
      return [];
    }
  };

  const isUser = values.role_name && values.role_name?.toLowerCase() === "user";

  return (
    <Box
      sx={{
        pt: "10px",
        px: "15px",
        "& .add_account": {
          backgroundColor: "#f9f9f9",
          padding: "15px",
          margin: "0",
          borderRadius: "4px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
        },
        "& .add_account h4": {
          fontWeight: "700",
          color: (theme) => theme.palette.common.white,
          backgroundColor: (theme) => theme.palette.secondary.main,
          paddingTop: "5px",
          paddingBottom: "5px",
          fontSize: "18px",
          paddingLeft: "15px",
          mb: "1.5rem",
        },
        "& .label": {
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "15px",
          mb: "0.5rem",
          color: "#1E1E1E",
        },
        "& .td_th": {
          fontSize: "14px",
          color: "#1e1e1e;",
          lineHeight: "15px",
        },
      }}
    >
      <form onSubmit={handleSubmit}>
        <Box className="add_account">
          <Typography
            sx={{
              fontSize: "2rem",
              mb: "20px",
              lineHeight: "1.2",
              fontWeight: "500",
            }}
          >
            Add Account
          </Typography>

          <Grid container columnSpacing={"30px"}>
            <CommonGridItem>
              <Typography component="h4">Personal Detail</Typography>

              <Grid container columnSpacing={"30px"}>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">Client Name:</FormLabel>
                    <CustomTextField
                      name="user_name"
                      value={values.user_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.user_name && Boolean(errors.user_name)}
                      helperText={touched.user_name && errors.user_name}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">User Password:</FormLabel>
                    <CustomTextField
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">Retype Password:</FormLabel>
                    <CustomTextField
                      type="password"
                      name="confirm_password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.confirm_password && Boolean(errors.confirm_password)}
                      helperText={touched.confirm_password && errors.confirm_password}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">Full Name:</FormLabel>
                    <CustomTextField
                      name="display_name"
                      value={values.display_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.display_name && Boolean(errors.display_name)}
                      helperText={touched.display_name && errors.display_name}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">City:</FormLabel>
                    <CustomTextField
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">Phone:</FormLabel>
                    <CustomTextField
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.phone && Boolean(errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  </CommonFormControl>
                </CommonGridItem>
              </Grid>
            </CommonGridItem>
            <CommonGridItem>
              <Typography component="h4">Account Detail</Typography>
              <Grid container columnSpacing={"30px"}>
                <CommonGridItem>
                  <MuiSelect
                    label="Account Type:"
                    options={getUserOptions()}
                    name="role_id"
                    value={values.role_id}
                    onChange={(evt) => {
                      handleChange(evt);
                      const options = getUserOptions();
                      const findRole = options.find((o) => o.role_id === evt.target.value);

                      setFieldValue("role_name", findRole?.name ?? "");

                      setFieldValue("member_max_exposure_amt", "");
                    }}
                    onBlur={handleBlur}
                    error={touched.role_id && Boolean(errors.role_id)}
                    helperText={touched.role_id ? errors.role_id : ""}
                    controlSx={{
                      mb: "1rem",
                      "& .MuiFormLabel-root": {
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        mb: "0.5rem",
                        color: "#1E1E1E",
                      },
                    }}
                  />
                </CommonGridItem>
                <CommonGridItem>
                  <CommonFormControl>
                    <FormLabel className="label">Credit Reference:</FormLabel>
                    <CustomTextField
                      name="credit_reference"
                      value={values.credit_reference}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.credit_reference && Boolean(errors.credit_reference)}
                      helperText={touched.credit_reference && errors.credit_reference}
                    />
                  </CommonFormControl>
                </CommonGridItem>
                {isUser && (
                  <CommonGridItem>
                    <CommonFormControl>
                      <FormLabel className="label">Exposer Limit:</FormLabel>
                      <CustomTextField
                        name="member_max_exposure_amt"
                        value={values.member_max_exposure_amt}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.member_max_exposure_amt && Boolean(errors.member_max_exposure_amt)}
                        helperText={touched.member_max_exposure_amt && errors.member_max_exposure_amt}
                      />
                    </CommonFormControl>
                  </CommonGridItem>
                )}
              </Grid>
            </CommonGridItem>
            <Grid item sm={12}>
              <Typography component="h4">Commission Settings</Typography>
              <div className="add-account">
                <table className="table table-striped table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <Typography className="td_th">Upline</Typography>
                      </td>
                      <td>
                        <Typography className="td_th">
                          {memberData?.commission_settings?.up_line ?? "0"}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className="td_th">Downline</Typography>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="comm"
                          // id="" placeholder="0" maxlength="4" class=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className="td_th">Our</Typography>
                      </td>
                      <td>
                        <Typography className="td_th">
                          {memberData?.commission_settings?.our ?? "0"}
                        </Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
            <Grid item sm={12}>
              <Typography component="h4">Partnership</Typography>
              <div className="add-account">
                <table className="table table-striped table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <Typography className="td_th">Upline</Typography>
                      </td>
                      <td>
                        <Typography className="td_th">{memberData?.partnership?.up_line ?? "0"}</Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className="td_th">Downline</Typography>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="comm"
                          // id="" placeholder="0" maxlength="4" class=""
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Typography className="td_th">Our</Typography>
                      </td>
                      <td>
                        <Typography className="td_th">{memberData?.partnership?.our ?? "0"}</Typography>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
            {isUser && (
              <Grid item sm={12}>
                <Typography component="h4">Min Max Bet</Typography>
                <div className="add-account">
                  <table className="table table-striped table-bordered">
                    <tbody>
                      <tr>
                        <td rowSpan={2}>
                          <Typography className="td_th">Min Bet</Typography>
                        </td>
                        <td>
                          <Typography className="td_th">100</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="text" placeholder="100" value="100" />
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <Typography className="td_th">Max Bet</Typography>
                        </td>
                        <td>
                          <Typography className="td_th">5000000</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="text" placeholder="5000000" value="5000000" />
                        </td>
                      </tr>
                      <tr>
                        <td rowSpan={2}>
                          <Typography className="td_th">Delay</Typography>
                        </td>
                        <td>
                          <Typography className="td_th">5.00</Typography>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <input type="text" placeholder="5.00" value="5.00" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            )}
            <Grid item sm={12}>
              <Stack direction="row" sx={{ justifyContent: "flex-end", mt: "1.5rem" }}>
                <Grid item sm={3}>
                  <CommonFormControl>
                    <FormLabel className="label">Transaction Password:</FormLabel>
                    <CustomTextField
                      type="password"
                      name="transaction_password"
                      value={values.transaction_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.transaction_password && Boolean(errors.transaction_password)}
                      helperText={touched.transaction_password && errors.transaction_password}
                    />
                  </CommonFormControl>
                </Grid>
              </Stack>
            </Grid>
            <Grid item sm={12}>
              <Stack direction="row" sx={{ justifyContent: "flex-end" }}>
                <MyButton type="submit" className="btn" variant="contained" sx={{ color: "white" }}>
                  Create Account
                </MyButton>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}
