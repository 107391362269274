import { Link } from "react-router-dom";
import { useBoolean } from "usehooks-ts";
import React, { useState, useEffect, ChangeEvent } from "react";

import { Box, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";

import MenuItems from "./menu-items";
import ProfileMenu from "./profile-menu";
import Sidebar from "../sidebar/Sidebar";
import { IUser } from "../../types/user";
import enpoints from "../../api/endpoints";
import SearchBox from "./search/search-box";
import useGetApi from "../../hooks/use-get-api";
import CustomTextField from "../ui/custom-text-field";
import HEADER_MENU_ITEMS from "../../config/header-menu-items";

const Header: React.FC = () => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  const showSearch = useBoolean();

  const [searchText, setSearchText] = useState<string>("");

  const [searchResult] = useState<any[]>(["demomik1", "demomik2"]);

  const { data: adminBalanceData } = useGetApi<IUser>(enpoints.member.getMember(userId));

  useEffect(() => {
    if (adminBalanceData) {
      localStorage.setItem("user_data", JSON.stringify(adminBalanceData));
    }
  }, [adminBalanceData]);

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      showSearch.setTrue();
    } else {
      showSearch.setFalse();
    }
  }, [searchText, showSearch]);

  const onClosePopUp = () => {
    setSearchText("");
    // setSearchResult([]);
    showSearch.setFalse();
  };

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const loggedInUserId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  return (
    <Stack
      bgcolor="primary.main"
      direction="row"
      justifyContent="space-between"
      py={1}
      pr="15px"
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        {/* Logo */}
        <Link to={`/list-clients/${loggedInUserId}`}>
          <img src="/logo512.png" alt="Silver Exchange Logo" width="104px" style={{ marginRight: "16px" }} />
        </Link>

        {/* SIDEBAR */}
        <Sidebar />

        <MenuItems data={HEADER_MENU_ITEMS} />
      </Stack>

      <Stack direction="row" alignItems="center" columnGap={2}>
        <ProfileMenu />
        <Box position="relative" display="flex" alignItems="flex-start">
          <CustomTextField
            placeholder="All Clients"
            InputProps={{ endAdornment: <Search fontSize="small" /> }}
            onBlur={onClosePopUp}
            value={searchText}
            onChange={onChangeText}
          />
          {showSearch.value && searchResult && searchResult.length > 0 && (
            <SearchBox searchResult={searchResult} onClosePopUp={onClosePopUp} />
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Header;
