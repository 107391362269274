import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "../../../components/ui/custom-text-field";
import MyButton from "../../../components/ui/myButton";

const TelegramAuth: React.FC = () => {
  return (
    <Box my={1.5}>
      <Typography fontSize={"14px"} mb={1.5} align="center">
        Please select below option to enable secure auth verification
      </Typography>
      <Box display="flex">
        <CustomTextField sx={{ mr: 1.5 }} placeholder="Enter Transaction Password" />
        <MyButton variant="contained" color="primary" fullWidth size="small">
          Get Connection Id
        </MyButton>
      </Box>
    </Box>
  );
};

export default TelegramAuth;
