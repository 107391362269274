import { IProfitAndLossReport } from "../types";

const profitAndLossReport_mock: IProfitAndLossReport = {
  bets: [
    {
      _id: "2024-02-22",
      profitAndLoss: 0,
      markets: [
        {
          provider_market_id: "1.225128440",
          market_name: "Match Odds",
          event_name: "Quetta Gladiators v Islamabad United",
          game_name: "cricket",
          event_time: "1708610400",
          settled_at: "1708596938",
          comission: 0,
          provider_game_id: "4",
          profitAndLoss: 0,
        },
      ],
    },
    {
      _id: "2024-02-05",
      profitAndLoss: 14.7,
      markets: [
        {
          provider_market_id: "1.224261350",
          market_name: "Match Odds",
          event_name: "Sri Lanka v Afghanistan",
          game_name: "cricket",
          event_time: "1707107400",
          settled_at: "1707127142",
          comission: -0.3,
          provider_game_id: "4",
          profitAndLoss: 14.7,
        },
      ],
    },
    {
      _id: "2024-02-02",
      profitAndLoss: -254.46,
      markets: [
        {
          provider_market_id: "1380123",
          market_name: "12th Over Runs Only SCO U19",
          event_name: "Namibia U19 v Scotland U19",
          game_name: "cricket",
          event_time: "1706774400",
          settled_at: "1706862485",
          comission: 0,
          provider_game_id: "4",
          profitAndLoss: 127.5,
        },
        {
          provider_market_id: "1380110",
          market_name: "3rd WKT Pship Boundaries SCO U19",
          event_name: "Namibia U19 v Scotland U19",
          game_name: "cricket",
          event_time: "1706774400",
          settled_at: "1706862480",
          comission: 0,
          provider_game_id: "4",
          profitAndLoss: 150,
        },
        {
          provider_market_id: "1380165",
          market_name: "Owen Gould Runs",
          event_name: "Namibia U19 v Scotland U19",
          game_name: "cricket",
          event_time: "1706774400",
          settled_at: "1706862462",
          comission: 0,
          provider_game_id: "4",
          profitAndLoss: -15,
        },
        {
          provider_market_id: "1.224158388",
          market_name: "Match Odds",
          event_name: "Namibia U19 v Scotland U19",
          game_name: "cricket",
          event_time: "1706774400",
          settled_at: "1706862444",
          comission: 0,
          provider_game_id: "4",
          profitAndLoss: -564,
        },
        {
          provider_market_id: "1.224145516",
          market_name: "Match Odds",
          event_name: "South Africa U19 v Zimbabwe U19",
          game_name: "cricket",
          event_time: "1706688000",
          settled_at: "1706862411",
          comission: -0.96,
          provider_game_id: "4",
          profitAndLoss: 47.04,
        },
        {
          provider_market_id: "1380019",
          market_name: "Macdonald Runs",
          event_name: "Western Australia v New South Wales Blues",
          game_name: "cricket",
          event_time: "1706756700",
          settled_at: "1706862301",
          comission: 0,
          profitAndLoss: 0,
        },
        {
          provider_market_id: "1380008",
          market_name: "2nd  WKT Caught Out  NSWB @ 1",
          event_name: "Western Australia v New South Wales Blues",
          game_name: "cricket",
          event_time: "1706756700",
          settled_at: "1706862297",
          comission: 0,
          profitAndLoss: 0,
        },
        {
          provider_market_id: "1379986",
          market_name: "20 OVER TOTAL ODD  NSWB",
          event_name: "Western Australia v New South Wales Blues",
          game_name: "cricket",
          event_time: "1706756700",
          settled_at: "1706862293",
          comission: 0,
          profitAndLoss: 0,
        },
        {
          provider_market_id: "1379985",
          market_name: "10 OVER TOTAL ODD  NSWB",
          event_name: "Western Australia v New South Wales Blues",
          game_name: "cricket",
          event_time: "1706756700",
          settled_at: "1706862289",
          comission: 0,
          profitAndLoss: 0,
        },
      ],
    },
  ],
  totalProfitLoss: -239.76,
  gameTotal: [
    {
      _id: "cricket",
      total_profit_loss: -239.76,
      provider_game_id: null,
    },
  ],
};

export default profitAndLossReport_mock;
