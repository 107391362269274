import { Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import theme from "../../../../theme";

const ScoreCard = () => {
  return (
    <TableContainer sx={{ borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ bgcolor: `${theme.palette.secondary.main}d9` }}>
            <TableCell sx={{ color: "#fff", borderBottom: 0 }} colSpan={4}>
              Score Card
            </TableCell>
            {/* <TableCell sx={{ borderBottom: 0 }}></TableCell>
            <TableCell sx={{ borderBottom: 0 }}></TableCell>
            <TableCell sx={{ borderBottom: 0 }}></TableCell> */}
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default ScoreCard;
