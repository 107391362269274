import { Box, Grid } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

import MyButton from "../../../components/ui/myButton";
import MuiDataGrid from "../../../components/MuiDataGrid";
import ReportLayout from "../../../sections/report-layout";
import RowPageSearch from "../../../sections/row-page-search";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";
import { GENERAL_REPORTS } from "../../../mock/_general_report";

const SELECT_TYPES = [
  { label: "General Report", value: "General Report" },
  { label: "Credit Refrance Report", value: "Credit Refrance Report" },
];

const columns: GridColDef[] = [
  {
    field: "srNoL",
    headerName: "Sr. No",
    flex: 0.5,
  },
  {
    field: "nameL",
    headerName: "Name",
    flex: 0.5,
  },
  {
    field: "amountL",
    headerName: "Amount",
    flex: 0.5,
  },
  {
    field: "srNoR",
    headerName: "Sr. No",
    flex: 0.5,
  },
  {
    field: "nameR",
    headerName: "Name",
    flex: 0.5,
  },
  {
    field: "amountR",
    headerName: "Amount",
    flex: 0.5,
  },
];

const GeneralReportPage = () => {
  return (
    <ReportLayout>
      <ReportTitle title="General Reports" />

      <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
        <Grid item xs={12} sm={2} lg={2}>
          <MuiSelect label="Select Type" options={SELECT_TYPES} />
        </Grid>

        <Grid item xs={12} sm={2} md={2} lg={1.5}>
          <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
            <MyButton color="primary" variant="contained">
              Load
            </MyButton>
          </Box>
        </Grid>
      </Grid>

      <RowPageSearch />
      <MuiDataGrid
        rows={GENERAL_REPORTS}
        columns={columns}
        hideFooter
        sx={{
          "& .MuiDataGrid-row--lastVisible": {
            fontWeight: "bold",
          },
        }}
      />
    </ReportLayout>
  );
};

export default GeneralReportPage;
