import React from "react";

import ListClientsView from "../../sections/list-clients";

interface Props {
  users_type?: "all" | "active" | "inactive";
}

const ListClinetsPage: React.FC<Props> = ({ users_type }) => {
  return <ListClientsView users_type={users_type} />;
};

export default ListClinetsPage;
