import React from "react";

import { Stack, useMediaQuery } from "@mui/material";

import theme from "../../theme";
// import PlaceBetModal from '../place-bet-modal'
// import { useBoolean } from '../../hooks/use-boolean'
// import { useBetSlipBgColor, useCurrentBetSlip } from '../../zustore/zustore'

// ---------------------------------------

interface Props {
  type: string;
  odds?: number;
  isEventPage?: boolean;
  size?: number;
  emptyCellButton?: boolean;
  removeCell?: boolean;
  runner_name?: string;
  marketType?: string;
  fancy_rate?: number;
}

// ---------------------------------------

const BetButton: React.FC<Props> = ({
  type,
  odds,
  isEventPage,
  size,
  emptyCellButton,
  removeCell,
  runner_name,
  marketType,
  fancy_rate,
}) => {
  // const { setCurrentbetSlip } = useCurrentBetSlip()
  // const { setbetSlipBgColor } = useBetSlipBgColor()

  // Breakpoint
  const breakpoint = useMediaQuery(theme.breakpoints.up("md"));

  // const openModal = useBoolean(false)

  // const toggleModal = () => {
  //     openModal.onToggle()
  // }

  // const handleClick = () => {
  //   // setCurrentbetSlip({
  //   //     betFor: runner_name,
  //   //     odds: typeof odds === 'number' ? marketType === 'fancy' ? fancy_rate || 0 : odds : 0,
  //   //     stake: 0,
  //   //     liability: 0,
  //   //     market_type: marketType || ''
  //   // })
  //   // !breakpoint && toggleModal()
  //   // changeBetSlipBgColor()
  // };

  // const changeBetSlipBgColor = () => {
  //     if (type === 'lay') {
  //         setbetSlipBgColor('#FAA9BA')
  //     } else {
  //         setbetSlipBgColor('#72BBEF')
  //     }
  // }

  return (
    <>
      <Stack
        // onClick={handleClick}
        flex={1}
        sx={{
          display: removeCell ? "none" : "grid",
          opacity: emptyCellButton ? "0" : "1",
          border: "1px solid #fff",
          width: breakpoint ? "84px" : "100%",
          borderRadius: 0,
          bgcolor: type === "back" ? "#72BBEF" : "#FAA9BA",
          textAlign: "center",
          py: 0.9,
          fontSize: isEventPage ? "16px" : "14px",
          fontWeight: isEventPage ? 700 : 400,
          height: "inherit",
        }}
      >
        {odds || "-"}
        <span style={{ fontSize: "10px", fontWeight: 400 }}>{size && size}</span>
      </Stack>

      {/* <Modal open={openModal.value} onClose={toggleModal}>
                <>
                    <PlaceBetModal toggleModal={toggleModal} />
                </>
            </Modal> */}
    </>
  );
};

export default BetButton;
