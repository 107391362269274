import CustomTextField from "../components/ui/custom-text-field";
import { Stack } from "@mui/material";
import MuiSelect from "../components/MuiSelect/MuiSelect";
import FromDate from "../components/date/MuiDate";

const DemoPage = () => {
  return (
    <Stack direction={"row"} columnGap={2} pt={2} px={4}>
      <CustomTextField placeholder="Hello" />
      <MuiSelect />
      <MuiSelect />
      <MuiSelect />
      <FromDate />
    </Stack>
  );
};

export default DemoPage;
