import React from "react";

import {
  Box,
  Table,
  SxProps,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Typography,
  TableContainer,
} from "@mui/material";

import { FancyI } from "./types";
import theme from "../../../theme";
import EventsFancyTableRow from "./events-fancy-table-row";

// ------------------------------------------------------------
type Props = {
  fancy: FancyI[];
  title: string;
  isLink?: boolean;
};
// ------------------------------------------------------------
const sxStyle: SxProps = { width: "84px" };

const EventsFancyTable: React.FC<Props> = ({ fancy, title, isLink = false }) => {
  return (
    <Box p={0.5} mt={1}>
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          padding: "6px 16px 6px 6px",
          bgcolor: `${theme.palette.secondary.light}`,
          color: "white",
        }}
      >
        {title}
      </Typography>
      <TableContainer sx={{ mt: "1px" }}>
        <Table
          size="small"
          sx={{
            "& .MuiTableRow-root": {
              backgroundColor: "#0000000d",
            },
            "& .MuiTableCell-root": {
              borderWidth: "0 1px 1px 1px",
              borderColor: "#fff",
              borderStyle: "solid",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: "6px 16px", fontSize: "16px", fontWeight: 700 }} />

              <TableCell
                sx={{
                  ...sxStyle,
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#FAA9BA",
                  height: "30px",
                }}
              >
                No
              </TableCell>
              <TableCell
                sx={{
                  ...sxStyle,

                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#72BBEF",
                  height: "30px",
                }}
              >
                Yes
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(fancy) &&
              fancy.map((item: FancyI, i: number) => {
                return <EventsFancyTableRow key={i} fancy={item} sxStyle={sxStyle} />;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EventsFancyTable;
