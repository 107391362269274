export const GENERAL_REPORTS: any = [
  {
    id: "0ae68023-c276-43be-ae8f-44e11c079a35",
    srNoR: 1,
    nameR: "Kendra",
    nameL: "",
    srNoL: "",
    amountR: 181.57,
    amountL: "",
  },
  {
    id: "d841e39a-6f5c-4300-8f79-ee75129a39f0",
    srNoR: 2,
    nameR: "Brielle",
    nameL: "",
    srNoL: "",
    amountR: 826.28,
    amountL: "",
  },
  {
    id: "da767a01-ab92-49d1-b53d-7e5089c5e28a",
    srNoR: 3,
    nameR: "General Total",
    nameL: "General Total",
    srNoL: "",
    amountR: 1007.85,
    amountL: "",
  },
];
