import { Alert, Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../components/ui/custom-text-field";
import { LogoutSharp } from "@mui/icons-material";
import MyButton from "../../components/ui/myButton";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";

import { userLogin } from "../../redux/reducers/user";
import { useNavigate } from "react-router-dom";

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [user_name, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState();

  // Event handler for updating username state
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUsername(e.target.value);
  };

  // Event handler for updating password state
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // const ip_address = await getIP();
    const data = {
      user_name,
      password,
    };
    dispatch(userLogin(data))
      .unwrap()
      .then((res) => {
        // /change-password
        if (!res.data.is_password_changed) {
          navigate("/change-password");
        }
      })
      .catch((error) => setErrorMsg(error));
  };

  return (
    <Box sx={{ width: "400px", bgcolor: "#eee", px: "1.875rem", pt: "2.188rem", pb: "0.625rem" }}>
      <Divider
        sx={{
          alignItems: "flex-end",
          marginBottom: 3.8,
          "&::before, ::after": {
            width: "30%",
            borderTop: "thin solid rgb(0 0 0)",
            WebkitAlignSelf: "initial",
            alignSelf: "initial",
            position: "relative",
            bottom: "8px",
          },
          "& span.MuiDivider-wrapper.css-qywfm8-MuiDivider-wrapper": {
            width: "-webkit-fill-available",
          },
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "28px" }}>
          Sign In
        </Typography>
      </Divider>
      <form onSubmit={handleLogin}>
        <CustomTextField
          onChange={handleUsernameChange}
          value={user_name}
          sx={{ mb: 2.5 }}
          placeholder="Username"
          type="text"
          name="username"
        />
        <CustomTextField
          onChange={handlePasswordChange}
          value={password}
          sx={{ mb: 2.5 }}
          placeholder="Password"
          type="password"
          name="password"
        />

        <MyButton
          type="submit"
          variant="contained"
          fullWidth
          endIcon={<LogoutSharp />}
          sx={{ fontSize: "14px", mb: "14px" }}
        >
          Login
        </MyButton>
        {errorMsg && (
          <Alert severity="error" variant="outlined">
            {errorMsg}
          </Alert>
        )}
      </form>
    </Box>
  );
};

export default LoginForm;
