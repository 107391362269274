import React from "react";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";

import { Logout, ReplayOutlined } from "@mui/icons-material";
import { Stack, Switch, TextField, Typography } from "@mui/material";

import { ITemp } from "../users-table";
import endpoints from "../../../../api/endpoints";
import usePutApi from "../../../../hooks/use-put-api";
import MyButton from "../../../../components/ui/myButton";
import CustomModal from "../../../../components/custom-modal";
import StatusSchema, { IStatusSchema } from "./schema/status.schema";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  rowData: ITemp;
}

const ChangeStatusModal: React.FC<Props> = ({ openModal, toggleModal, rowData }) => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  const { putData } = usePutApi(endpoints.member.operation(rowData.id), [
    endpoints.member.getDownLineHandlerById(userId),
  ]);

  const { values, handleChange, handleBlur, handleSubmit, resetForm, errors } = useFormik<IStatusSchema>({
    initialValues: {
      is_active: rowData.user_status,
      is_bet_lock: rowData.bet_status,
      transaction_password: "",
      member_operation_type: "change-status",
    },
    validationSchema: StatusSchema,
    onSubmit: (formValues) => {
      handleChangeStatusSubmit(formValues);
    },
  });

  const handleChangeStatusSubmit = async (submitData: IStatusSchema) => {
    try {
      await putData(submitData);
      enqueueSnackbar("Status Changed Successful", { variant: "success" });
      toggleModal();
      resetForm();
    } catch (error: any) {
      const errorMessage = error?.data?.status_message;
      enqueueSnackbar(errorMessage || "Someting Went Wrong", { variant: "error" });
      toggleModal();
      resetForm();
    }
  };

  return (
    <CustomModal open={openModal} onCloseFunc={() => toggleModal()} title="Change Status">
      <form onSubmit={handleSubmit}>
        <Stack mt={4} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography fontSize={"20px"} sx={{ color: "#ffc01a" }}>
            {rowData.username}
          </Typography>

          <Typography fontSize={"14px"} sx={{ color: rowData.user_status ? "#28a745" : "tomato" }}>
            {rowData.user_status ? "Active" : "Inactive"}
          </Typography>
        </Stack>

        <Stack mt={4} direction={"row"} justifyContent={"space-around"} alignItems={"center"}>
          <Stack alignItems={"center"}>
            <Typography fontSize={"14px"}>User Active</Typography>
            <Switch onChange={handleChange} name="is_active" color="secondary" checked={values.is_active} />
          </Stack>
          <Stack alignItems={"center"}>
            <Typography fontSize={"14px"}>Bet Active</Typography>
            <Switch
              onChange={handleChange}
              name="is_bet_lock"
              color="secondary"
              checked={values.is_bet_lock}
            />
          </Stack>
        </Stack>

        <Stack mt={4} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
          <Typography maxWidth={"40%"} width={"-webkit-fill-available"} sx={{ fontSize: "14px" }}>
            Transaction Password
          </Typography>
          <Stack width={"60%"}>
            <TextField
              type="password"
              name="transaction_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.transaction_password}
              fullWidth
              size="small"
            />
            {errors.transaction_password && (
              <Typography variant="caption" color={"error"}>
                {errors.transaction_password}
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"end"} columnGap={1} mt={3}>
          <MyButton
            sx={{ color: "#fff" }}
            startIcon={<ReplayOutlined />}
            variant="contained"
            color="secondary"
          >
            Back
          </MyButton>
          <MyButton
            type="submit"
            sx={{ color: "#fff" }}
            endIcon={<Logout />}
            variant="contained"
            color="primary"
          >
            Submit
          </MyButton>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default ChangeStatusModal;
