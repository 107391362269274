import React from "react";

import { Stack } from "@mui/material";

import LiveMatch from "./live-match/live-matchs";
import ScoreCard from "./score-card/score-card";
import MatchData from "./match-data/match-data";

const RightBar: React.FC = () => {
  return (
    <Stack sx={{ p: 0.5 }} rowGap={1}>
      <LiveMatch />
      <ScoreCard />
      <MatchData />
    </Stack>
  );
};

export default RightBar;

// --------------------------------------------------------------------------------------------------

export const inputStyles = {
  height: "24px",
  background: "#fff",
  borderColor: "#efefef",
  boxShadow: "inset 0px 0px 0 1px #ced4da",
  textAlign: "center !important",
  display: "block",
  width: "100%",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#212529",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  appearance: "none",
  transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  border: "1px solid #ced4da !important",
  borderRadius: "0.25rem !important",
  padding: "0 !important",
  "& .MuiInputBase-input": {
    padding: 0,
    textAlign: "center !important",
  },
  "& .MuiInputBase-root": {
    padding: 0,
  },
};

export const numberArrowStyles = {
  fontSize: "16px",
  height: "11px",
  bgcolor: "#EFEFEF",
};
