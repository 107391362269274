import { Box, Typography } from "@mui/material";
import PageSize from "../../components/pagination/PageSize";
import CustomTextField from "../../components/ui/custom-text-field";
import { FC } from "react";

interface Props {
  onChangePageSize?: (val: number) => void;
}

const RowPageSearch: FC<Props> = ({ onChangePageSize = () => {} }) => {
  return (
    <Box sx={{ ml: 1, my: 1, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <PageSize onChangePageSize={onChangePageSize} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1" mr="3px">
          Search:
        </Typography>
        <CustomTextField />
      </Box>
    </Box>
  );
};

export default RowPageSearch;
