import React from "react";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";

import { Stack, TextField, Typography } from "@mui/material";
import { Logout, ReplayOutlined } from "@mui/icons-material";

import { ITemp } from "../users-table";
import endpoints from "../../../../api/endpoints";
import usePutApi from "../../../../hooks/use-put-api";
import MyButton from "../../../../components/ui/myButton";
import CustomModal from "../../../../components/custom-modal";
import PasswordSchema, { IPasswordSchema } from "./schema/password.schema";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  rowData: ITemp;
}

const PasswordModal: React.FC<Props> = ({ openModal, toggleModal, rowData }) => {
  const { values, errors, handleChange, handleSubmit, handleBlur, resetForm } = useFormik<IPasswordSchema>({
    initialValues: {
      new_password: "",
      confirm_password: "",
      transaction_password: "",
      member_operation_type: "password",
    },
    onSubmit: (formValues) => {
      handlePasswordSubmit(formValues);
    },
    validationSchema: PasswordSchema,
  });

  // PUT WITHDRAW OPERATION DATA
  const { putData } = usePutApi(endpoints.member.operation(rowData.id));

  const handlePasswordSubmit = async (submitValues: IPasswordSchema) => {
    try {
      await putData(submitValues);
      enqueueSnackbar("Password Updated Successful", { variant: "success" });
      toggleModal();
      resetForm();
    } catch (error: any) {
      const errorMessage = error?.data?.status_message;
      enqueueSnackbar(errorMessage || "Someting Went Wrong", { variant: "error" });
      toggleModal();
      resetForm();
    }
  };

  return (
    <CustomModal open={openModal} onCloseFunc={toggleModal} title="Password">
      <form onSubmit={handleSubmit}>
        <Stack sx={{ "& .Mui-disabled": { bgcolor: "#ddd", textAlign: "right" } }} py={4} spacing={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              New Password
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="password"
                fullWidth
                value={values.new_password}
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                name="new_password"
              />
              {errors.new_password && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.new_password}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              Confirm Password
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="password"
                fullWidth
                value={values.confirm_password}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                name="confirm_password"
              />
              {errors.confirm_password && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.confirm_password}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              Transaction Password
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="password"
                fullWidth
                value={values.transaction_password}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                name="transaction_password"
              />
              {errors.transaction_password && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.transaction_password}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"end"} columnGap={1}>
          <MyButton
            sx={{ color: "#fff" }}
            startIcon={<ReplayOutlined />}
            variant="contained"
            color="secondary"
            onClick={toggleModal}
          >
            Back
          </MyButton>
          <MyButton
            type="submit"
            sx={{ color: "#fff" }}
            endIcon={<Logout />}
            variant="contained"
            color="primary"
          >
            Submit
          </MyButton>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default PasswordModal;
