import React from "react";

import { Box } from "@mui/material";
import PreUserTable from "./pre-user-table";
import UsersTable from "./users-table";
import { IUser } from "../../../types/user";

interface Props {
  accountList: IUser[];
  selectedTab: string;
  isListClients: boolean;
}

const UsersList: React.FC<Props> = ({ accountList, isListClients, selectedTab }) => {
  return (
    <Box sx={{ mt: "1rem" }}>
      <PreUserTable />
      <Box mb={"1rem"} />

      <UsersTable isListClients={isListClients} selectedTab={selectedTab} accountList={accountList} />
    </Box>
  );
};

export default UsersList;
