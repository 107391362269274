import { Stack, Typography } from "@mui/material";

interface PropsTypes {
  title: string;
  action?: React.ReactNode;
}

const ReportTitle: React.FC<PropsTypes> = ({ title, action }) => {
  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <Typography component="h2" variant="h6" sx={{fontSize: '22.4px'}}>
        {title}
      </Typography>

      {action}
    </Stack>
  );
};

export default ReportTitle;
