import React from "react";
import { Icon } from "@iconify/react";

import { Box, Stack, Button, Typography } from "@mui/material";

const MobileAppAuth: React.FC = () => {
  return (
    <Box my={1.5} display={"grid"}>
      <Typography fontSize={"14px"} mb={1.5} align="center">
        Please enter below auth code in your 'Secure Auth Verification App'.
      </Typography>
      <Typography
        style={{
          border: "1px solid #f2f2f2",
          width: "fit-content",
          fontSize: "45px",
          lineHeight: "1",
          color: "#585858",
          background: "#e4e4e4",
          padding: "10px",
          margin: "auto",
        }}
      >
        457265
      </Typography>
      <Typography sx={{ fontSize: "21px" }} align="center" mt={1.5}>
        If you haven't downloaded,
        <br /> please download 'Secure Auth Verification App' from below link.
      </Typography>
      <Typography fontSize={"14px"} mb={1.5} align="center">
        Using this app you will receive auth code during login authentication
      </Typography>

      <Button
        startIcon={<Icon icon="uil:android" />}
        variant="contained"
        sx={{
          bgcolor: "#59A845",
          fontSize: "18px",
          margin: "auto",
          "&:hover": { bgcolor: "#59A845" },
          "& .css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1)": { fontSize: "50px" },
        }}
      >
        <Stack>
          <span style={{ fontSize: "22px" }}>Download</span>
          <span style={{ fontSize: "18px", textTransform: "capitalize" }}>on the android</span>
        </Stack>
      </Button>
    </Box>
  );
};

export default MobileAppAuth;
