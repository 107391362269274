import { Button, ButtonProps, SxProps } from "@mui/material";
import React from "react";

interface Props extends ButtonProps {
  sx?: SxProps;
  variant?: "text" | "outlined" | "contained";
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
}

const MyButton: React.FC<Props> = ({ sx = {}, children, ...allProps }) => {
  return (
    <Button
      {...allProps}
      sx={{
        borderRadius: "0.25rem",
        padding: "5px 10px",
        textTransform: "capitalize",
        color: "white",
        ...sx,
      }}
      disableElevation
      disableFocusRipple
      disableRipple
      disableTouchRipple
    >
      {children}
    </Button>
  );
};

export default MyButton;
