import { Dialog } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  open: boolean;
  handleClose: () => void;
}

const FullScreenPopUp: React.FC<Props> = ({ open, handleClose, children }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "calc(100% - 150px);",
        },
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default FullScreenPopUp;
