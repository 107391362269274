import { v4 as uuidv4 } from "uuid";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { Checkbox, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import { IUser } from "../../../types/user";
import UserRowActionButtons from "./user-row-action-buttons";
import CustomToolbar from "../../../components/custom-toolbar";

// const noRowsOverlay = () => {
//   return (
//     <Stack sx={{ justifyContent: "center", alignItems: "center", height: "inherit" }}>
//       <Typography>No Data</Typography>
//     </Stack>
//   );
// };

// ---------------------------------------------------------------------------

const columns: GridColDef[] = [
  {
    field: "username",
    headerName: "User Name",
    width: 270,
    renderCell: (params) => {
      return <UserNameCol rowData={params.row} />;
    },
  },
  {
    field: "credit_reference",
    headerName: "Credit Reference",
    align: "right",
    width: 190,
  },
  {
    field: "user_status",
    headerName: "U St",
    renderCell: (params) => {
      return <Checkbox disableFocusRipple disableRipple disableTouchRipple checked={params.value} />;
    },
    width: 50,
  },
  {
    field: "bet_status",
    headerName: "B St",
    renderCell: (params) => {
      return <Checkbox disableFocusRipple disableRipple disableTouchRipple checked={params.value} />;
    },
    width: 50,
  },
  {
    field: "exposure_limit",
    headerName: "Exposure Limit",
    align: "right",
    width: 150,
  },
  {
    field: "default",
    headerName: "Defualt %",
    align: "right",
    width: 150,
  },
  {
    field: "account_type",
    headerName: "Account Type",
    width: 150,
  },
  {
    field: "casino_total",
    headerName: "Casino Total",
    align: "right",
    width: 200,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 300,
    renderCell: (params) => {
      return <UserRowActionButtons rowData={params.row} />;
    },
  },
];

// ---------------------------------------------------------------------------
interface UsersTableProps {
  accountList: IUser[];
  selectedTab: string;
  isListClients: boolean;
}

export interface ITemp {
  randomId: string;
  id: string;
  username: string;
  admin_id?: string;
  user_name?: string;
  credit_reference: number;
  user_status: boolean;
  bet_status: boolean;
  exposure_limit: number;
  default: number;
  account_type: string;
  casino_total: number;
  balance?: number;
  user_id?: string;
  admin_user_name?: string;
}

const UsersTable: React.FC<UsersTableProps> = ({ accountList, isListClients, selectedTab }) => {
  const [filteredAccountList, setFilteredAccountList] = useState<ITemp[]>([]);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  useEffect(() => {
    if (accountList?.length) {
      const temp: ITemp[] = accountList
        .filter((account: IUser) => (!isListClients ? account : account.status === selectedTab))
        .map((account: IUser, index: number) => {
          const {
            user_name,
            credit_reference,
            status: user_status,
            is_bet_lock,
            member_max_exposure_amt: exposure_limit,
            role,
            user_id,
            balance,
            admin_id,
          } = account;

          return {
            id: user_id || admin_id || "",
            randomId: uuidv4(),
            user_id,
            admin_id,
            username: user_name,
            credit_reference,
            user_status: user_status === "Active",
            bet_status: !is_bet_lock,
            exposure_limit,
            default: 0,
            account_type: role.name,
            casino_total: 0,
            balance,
          };
        });
      // TODO: show in first row
      // const total_credit_reference = temp.reduce(function (credit_reference, obj) {
      //   return credit_reference + obj.credit_reference;
      // }, 0);

      setFilteredAccountList(temp);
    }
  }, [accountList, accountList?.length, isListClients, selectedTab]);

  return (
    <DataGrid
      slotProps={{ row: {} }}
      getRowId={(row) => row.randomId}
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
      slots={{
        toolbar: () => (
          <CustomToolbar
            page={paginationModel.page}
            noOfEntries={filteredAccountList.length}
            entriesPerPage={paginationModel.pageSize}
          />
        ),
      }}
      sx={{
        flexDirection: "column-reverse !important",
        "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader": {
          maxHeight: "40px !important",
          minHeight: "40px !important",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
        },
        "& .MuiDataGrid-row:nth-child(odd)": {
          bgcolor: "#ececec",
        },
        "& .MuiDataGrid-cell": {
          border: "1px solid #00000024",
        },
        "& .MuiDataGrid-footerContainer": {
          display: "none",
        },
      }}
      columns={columns}
      rows={filteredAccountList}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 5,
          },
        },
      }}
      pageSizeOptions={[5]}
      disableRowSelectionOnClick
    />
  );
};

export default UsersTable;

const UserNameCol: React.FC<{ rowData: ITemp }> = ({ rowData }) => {
  const { pathname } = useLocation();
  const isListClients = pathname.split("/")[1] === "list-clients";

  return (
    <Link
      target={rowData.account_type.toLowerCase() === "user" ? "_self" : "_blank"}
      to={
        rowData.account_type.toLowerCase() === "user"
          ? "javascript:void(0)"
          : `/${isListClients ? "list-clients" : "active-users"}/${rowData.id}`
      }
    >
      <Typography
        sx={{
          cursor: "pointer",
          background: "#444",
          padding: "5px 10px",
          textDecoration: "none",
          color: "#fff",
          borderRadius: "3px",
          marginRight: "3px",
          textTransform: "uppercase",
          display: "inline-block",
          fontSize: "14px",
        }}
      >
        {rowData.username}
      </Typography>
    </Link>
  );
};
