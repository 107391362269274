import { Stack, SxProps } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import MyButton from "../../../components/ui/myButton";
import { useBoolean } from "usehooks-ts";
import DepositModal from "./modals/deposit-modal";
import WithdrawlModal from "./modals/withdrawl-modal";
import ExposureLimitModal from "./modals/exposure-limit-modal";
import CreditModal from "./modals/credit-modal";
import PasswordModal from "./modals/password-modal";
import ChangeStatusModal from "./modals/change-status-modal";
import { ITemp } from "./users-table";
import endpoints, { IMember } from "../../../api/endpoints";
import { IUserData } from "../../../redux/types";
import useGetApi from "../../../hooks/use-get-api";

interface Props {
  rowData: ITemp;
}

const UserRowActionButtons: React.FC<Props> = ({ rowData }) => {
  const { data } = useGetApi<IMember>(endpoints.member.getMember(rowData?.id as string));

  const userData = JSON.parse(localStorage.getItem("user_data") as string) as IUserData;

  const isAdminDownline = useBoolean(false);

  const openDepositModal = useBoolean(false);
  const openWithdrawlModal = useBoolean(false);
  const openExposureLimitModal = useBoolean(false);
  const openCreditModal = useBoolean(false);
  const openPasswordModal = useBoolean(false);
  const openStatusModal = useBoolean(false);

  useEffect(() => {
    if (data && userData) {
      isAdminDownline.setValue(userData?.user_id === data?.admin_id);
    }
  }, [data, userData, isAdminDownline]);

  return (
    <>
      <Stack direction={"row"} columnGap={1}>
        <ActionButton onClick={() => openDepositModal.toggle()}>D</ActionButton>
        <ActionButton onClick={() => openWithdrawlModal.toggle()}>W</ActionButton>
        {isAdminDownline.value && (
          <>
            <ActionButton onClick={() => openExposureLimitModal.toggle()}>L</ActionButton>
            <ActionButton onClick={() => openCreditModal.toggle()}>C</ActionButton>
            <ActionButton onClick={() => openPasswordModal.toggle()}>P</ActionButton>
            <ActionButton onClick={() => openStatusModal.toggle()}>S</ActionButton>
            <ActionButton sx={{ px: 3 }}>More</ActionButton>
          </>
        )}
      </Stack>

      {/* Deposit Modal */}
      {openDepositModal.value && (
        <DepositModal
          data={data}
          rowData={rowData}
          openModal={openDepositModal.value}
          toggleModal={() => {
            openDepositModal.toggle();
            // mutate(endpoints.member.getMember(rowData?.user_id as string));
          }}
        />
      )}
      {/* WithDrawl Modal */}
      {openWithdrawlModal.value && (
        <WithdrawlModal
          data={data}
          rowData={rowData}
          openModal={openWithdrawlModal.value}
          toggleModal={() => openWithdrawlModal.toggle()}
        />
      )}

      {/* Exposure Limit Modal */}
      {openExposureLimitModal.value && (
        <ExposureLimitModal
          rowData={rowData}
          openModal={openExposureLimitModal.value}
          toggleModal={() => openExposureLimitModal.toggle()}
        />
      )}
      {/* Credit Modal */}
      {openCreditModal.value && (
        <CreditModal
          rowData={rowData}
          openModal={openCreditModal.value}
          toggleModal={() => openCreditModal.toggle()}
        />
      )}
      {/* Password */}
      {openPasswordModal.value && (
        <PasswordModal
          rowData={rowData}
          openModal={openPasswordModal.value}
          toggleModal={openPasswordModal.toggle}
        />
      )}
      {/* Change Status */}
      {openStatusModal.value && (
        <ChangeStatusModal
          rowData={rowData}
          openModal={openStatusModal.value}
          toggleModal={openStatusModal.toggle}
        />
      )}
    </>
  );
};

export default UserRowActionButtons;

interface ActionButtonProps extends PropsWithChildren {
  sx?: SxProps;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({ children, sx, onClick }) => {
  return (
    <MyButton
      onClick={() => onClick && onClick()}
      sx={{
        "&:hover": { bgcolor: "#444" },
        bgcolor: "#444",
        color: "#fff",
        p: 0,
        width: "25px !important",
        maxWidth: "25px !important",
        minWidth: "25px !important",
        textTransform: "uppercase",
        ...sx,
      }}
      variant="contained"
    >
      {children}
    </MyButton>
  );
};
