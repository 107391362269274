import useSWR from "swr";
import { useMemo } from "react";

import { fetcher } from "../api/instance";

export default function useGetApi<TData>(endpoint: string, condition = true) {
  const { data, error, isLoading } = useSWR(condition ? endpoint : null, fetcher);

  const memoizedData = useMemo(
    () => ({
      data: data?.data as TData,
      isLoading,
      error,
    }),
    [data?.data, isLoading, error]
  );

  return memoizedData;
}
