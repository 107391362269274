import React from 'react'

import SixColMarketTable from '../six-col-market-table/six-col-market-table'

import { eventMarketData } from '../../../mock/_event-market-data'

import TwoColMarketTable from '../two-col-market-table/two-col-market-table'

import FancyMarketTable from '../fancy-col-market-table/fancy-col-market-table'

import { Grid, Stack, Typography } from '@mui/material'
import RunnersMarketTable from '../runners-market-table/runners-market-table'
import EventsFancyTable from '../events-fancy/events-fancy-table'

const SportEventView: React.FC = () => {

    return (
        <>
            <Stack m={0.5} p={1} direction={'row'} justifyContent={'space-between'} bgcolor={'secondary.main'} >
                <Typography color={'#fff'} sx={{ fontSize: '15px', fontWeight: 600 }}>TWENTY20 BIG BASH</Typography>
                <Typography color={'#fff'}>12/13/2022 02:45:00 PM</Typography>
            </Stack>

            <Grid container>
                <Grid item xs={12} md={12}>
                    <RunnersMarketTable data={eventMarketData?.match_odd[0]} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <SixColMarketTable data={eventMarketData?.match_odd[1]} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TwoColMarketTable variant="small" data={eventMarketData?.bookmaker} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <EventsFancyTable fancy={eventMarketData?.fancy} title="Fancy Market" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FancyMarketTable variant="small" data={eventMarketData?.fancy[0]} />
                </Grid>
            </Grid>
        </>
    )
}

export default SportEventView