import type { FC } from "react";
import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import type { SxProps, TextFieldPropsSizeOverrides, TextFieldVariants } from "@mui/material";
import type { OverridableStringUnion } from "@mui/types";

export interface OptionI {
  label: string;
  value: string;
}

// [other: string]: any

export interface SimpleAutoCompleteProps extends TextFieldPropsSizeOverrides {
  name: string;
  options: OptionI[];
  inputValue: string;
  onInputChange: (event: React.ChangeEvent<{}>, value: string) => void;
  variant?: TextFieldVariants;
  label?: string;
  placeholder?: string;
  sx?: SxProps;
  disableClearable?: boolean;
  helperText?: string;
  size?: OverridableStringUnion<"small" | "medium", TextFieldPropsSizeOverrides>;
  required?: boolean;
  onChange?: (name: string, value: string | null | undefined) => void;
}

interface PropsI extends SimpleAutoCompleteProps {
  value: string | undefined | null;
  onChange?: (name: string, value: string | null | undefined) => void;
  error?: any;
}

const AutoCompleteSearch: FC<PropsI> = ({
  name,
  options = [],
  inputValue,
  onInputChange,
  value,
  variant = "outlined",
  label,
  placeholder,
  onChange = () => {},
  disableClearable = false,
  error,
  helperText,
  sx = {},
  size = "small",
  required = false,
}) => {
  const selectedValue = useMemo(
    () => (Array.isArray(options) && options.find((op) => op.value === value)) || null,
    [options, value]
  );

  if (!name) return null;

  return (
    <Autocomplete
      disableClearable={disableClearable}
      options={options}
      value={selectedValue}
      inputValue={inputValue}
      onChange={(event, newValue) => {
        onChange(name, newValue ? newValue.value : undefined);
      }}
      onInputChange={onInputChange}
      size={size}
      sx={{ ...sx }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          error={!!error}
          required={required}
          helperText={error?.message ? error?.message : helperText}
          sx={{
            "& input::placeholder": {
              fontSize: "14px",
            },
            "& input": {
              p: "5px 10px",
            },
          }}
        />
      )}
    />
  );
};

export default AutoCompleteSearch;
