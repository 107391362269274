import { Box, Grid } from "@mui/material";

import MyButton from "../../../components/ui/myButton";
import MuiDate from "../../../components/date/MuiDate";
import ReportLayout from "../../../sections/report-layout";
import RowPageSearch from "../../../sections/row-page-search";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";
// import { CASINO_RESULTS } from "../../../mock/_casino-result";

const GAMES = [
  { label: "1 Day Teenpatti", value: "1 Day Teenpatti" },
  { label: "20-20 Teenpatti", value: "20-20 Teenpatti" },
  { label: "Open Teenpatti", value: "Open Teenpatti" },
  { label: "1 Day Poker", value: "1 Day Poker" },
];

// const columns: GridColDef[] = [
//   {
//     field: "round_id",
//     headerName: "Round ID",
//     flex: 0.5,
//   },
//   {
//     field: "winner",
//     headerName: "Winner",
//     flex: 0.5,
//   },
// ];

const CasinoResultPage = () => (
  <ReportLayout>
    <ReportTitle title="Casino Result Report" />

    <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
      <Grid item xs={12} sm={2} lg={1.5}>
        <MuiDate />
      </Grid>
      <Grid item xs={12} sm={2} lg={1.5}>
        <MuiSelect options={GAMES} />
      </Grid>

      <Grid item xs={12} sm={2} md={2} lg={1.5}>
        <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
          <MyButton color="primary" variant="contained">
            Submit
          </MyButton>
        </Box>
      </Grid>
    </Grid>

    <RowPageSearch />
    {/* <MuiDataGrid rows={CASINO_RESULTS} columns={columns} /> */}
  </ReportLayout>
);

export default CasinoResultPage;
