import { useRoutes } from "react-router-dom";

import LoginPage from "../pages/login-page";
import ListClinetsPage from "../pages/list-clients";

import ProtectedRoute from "../components/protected-route";
import AccountStatementPage from "../pages/reports/account-statement";
import CurrentBetsPage from "../pages/reports/current-bets";
import GeneralReportPage from "../pages/reports/general-report";
import GameReportPage from "../pages/reports/game-report";
import CasinoReportPage from "../pages/reports/casino-report";
import DemoPage from "../pages/DemoPage";
import ProfitLossReportPage from "../pages/reports/profit-loss";
import SecurityAuthPage from "../pages/security-auth";
import CasinoResultPage from "../pages/reports/casino-result";
import SportEventPage from "../pages/sport-event";
import ChangePassword from "../pages/change-password";
import AddAccount from "../pages/add-account";
import ActiveDeActiveUsers from "../sections/list-clients/view/active-deactive-users";

export default function Router() {
  // const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;
  return useRoutes([
    {
      path: "/login",
      index: true,
      element: <LoginPage />,
    },
    {
      path: "/change-password",
      element: (
        <ProtectedRoute>
          <ChangePassword />
        </ProtectedRoute>
      ),
    },
    {
      path: "/list-clients/:userId",
      element: (
        <ProtectedRoute>
          <ActiveDeActiveUsers />
        </ProtectedRoute>
      ),
    },
    {
      path: "/add-account",
      element: (
        // <ProtectedRoute>
        <AddAccount />
        // </ProtectedRoute>
      ),
    },
    {
      path: "/active-users/:userId",
      element: (
        <ProtectedRoute>
          <ListClinetsPage users_type={"active"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/account-statement",
      element: (
        <ProtectedRoute>
          <AccountStatementPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/current-bets",
      element: (
        <ProtectedRoute>
          <CurrentBetsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/general-report",
      element: (
        <ProtectedRoute>
          <GeneralReportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/game-report",
      element: (
        <ProtectedRoute>
          <GameReportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/casino-report",
      element: (
        <ProtectedRoute>
          <CasinoReportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profit-loss",
      element: (
        <ProtectedRoute>
          <ProfitLossReportPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/security-auth",
      element: (
        <ProtectedRoute>
          <SecurityAuthPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/casinoresult",
      element: (
        <ProtectedRoute>
          <CasinoResultPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/analysis-running-market/:id",
      element: (
        <ProtectedRoute>
          <SportEventPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bs",
      element: <DemoPage />,
    },
  ]);
}
