import { ArrowCircleDown, ArrowCircleUp } from "@mui/icons-material";
import { Box, Collapse, Grid, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useBoolean } from "usehooks-ts";

interface StatusGridI {
  title: string;
  value?: string | number;
}

const StatusGrid: FC<StatusGridI> = ({ title, value = 0 }) => {
  return (
    <Grid item sm={2} sx={{ mb: "5px" }}>
      <Grid container columns={10}>
        <Grid item sm={7}>
          <Typography
            sx={{ fontSize: "14px", lineHeight: "15px", mb: "7px", color: "#fff", fontWeight: "100" }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography
            sx={{ fontSize: "14px", lineHeight: "15px", mb: "7px", color: "#fff", fontWeight: "100" }}
          >
            {value}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

interface IAccountData {
  accountData: Partial<{
    down_level_credit_reference: number;
    total_master_balance: number;
    down_level_occupy_balance: number;
    down_level_profit_loss: number;
    available_balance_with_profit_loss: number;
    available_balance: number;
    upper_level: number;
    // upper_level_credit_reference: number;
    my_profit_loss: number;
  }>;
}

const AccountStatusHeader: FC<IAccountData> = ({ accountData }) => {
  const isShow = useBoolean(false);
  return (
    <Box sx={{ bgcolor: "primary.dark", p: "20px" }}>
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        {isShow.value ? (
          <ArrowCircleUp onClick={isShow.toggle} sx={{ color: "#fff", cursor: "pointer" }} />
        ) : (
          <ArrowCircleDown onClick={isShow.toggle} sx={{ color: "#fff", cursor: "pointer" }} />
        )}
      </Stack>

      <Collapse in={isShow.value} timeout={900}>
        <Grid container columnSpacing={1} columns={6} sx={{ mt: "20px" }}>
          <StatusGrid title={"Upper Level Credit Reference:"} value={accountData.upper_level} />
          <StatusGrid title={"Down level Occupy Balance:"} value={accountData.down_level_occupy_balance} />
          <StatusGrid
            title={"Down Level Credit Reference:"}
            value={accountData.down_level_credit_reference}
          />
          <StatusGrid title={"Total Master Balance"} value={accountData.total_master_balance} />
          <StatusGrid title={"Upper Level:"} value={accountData.upper_level} />
          <StatusGrid title={"Down Level Profit/Loss :"} value={accountData.down_level_profit_loss} />
          <StatusGrid title={"Available Balance:"} value={accountData.available_balance} />
          <StatusGrid
            title={"Available Balance With Profit/Loss:"}
            value={accountData.available_balance_with_profit_loss}
          />
          <StatusGrid title={"My Profit/Loss:"} value={accountData.my_profit_loss} />
        </Grid>
      </Collapse>
    </Box>
  );
};

export default AccountStatusHeader;
