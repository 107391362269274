import * as Yup from 'yup'

export interface ICreditLimitSchema {
    new_credit: number
    transaction_password: string
    member_operation_type: string
}

const CreditLimitSchema = Yup.object({
    new_credit: Yup.number().required('New Limit is required').test('not-zero', 'New Limit should be greater than zero', value => value > 0),
    transaction_password: Yup.string().required('Transaction Password is required'),
})

export default CreditLimitSchema