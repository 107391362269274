export const SIDEBAR_ITEMS = [
  {
    title: "Football",
    paddingLeft: "16px",
    items: [
      {
        title: "ALGERIA U21 League",
        paddingLeft: "26px",
        items: [
          {
            title: "2024/02/08",
            paddingLeft: "36px",
            items: [
              {
                title: "Biskra U21 - JS Saoura U21",
                paddingLeft: "46px",
                items: [
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                  {
                    title: "Draw No Bet",
                    to: "/",
                  },
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
              {
                title: "Cr Belouizdad U21 - Us Souf U21",
                paddingLeft: "46px",
                items: [
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                  {
                    title: "Draw No Bet",
                    to: "/",
                  },
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
              {
                title: "CS Constantine U21 - MC El Bayadh U21",
                paddingLeft: "46px",
                items: [
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                  {
                    title: "Draw No Bet",
                    to: "/",
                  },
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
              {
                title: "ES Ben Aknoun U21 - Mc Alger U21",
                paddingLeft: "46px",
                items: [
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                  {
                    title: "Draw No Bet",
                    to: "/",
                  },
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
              {
                title: "ES Setif U21 - USM Khenchela U21",
                paddingLeft: "46px",
                items: [
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                  {
                    title: "Draw No Bet",
                    to: "/",
                  },
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "ARGENTINA Copa de la Liga Profesional",
        paddingLeft: "26px",
        items: [
          {
            title: "2024/02/09",
            paddingLeft: "36px",
            items: [
              {
                title: "Huracan v CA Independiente",
                paddingLeft: "46px",
                items: [
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                  {
                    title: "OVER_UNDER_05",
                    to: "/",
                  },
                  {
                    title: "OVER_UNDER_15",
                    to: "/",
                  },
                  {
                    title: "OVER_UNDER_25",
                    to: "/",
                  },
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                  {
                    title: "Both Teams To Score",
                    to: "/",
                  },
                ],
              },
              {
                title: "Rosario Central v Independiente Rivadavia",
                paddingLeft: "46px",
                items: [
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "Next Goal 1.0",
                    to: "/",
                  },
                  {
                    title: "Next Goal 2.0",
                    to: "/",
                  },
                  {
                    title: "Next Goal 3.0",
                    to: "/",
                  },
                  {
                    title: "OVER_UNDER_25",
                    to: "/",
                  },
                  {
                    title: "1st Period Winner",
                    to: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: "Cricket",
    paddingLeft: "16px",
    items: [
      {
        title: "Bangladesh Premier League",
        paddingLeft: "26px",
        items: [
          {
            title: "2024/02/09",
            paddingLeft: "36px",
            items: [
              {
                title: "Comilla Victorians v Durdanto Dhaka",
                paddingLeft: "46px",
                items: [
                  {
                    title: "Goals Odd/Even",
                    to: "/",
                  },
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
              {
                title: "Sylhet Strikers v Khulna Tigers",
                paddingLeft: "46px",
                items: [
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "ICC U19 World Cup",
        paddingLeft: "26px",
        items: [
          {
            title: "2024/02/08",
            paddingLeft: "36px",
            items: [
              {
                title: "Huracan v CA Independiente",
                paddingLeft: "46px",
                items: [
                  {
                    title: "MATCH_ODDS",
                    to: "/",
                  },
                  {
                    title: "OVER_UNDER_05",
                    to: "/",
                  },
                ],
              },
              {
                title: "Rosario Central v Independiente Rivadavia",
                paddingLeft: "46px",
                items: [
                  {
                    title: "HT/FT",
                    to: "/",
                  },
                  {
                    title: "Next Goal 1.0",
                    to: "/",
                  },
                  {
                    title: "Next Goal 2.0",
                    to: "/",
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
