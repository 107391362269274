import { Box, Grid } from "@mui/material";

import MyButton from "../../../components/ui/myButton";
// import { CASINO_REPORTS } from "../../../mock/_casino-report";
import MuiDate from "../../../components/date/MuiDate";
import ReportLayout from "../../../sections/report-layout";
import RowPageSearch from "../../../sections/row-page-search";
import ReportTitle from "../../../sections/title/report-title";
import MuiSelect from "../../../components/MuiSelect/MuiSelect";

const GAME_TYPES = [
  { label: "Slot Game", value: "Slot Game" },
  { value: "Live Casino", label: "Live Casino" },
  { value: "Live Casino 1", label: "Live Casino 1" },
  { value: "Live Casino 2", label: "Live Casino 2" },
];

// const columns: GridColDef[] = [
//   {
//     field: "username",
//     headerName: "Username",
//     flex: 0.5,
//   },
//   {
//     field: "casino_type",
//     headerName: "Casino Type",
//     flex: 0.5,
//   },
//   {
//     field: "casino_name",
//     headerName: "Casino Name",
//     flex: 0.5,
//   },
//   {
//     field: "transaction_id",
//     headerName: "Transaction Id",
//     flex: 0.5,
//   },
//   {
//     field: "transaction_type",
//     headerName: "Transaction Type",
//     flex: 0.5,
//   },
//   {
//     field: "game_id",
//     headerName: "Game Id",
//     flex: 0.5,
//   },
//   {
//     field: "amount",
//     headerName: "Amount",
//     flex: 0.5,
//   },
//   {
//     field: "date",
//     headerName: "Date",
//     flex: 0.5,
//   },
// ];

const CasinoReportPage = () => (
  <ReportLayout>
    <ReportTitle title="Casino Report" />

    <Grid container spacing={1} sx={{ px: 0.5, py: 0.7 }}>
      <Grid item xs={12} sm={12} md={2} lg={1.5}>
        <MuiDate label="From" />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={1.5}>
        <MuiDate label="To" />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={1.5}>
        <MuiSelect label="Game Type" options={GAME_TYPES} />
      </Grid>

      <Grid item xs={12} sm={2} md={2} lg={1.5}>
        <Box sx={{ display: "flex", alignItems: "flex-end", height: "100%", mt: "auto" }}>
          <MyButton color="primary" variant="contained">
            Submit
          </MyButton>
        </Box>
      </Grid>
    </Grid>

    <RowPageSearch />
    {/* <MuiDataGrid rows={CASINO_REPORTS} columns={columns} /> */}
  </ReportLayout>
);

export default CasinoReportPage;
