import React from "react";
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";

import { Stack, TextField, Typography } from "@mui/material";
import { Logout, ReplayOutlined } from "@mui/icons-material";

import { ITemp } from "../users-table";
import endpoints from "../../../../api/endpoints";
import usePutApi from "../../../../hooks/use-put-api";
import parseNumber from "../../../../utils/parse-number";
import MyButton from "../../../../components/ui/myButton";
import CustomModal from "../../../../components/custom-modal";
import ExposureLimitSchema, { IExposureLimitSchema } from "./schema/exposure-limit.schema";

interface Props {
  openModal: boolean;
  toggleModal: () => void;
  rowData: ITemp;
}

const ExposureLimitModal: React.FC<Props> = ({ openModal, toggleModal, rowData }) => {
  const userId = JSON.parse(localStorage.getItem("user_data") as string)?.user_id as string;

  const { values, errors, handleChange, handleSubmit, handleBlur, resetForm } = useFormik({
    initialValues: {
      new_limit: 0,
      transaction_password: "",
      member_operation_type: "exposure-limit",
    },
    onSubmit: (formValues) => {
      handleExposureLimitSubmit(formValues);
    },
    validationSchema: ExposureLimitSchema,
  });

  // PUT WITHDRAW OPERATION DATA
  const { putData } = usePutApi(endpoints.member.operation(rowData.id), [
    endpoints.member.getDownLineHandlerById(userId),
    endpoints.member.getMember(userId),
  ]);

  const handleExposureLimitSubmit = async (submitData: IExposureLimitSchema) => {
    try {
      await putData({
        ...submitData,
        new_limit: parseNumber({ type: "float", value: values.new_limit }),
      });
      enqueueSnackbar("Exposure Limit Updated Successful", { variant: "success" });
      toggleModal();
      resetForm();
    } catch (error: any) {
      const errorMessage = error?.data?.status_message;
      enqueueSnackbar(errorMessage || "Someting Went Wrong", { variant: "error" });
      toggleModal();
      resetForm();
    }
  };

  return (
    <CustomModal open={openModal} onCloseFunc={toggleModal} title="Exposure Limit">
      <form onSubmit={handleSubmit}>
        <Stack sx={{ "& .Mui-disabled": { bgcolor: "#ddd", textAlign: "right" } }} py={4} spacing={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              Old Limit
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="number"
                sx={{ "& input": { textAlign: "right" } }}
                fullWidth
                disabled
                value={rowData.exposure_limit}
                size="small"
              />
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              New Limit
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="number"
                sx={{ "& input": { textAlign: "right" } }}
                fullWidth
                value={values.new_limit !== 0 ? values.new_limit : ""}
                size="small"
                onChange={handleChange}
                onBlur={handleBlur}
                name="new_limit"
              />
              {errors.new_limit && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.new_limit}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack direction={"row"} alignItems={"center"}>
            <Typography width={"30%"} variant="body2">
              Transaction Password
            </Typography>
            <Stack width={"70%"}>
              <TextField
                type="password"
                fullWidth
                value={values.transaction_password}
                onChange={handleChange}
                onBlur={handleBlur}
                size="small"
                name="transaction_password"
              />
              {errors.transaction_password && (
                <Typography mt={"0 !important"} variant="caption" color={"error"}>
                  {errors.transaction_password}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={"row"} justifyContent={"end"} columnGap={1}>
          <MyButton
            sx={{ color: "#fff" }}
            startIcon={<ReplayOutlined />}
            variant="contained"
            color="secondary"
            onClick={toggleModal}
          >
            Back
          </MyButton>
          <MyButton
            type="submit"
            sx={{ color: "#fff" }}
            endIcon={<Logout />}
            variant="contained"
            color="primary"
          >
            Submit
          </MyButton>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default ExposureLimitModal;
