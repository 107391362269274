import React from "react";

import { Menu } from "@mui/icons-material";
import { Box, Drawer, IconButton, Typography } from "@mui/material";

import { MenuItem } from "./multiLevelMenuItem";
import { SIDEBAR_ITEMS } from "../../mock/_sidebar";

const Sidebar = () => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState(open);
  };

  return (
    <>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        sx={{ marginRight: 2 }}
        onClick={toggleDrawer(true)}
      >
        <Menu sx={{ color: "#000", fontSize: "28px" }} />
      </IconButton>

      <React.Fragment key="left">
        <Drawer anchor="left" open={state} onClose={toggleDrawer(false)}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            // onClick={toggleDrawer(false)}
            // onKeyDown={toggleDrawer(false)}
          >
            <Typography sx={{ padding: "16px", fontWeight: "900", fontSize: "20px" }}>Sports</Typography>
            {SIDEBAR_ITEMS?.map((item, key) => <MenuItem key={key} item={item} />)}
            {/* <Divider /> */}
          </Box>
        </Drawer>
      </React.Fragment>
    </>
  );
};

export default Sidebar;
