import moment from "moment";

export const formatTimeStamp = (newTimeStamp: string) => {
  const timestamp = parseInt(newTimeStamp, 10) * 1000;
  const dateStr = moment(timestamp).format("DD/MM/YYYY hh:mm:ss");
  return dateStr;
};

export const formatDate = (newTimeStamp: string, format?: string) => {
  const timestamp = parseInt(newTimeStamp, 10) * 1000;
  const dateStr = moment(timestamp).format(format ?? "DD/MM/YYYY");
  return dateStr;
};

export const formatTime = (newTimeStamp: string) => {
  const timestamp = parseInt(newTimeStamp, 10) * 1000;
  const dateStr = moment(timestamp).format("hh:mm:ss");
  return dateStr;
};

export const fTimeHHMM = (newTimeStamp: string) => {
  const timestamp = parseInt(newTimeStamp, 10);
  const dateStr = moment.unix(timestamp).format("HH:mm");
  return dateStr;
};

export const fUnixTimeStamp = (newTimeStamp: string, format: string = "YYYY-MM-DD hh:mm:ss"): string => {
  if (!newTimeStamp) return "";

  const timestamp = parseInt(newTimeStamp, 10);

  if (Number.isNaN(timestamp)) return "";

  return moment.unix(timestamp).format(format);
};
