import { SxProps } from "@mui/material";
import { DataGrid, GridColDef, GridRowIdGetter } from "@mui/x-data-grid";
import React from "react";
import CustomToolbar from "../custom-toolbar";
import { IAccountStatement } from "../../mock/_casino-report";

interface PageOptions {
  pageSize: number;
  page: number;
}

const defaultPageOptions = {
  pageSize: 10,
  page: 0,
};

interface PropsDatGrid {
  rows: IAccountStatement[];
  columns: GridColDef[];
  sx?: SxProps;
  getRowId?: GridRowIdGetter;
  pageOptions?: PageOptions;
  onChangePagination?: (pageModel: PageOptions) => void;
  noOfEntries?: number;
  hideFooter?: boolean;
}

const MuiDataGrid: React.FC<PropsDatGrid> = ({
  rows = [],
  columns = [],
  sx = {},
  getRowId,
  pageOptions = defaultPageOptions,
  onChangePagination = () => {},
  noOfEntries = 0,
  hideFooter = false,
}) => {
  return (
    <DataGrid
      paginationModel={pageOptions}
      rowCount={noOfEntries}
      // paginationMode="server"
      onPaginationModelChange={(pageModel) => {
        onChangePagination(pageModel);
      }}
      slots={{
        footer: () => (
          <CustomToolbar
            page={pageOptions.page}
            entriesPerPage={pageOptions.pageSize}
            noOfEntries={noOfEntries}
          />
        ),
      }}
      getRowId={getRowId}
      rows={rows}
      columns={columns}
      hideFooterPagination
      hideFooter={hideFooter}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      rowSelection={false}
      rowHeight={40}
      columnHeaderHeight={40}
      // disableDensitySelector
      sx={{
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-columnHeaders, .MuiDataGrid-columnHeader": {
          maxHeight: "40px !important",
          minHeight: "40px !important",
          ":focus": {
            outline: "none",
          },
          ":focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontWeight: 700,
        },
        "& .MuiDataGrid-row:nth-child(odd)": {
          bgcolor: "#ececec",
        },
        "& .MuiDataGrid-cell": {
          border: "1px solid #00000024",
          ":focus": {
            outline: "none",
          },
          ":focus-within": {
            outline: "none",
          },
        },
        "& .MuiDataGrid-footerContainer": {
          display: "none",
        },
      }}
    />
  );
};

export default MuiDataGrid;
