import React from "react";

import { Box, Table, TableRow, TableBody, TableCell, TableHead, TableContainer } from "@mui/material";

import theme from "../../../theme";
import FancyMarketTableRow from "./fancy-col-market-table-row";

// ------------------------------------------------------------
type Props = {
  data: any;
  variant: "small" | "large";
};
// ------------------------------------------------------------

const FancyMarketTable: React.FC<Props> = ({ data, variant }) => {
  return (
    <Box p={0.5}>
      <TableContainer key={data?.market_id} sx={{ mt: 1 }}>
        <Table size="small">
          <TableHead sx={{ bgcolor: `${theme.palette.secondary.light}` }}>
            <TableRow>
              <TableCell
                width={"50%"}
                sx={{ fontSize: "14px", fontWeight: "700", color: "#fff", paddingLeft: 0.75 }}
              >
                {data?.market_name}
              </TableCell>
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
              {variant !== "small" && (
                <>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                </>
              )}
              <TableCell
                colSpan={variant === "small" ? 3 : undefined}
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#FAA9BA",
                  height: "30px",
                }}
              >
                NO
              </TableCell>
              <TableCell
                colSpan={variant === "small" ? 3 : undefined}
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#72BBEF",
                  height: "30px",
                }}
              >
                YES
              </TableCell>
              {variant !== "small" && (
                <>
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                  <TableCell sx={{ fontSize: "14px", fontWeight: "700" }} />
                </>
              )}
            </TableRow>
          </TableBody>
          <TableBody>
            <FancyMarketTableRow
              variant={variant}
              market={data}
              marketStatus={data.provider_status}
              marketType={data.market_type}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FancyMarketTable;
