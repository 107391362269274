import React from "react";

import {
  Box,
  Table,
  SxProps,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  useMediaQuery,
  TableContainer,
} from "@mui/material";

import { Runner } from "./types";
import theme from "../../../theme";
import RunnersMarketTableRow from "./runners-market-table-row";

// ------------------------------------------------------------
type Props = {
  data: any;
};
// ------------------------------------------------------------
const sxStyle: SxProps = { width: "84px" };

const RunnersMarketTable: React.FC<Props> = ({ data }) => {
  const breakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Box p={0.5}>
      {/* <Typography
        variant="subtitle1"
        sx={{
          fontSize: "14px",
          fontWeight: 700,
          padding: "6px 16px 6px 6px",
          bgcolor: `${theme.palette.secondary.light}`,
          color: "white",
        }}
      >
        {data?.market_name}
      </Typography> */}
      <TableContainer key={data?.market_id}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: "6px 16px", fontSize: "16px", fontWeight: 700 }}>Runners</TableCell>
              {breakpoint && (
                <>
                  <TableCell sx={{ ...sxStyle }} />
                  <TableCell sx={{ ...sxStyle }} />
                </>
              )}

              <TableCell
                sx={{
                  ...sxStyle,
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#72BBEF",
                  height: "30px",
                }}
              >
                Back
              </TableCell>
              <TableCell
                sx={{
                  ...sxStyle,
                  fontSize: "16px",
                  fontWeight: "700",
                  padding: 0,
                  textAlign: "center",
                  bgcolor: "#FAA9BA",
                  height: "30px",
                }}
              >
                Lay
              </TableCell>
              {breakpoint && (
                <>
                  <TableCell sx={{ ...sxStyle }}> </TableCell>
                  <TableCell sx={{ ...sxStyle }}> </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.runners &&
              data?.runners.map((runner: Runner, i: number) => {
                return (
                  <RunnersMarketTableRow
                    key={i}
                    runner={runner}
                    marketStatus={data.provider_status}
                    marketType={data.market_type}
                    sxStyle={sxStyle}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RunnersMarketTable;
